import React, { FC } from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

interface LoadingComponentProps {
  inverted?: boolean
}
export const LoadingComponent: FC<LoadingComponentProps> = ({ inverted = true }: LoadingComponentProps): JSX.Element => (
  <Segment basic style={{ minHeight: '330px' }} className="center-text">
    <Dimmer active={inverted} inverted={inverted}>
      <Loader inverted={inverted} active size="big">
        Chargement en cours
      </Loader>
    </Dimmer>
  </Segment>
)

export default LoadingComponent
