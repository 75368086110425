import axios, { AxiosPromise, AxiosResponse } from 'axios'
import { getToken } from '../utils/AuthService'
import { logException } from '../utils/ErrorLogger'
import { API_URL } from '../utils/Constants'
import get from 'lodash/get'
import Debug from 'debug'
import { ThunkAction } from 'redux-thunk'
import { Action, AnyAction } from 'redux'

const debug = Debug('app:actions:user')

export const SAVE_USER = 'SAVE_USER'
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS'
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export function createUser(profile: any): ThunkAction<Promise<any | AxiosResponse>, unknown, unknown, Action<string>> {
  debug('createUser : ', profile)
  return (dispatch: any): any => {
    dispatch(saveUser())
    return axios({
      url: `${API_URL}/users`,
      method: 'post',
      headers: { Authorization: `Bearer ${getToken()}` },
      data: {
        profile: profile,
      },
    }).then(
      (response: any): any => {
        // debug('axios : ', response)
        dispatch(saveUserSuccess(response.data.user))
        return response.data.user
      },
      (error: any): any => {
        // debug('axios : ', error.response)
        logException(error, error.response)

        dispatch(saveUserFailure(get(error, 'response.data')))
        throw error.response
      }
    )
  }
}

export function fetchUser(id: string): ThunkAction<Promise<AxiosResponse>, unknown, unknown, Action<string>> {
  debug('Fetching user (%s) ...', id)
  return (dispatch: any): any => {
    dispatch(saveUser())
    return axios({
      url: `${API_URL}/users/${id}`,
      method: 'get',
      headers: { Authorization: `Bearer ${getToken()}` },
    }).then(
      (response: any): any => {
        dispatch(saveUserSuccess(response.data.user))
        return response
      },
      (error: any): any => {
        logException(error, error.response)
        throw error.response
      }
    )
  }
}

export function axiosUpdateUser(id: string, updatedUser: any): AxiosPromise {
  debug('axiosUpdateUser : ', updatedUser)
  return axios({
    url: `${API_URL}/users/${id}`,
    method: 'put',
    headers: { Authorization: `Bearer ${getToken()}` },
    data: {
      updatedUser: updatedUser,
    },
  })
}

export function fetchUpdateUser(id: string, updatedUser: any): ThunkAction<Promise<AxiosResponse>, unknown, unknown, Action<string>> {
  debug('fetchUpdateUser : ', id, ' / ', updatedUser)
  return (dispatch: any): any => {
    dispatch(updateUser())
    debug('UpdateUser : ', updatedUser)
    return axiosUpdateUser(id, updatedUser).then(
      (response: any): any => {
        debug('response in fetchUpdateUser : ', response)
        dispatch(updateUserSuccess(response.data.user))
        return response
      },
      (error: any): any => {
        debug('error : ', error)
        logException(error, error.response)

        dispatch(updateUserFailure(error.response.data))
        throw error.response
      }
    )
  }
}

export function saveUser(): Action<string> {
  return {
    type: SAVE_USER,
  }
}

export function saveUserSuccess(user: any): AnyAction {
  debug('Action | saveUserSuccess : %O', user)
  return {
    type: SAVE_USER_SUCCESS,
    data: user,
  }
}

export function saveUserFailure(error: any): AnyAction {
  debug('Action | saveUserFailure : %O', error)
  return {
    type: SAVE_USER_FAILURE,
    data: error,
  }
}

export function updateUser(): Action<string> {
  return {
    type: UPDATE_USER,
  }
}

export function updateUserSuccess(user: any): AnyAction {
  debug('Action | updateUserSuccess : %O', user)
  return {
    type: UPDATE_USER_SUCCESS,
    data: user,
  }
}

export function updateUserFailure(error: any): AnyAction {
  debug('Action | updateUserFailure : %O', error)
  return {
    type: UPDATE_USER_FAILURE,
    data: error,
  }
}
