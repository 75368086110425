import React, { Component } from 'react'
import { Container, Divider, Grid, List, Loader, Segment } from 'semantic-ui-react'
import Debug from 'debug'
import { connect, ConnectedProps } from 'react-redux'
import { createClient } from 'contentful'
import { logException } from '../../utils/ErrorLogger'
import { Link, RouteChildrenProps } from 'react-router-dom'
import Helmet from 'react-helmet'
import { RootReducerType } from '../../reducers'

const debug = Debug('app:containers:LandingList')

const connector = connect((state: RootReducerType) => ({
  ui: state.ui,
}))

type LandingListProps = ConnectedProps<typeof connector> & RouteChildrenProps

interface LandingListState {
  modalOpen: boolean
  landings: any[]
  loading: boolean
  gifUrl: string
  playing: boolean
  calculations: { onScreen: boolean }
}

class LandingList extends Component<LandingListProps, LandingListState> {
  constructor(props: LandingListProps) {
    super(props)
    this.state = {
      modalOpen: false,
      landings: [],
      loading: true,
      gifUrl: '',
      playing: false,
      calculations: {
        onScreen: false,
      },
    }
  }

  async componentDidMount(): Promise<void> {
    debug('this.props.match.params.page_url :', (this.props.match?.params as any).page_url)
    await this.fetchLanding()
  }

  fetchLanding = async () => {
    try {
      let preview = false
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        preview = true
      }
      this.setState({
        loading: true,
      })

      const client = createClient({
        space: 'uu95v6ljdw3h',
        host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
        accessToken: preview
          ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
          : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
      })

      debug('Fetching Entries....')

      const response = await client.getEntries({
        content_type: 'landing',
        order: '-sys.createdAt',
      })
      debug(response.items)
      if (response.items && response.items.length) {
        this.setState({
          landings: response.items,
          loading: false,
        })
      }
    } catch (ex) {
      logException(ex)
    }
  }

  render() {
    const { landings, loading } = this.state

    if (!landings || loading) {
      return (
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          <Loader active>Chargement en cours...</Loader>
        </Segment>
      )
    } else {
      return (
        <div>
          <Helmet>
            <title>Liste Landing Page - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="MySendingBox propose des solutions modernes d'envoi postal en ligne." />

            <meta property="og:title" content="Liste Landing Page - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
            <meta property="og:description" content="MySendingBox propose des solutions modernes d'envoi postal en ligne." />
          </Helmet>

          <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
            <Divider section hidden />

            <Grid columns="equal">
              <Grid.Row centered>
                <Grid.Column computer={8} tablet="10" mobile="16" textAlign="left">
                  <List divided selection>
                    {landings.map((landing: any) => {
                      return (
                        <List.Item style={{ color: 'rgba(0,0,0,0.8)' }} key={landing.fields.url} as={Link} to={`/landing/${landing.fields.url}`}>
                          {landing.fields.title}
                        </List.Item>
                      )
                    })}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      )
    }
  }
}

export default connector(LandingList)
