import React, { FC } from 'react'
import { Button, Container, Divider, Grid, Header, Image } from 'semantic-ui-react'

import { Link } from 'react-router-dom'

const Features: FC = () => {
  return (
    <div>
      <Container fluid={false} style={{ flex: 1, minHeight: '400px', /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%' }}>
        <Divider section hidden />

        <Grid columns="equal">
          <Grid.Row centered>
            <Grid.Column computer={14} tablet="16" mobile="16">
              <Header as="h3" textAlign="center" size="large">
                Pourquoi utiliser l'API MySendingBox ?
              </Header>

              <Divider hidden />
              <Divider hidden />
              <Divider hidden />

              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column computer="8" tablet="8" mobile="16">
                    <Grid verticalAlign="middle">
                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/mysendingbox/icons/courrier_1.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">Externalisation</Header>
                          <p>Externalisez vos envois de courrier postal pour réduire vos coûts et gagner du temps.</p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/mysendingbox/icons/courrier_2.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">Tout type de courrier</Header>
                          <p>Lettre verte, recommandé avec accusé de réception, recto/verso, couleur, vous définissez vos propres paramètres.</p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/mysendingbox/icons/courrier_3.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">Envoi le jour même</Header>
                          <p>
                            Impression, mise sous pli et envoi dans les 24H.
                            <br />
                            Envoyez du courrier très facilement depuis votre CRM, ERP ou site web.
                          </p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/mysendingbox/icons/courrier_4.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">Suivi en ligne</Header>
                          <p>
                            Suivez l’acheminement de vos courriers en ligne jusqu’à leur réception grâce aux{' '}
                            <Link to="/guide/webhooks-suivi-courriers-api-notification">webhooks de tracking</Link>.
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width="16" only="mobile">
                    <Divider hidden />
                  </Grid.Column>

                  <Grid.Column computer="8" tablet="8" mobile="16">
                    <Grid verticalAlign="middle">
                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/v1499986042/website/cash-HJABZ84Mb_uh8tld.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">Prix simples, sans surprises</Header>
                          <p>
                            Sans conditions de volumes, sans engagement, sans frais d’installations.
                            {/* Vous ne payez que le courrier que vous consommez. */} <Link to="/tarifs">Voir les tarifs.</Link>
                          </p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/v1500555695/shield-ryREtmCSW_jh8szb.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">Contenu certifié</Header>
                          <p>
                            Le contenu de vos courriers est certifié via un numéro de suivi unique.
                            <br />
                            Pour les recommandés vous recevez <Link to="/guide/lettre-recommande-preuve-de-depot-prv-lrel">une preuve de dépot</Link> de La
                            Poste.
                          </p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/v1500555695/education-Sy19FQ0rb_vhagu6.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">Rédaction simplifiée</Header>
                          <p>
                            Des <Link to="/templates">templates</Link> de lettres sont disponibles pour vous simplifier la rédaction de vos courriers.
                            <br />
                            Votre courrier est déjà prêt ? Vous pouvez envoyer directement un PDF.
                          </p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column computer="4" tablet="4" mobile="3">
                          <Image
                            src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/v1500555695/education-Sy19FQ0rb_vhagu6.png"
                            centered
                            style={{ display: 'block', width: '100%', margin: 'auto' }}
                          />
                        </Grid.Column>
                        <Grid.Column computer="12" tablet="12" mobile="13">
                          <Header as="h3">API moderne</Header>
                          <p>
                            Une API RESTful moderne et évolutive.{' '}
                            <a title="Documentation API envoi de courrier MySendingBox.fr" href="https://docs.MySendingBox.fr/">
                              Documentation
                            </a>
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16} textAlign="center">
                    <Divider hidden />
                    <Button as={Link} to="/signup/" primary size="big" content="Découvrir l'API" />
                    <p>
                      <small>Pas de carte bancaire requise.</small>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default Features
