import find from 'lodash/find'
import deburr from 'lodash/deburr'
import toLower from 'lodash/toLower'

export function isValidCountry(country: string): boolean {
  return !!(
    find(country_list, { nom_fr: toLower(country) }) ||
    find(country_list, { nom_fr: deburr(toLower(country)) }) ||
    find(country_list, { alpha2: deburr(toLower(country)) }) ||
    find(country_list, { alpha3: deburr(toLower(country)) }) ||
    find(country_list, { nom_de: deburr(toLower(country)) }) ||
    find(country_list, { nom_it: deburr(toLower(country)) }) ||
    find(country_list, { nom_en: deburr(toLower(country)) }) ||
    find(country_list, { nom_original: deburr(toLower(country)) })
  )
}

export function getCountryAlpha2FromName(country_name: string): string {
  let alpha2
  const country = find(country_list, { nom_fr: deburr(toLower(country_name)) }) || find(country_list, { nom_en: deburr(toLower(country_name)) })
  if (country) {
    alpha2 = country.alpha2
  } else {
    alpha2 = country_name
  }

  return alpha2
}

export function getCountryNameFromAlpha2(country_alpha2: string): string {
  let country_name
  const country = find(country_list, { alpha2: deburr(toLower(country_alpha2)) })
  if (country) {
    country_name = country.nom_fr
  } else {
    country_name = country_alpha2
  }

  return country_name
}

export const country_france = [
  'france',
  'andorre',
  'martinique',
  'guadeloupe',
  'guyane',
  'reunion',
  'réunion',
  'la réunion',
  'mayotte',
  'saint-pierre-et-miquelon',
  'saint-barthélemy',
  'saint-barthelemy',
  'saint-martin',
  'wallis-et-futuna',
  'polynésie française',
  'polynesie francaise',
  'nouvelle-calédonie',
  'nouvelle-caledonie',
]

export const country_list = [
  {
    id: 1,
    code: 4,
    alpha2: 'af',
    alpha3: 'afg',
    nom_fr: 'afghanistan',
    nom_en: 'afghanistan',
    zone: 2,
  },
  {
    id: 2,
    code: 8,
    alpha2: 'al',
    alpha3: 'alb',
    nom_fr: 'albanie',
    nom_en: 'albania',
    zone: 2,
  },
  {
    id: 3,
    code: 10,
    alpha2: 'aq',
    alpha3: 'ata',
    nom_fr: 'antarctique',
    nom_en: 'antarctica',
    zone: 2,
  },
  {
    id: 4,
    code: 12,
    alpha2: 'dz',
    alpha3: 'dza',
    nom_fr: 'algérie',
    nom_en: 'algeria',
    zone: 2,
  },
  {
    id: 5,
    code: 16,
    alpha2: 'as',
    alpha3: 'asm',
    nom_fr: 'samoa américaines',
    nom_en: 'american samoa',
    zone: 2,
  },
  {
    id: 6,
    code: 20,
    alpha2: 'ad',
    alpha3: 'and',
    nom_fr: 'andorre',
    nom_en: 'andorra',
    zone: 1,
  },
  {
    id: 7,
    code: 24,
    alpha2: 'ao',
    alpha3: 'ago',
    nom_fr: 'angola',
    nom_en: 'angola',
    zone: 2,
  },
  {
    id: 8,
    code: 28,
    alpha2: 'ag',
    alpha3: 'atg',
    nom_fr: 'antigua-et-barbuda',
    nom_en: 'antigua and barbuda',
    zone: 2,
  },
  {
    id: 9,
    code: 31,
    alpha2: 'az',
    alpha3: 'aze',
    nom_fr: 'azerbaïdjan',
    nom_en: 'azerbaijan',
    zone: 2,
  },
  {
    id: 10,
    code: 32,
    alpha2: 'ar',
    alpha3: 'arg',
    nom_fr: 'argentine',
    nom_en: 'argentina',
    zone: 2,
  },
  {
    id: 11,
    code: 36,
    alpha2: 'au',
    alpha3: 'aus',
    nom_fr: 'australie',
    nom_en: 'australia',
    zone: 2,
  },
  {
    id: 12,
    code: 40,
    alpha2: 'at',
    alpha3: 'aut',
    nom_fr: 'autriche',
    nom_en: 'austria',
    zone: 1,
  },
  {
    id: 13,
    code: 44,
    alpha2: 'bs',
    alpha3: 'bhs',
    nom_fr: 'bahamas',
    nom_en: 'bahamas',
    zone: 2,
  },
  {
    id: 14,
    code: 48,
    alpha2: 'bh',
    alpha3: 'bhr',
    nom_fr: 'bahreïn',
    nom_en: 'bahrain',
    zone: 2,
  },
  {
    id: 15,
    code: 50,
    alpha2: 'bd',
    alpha3: 'bgd',
    nom_fr: 'bangladesh',
    nom_en: 'bangladesh',
    zone: 2,
  },
  {
    id: 16,
    code: 51,
    alpha2: 'am',
    alpha3: 'arm',
    nom_fr: 'arménie',
    nom_en: 'armenia',
    zone: 2,
  },
  {
    id: 17,
    code: 52,
    alpha2: 'bb',
    alpha3: 'brb',
    nom_fr: 'barbade',
    nom_en: 'barbados',
    zone: 2,
  },
  {
    id: 18,
    code: 56,
    alpha2: 'be',
    alpha3: 'bel',
    nom_fr: 'belgique',
    nom_en: 'belgium',
    nom_de: 'belgien',
    nom_original: 'belgie',
    zone: 1,
  },
  {
    id: 19,
    code: 60,
    alpha2: 'bm',
    alpha3: 'bmu',
    nom_fr: 'bermudes',
    nom_en: 'bermuda',
    zone: 2,
  },
  {
    id: 20,
    code: 64,
    alpha2: 'bt',
    alpha3: 'btn',
    nom_fr: 'bhoutan',
    nom_en: 'bhutan',
    zone: 2,
  },
  {
    id: 21,
    code: 68,
    alpha2: 'bo',
    alpha3: 'bol',
    nom_fr: 'bolivie',
    nom_en: 'bolivia',
    zone: 2,
  },
  {
    id: 22,
    code: 70,
    alpha2: 'ba',
    alpha3: 'bih',
    nom_fr: 'bosnie-herzégovine',
    nom_en: 'bosnia and herzegovina',
    zone: 2,
  },
  {
    id: 23,
    code: 72,
    alpha2: 'bw',
    alpha3: 'bwa',
    nom_fr: 'botswana',
    nom_en: 'botswana',
    zone: 2,
  },
  {
    id: 24,
    code: 74,
    alpha2: 'bv',
    alpha3: 'bvt',
    nom_fr: 'île bouvet',
    nom_en: 'bouvet island',
    zone: 2,
  },
  {
    id: 25,
    code: 76,
    alpha2: 'br',
    alpha3: 'bra',
    nom_fr: 'brésil',
    nom_en: 'brazil',
    zone: 2,
  },
  {
    id: 26,
    code: 84,
    alpha2: 'bz',
    alpha3: 'blz',
    nom_fr: 'belize',
    nom_en: 'belize',
    zone: 2,
  },
  {
    id: 27,
    code: 86,
    alpha2: 'io',
    alpha3: 'iot',
    nom_fr: "territoire britannique de l'océan indien",
    nom_en: 'british indian ocean territory',
    zone: 2,
  },
  {
    id: 28,
    code: 90,
    alpha2: 'sb',
    alpha3: 'slb',
    nom_fr: 'îles salomon',
    nom_en: 'solomon islands',
    zone: 2,
  },
  {
    id: 29,
    code: 92,
    alpha2: 'vg',
    alpha3: 'vgb',
    nom_fr: 'îles vierges britanniques',
    nom_en: 'british virgin islands',
    zone: 2,
  },
  {
    id: 30,
    code: 96,
    alpha2: 'bn',
    alpha3: 'brn',
    nom_fr: 'brunéi darussalam',
    nom_en: 'brunei darussalam',
    zone: 2,
  },
  {
    id: 31,
    code: 100,
    alpha2: 'bg',
    alpha3: 'bgr',
    nom_fr: 'bulgarie',
    nom_en: 'bulgaria',
    zone: 1,
  },
  {
    id: 32,
    code: 104,
    alpha2: 'mm',
    alpha3: 'mmr',
    nom_fr: 'myanmar',
    nom_en: 'myanmar',
    zone: 2,
  },
  {
    id: 33,
    code: 108,
    alpha2: 'bi',
    alpha3: 'bdi',
    nom_fr: 'burundi',
    nom_en: 'burundi',
    zone: 2,
  },
  {
    id: 34,
    code: 112,
    alpha2: 'by',
    alpha3: 'blr',
    nom_fr: 'bélarus',
    nom_en: 'belarus',
    zone: 2,
  },
  {
    id: 35,
    code: 116,
    alpha2: 'kh',
    alpha3: 'khm',
    nom_fr: 'cambodge',
    nom_en: 'cambodia',
    zone: 2,
  },
  {
    id: 36,
    code: 120,
    alpha2: 'cm',
    alpha3: 'cmr',
    nom_fr: 'cameroun',
    nom_en: 'cameroon',
    zone: 2,
  },
  {
    id: 37,
    code: 124,
    alpha2: 'ca',
    alpha3: 'can',
    nom_fr: 'canada',
    nom_en: 'canada',
    zone: 2,
  },
  {
    id: 38,
    code: 132,
    alpha2: 'cv',
    alpha3: 'cpv',
    nom_fr: 'cap-vert',
    nom_en: 'cape verde',
    zone: 2,
  },
  {
    id: 39,
    code: 136,
    alpha2: 'ky',
    alpha3: 'cym',
    nom_fr: 'îles caïmanes',
    nom_en: 'cayman islands',
    zone: 2,
  },
  {
    id: 40,
    code: 140,
    alpha2: 'cf',
    alpha3: 'caf',
    nom_fr: 'république centrafricaine',
    nom_en: 'central african',
    zone: 2,
  },
  {
    id: 41,
    code: 144,
    alpha2: 'lk',
    alpha3: 'lka',
    nom_fr: 'sri lanka',
    nom_en: 'sri lanka',
    zone: 2,
  },
  {
    id: 42,
    code: 148,
    alpha2: 'td',
    alpha3: 'tcd',
    nom_fr: 'tchad',
    nom_en: 'chad',
    zone: 2,
  },
  {
    id: 43,
    code: 152,
    alpha2: 'cl',
    alpha3: 'chl',
    nom_fr: 'chili',
    nom_en: 'chile',
    zone: 2,
  },
  {
    id: 44,
    code: 156,
    alpha2: 'cn',
    alpha3: 'chn',
    nom_fr: 'chine',
    nom_en: 'china',
    zone: 2,
  },
  {
    id: 45,
    code: 158,
    alpha2: 'tw',
    alpha3: 'twn',
    nom_fr: 'taïwan',
    nom_en: 'taiwan',
    zone: 2,
  },
  {
    id: 46,
    code: 162,
    alpha2: 'cx',
    alpha3: 'cxr',
    nom_fr: 'île christmas',
    nom_en: 'christmas island',
    zone: 2,
  },
  {
    id: 47,
    code: 166,
    alpha2: 'cc',
    alpha3: 'cck',
    nom_fr: 'îles cocos (keeling)',
    nom_en: 'cocos (keeling) islands',
    zone: 2,
  },
  {
    id: 48,
    code: 170,
    alpha2: 'co',
    alpha3: 'col',
    nom_fr: 'colombie',
    nom_en: 'colombia',
    zone: 2,
  },
  {
    id: 49,
    code: 174,
    alpha2: 'km',
    alpha3: 'com',
    nom_fr: 'comores',
    nom_en: 'comoros',
    zone: 2,
  },
  {
    id: 50,
    code: 175,
    alpha2: 'yt',
    alpha3: 'myt',
    nom_fr: 'mayotte',
    nom_en: 'mayotte',
    zone: 2,
  },
  {
    id: 51,
    code: 178,
    alpha2: 'cg',
    alpha3: 'cog',
    nom_fr: 'république du congo',
    nom_en: 'republic of the congo',
    zone: 2,
  },
  {
    id: 52,
    code: 180,
    alpha2: 'cd',
    alpha3: 'cod',
    nom_fr: 'république démocratique du congo',
    nom_en: 'the democratic republic of the congo',
    zone: 2,
  },
  {
    id: 53,
    code: 184,
    alpha2: 'ck',
    alpha3: 'cok',
    nom_fr: 'îles cook',
    nom_en: 'cook islands',
    zone: 2,
  },
  {
    id: 54,
    code: 188,
    alpha2: 'cr',
    alpha3: 'cri',
    nom_fr: 'costa rica',
    nom_en: 'costa rica',
    zone: 2,
  },
  {
    id: 55,
    code: 191,
    alpha2: 'hr',
    alpha3: 'hrv',
    nom_fr: 'croatie',
    nom_en: 'croatia',
    zone: 1,
  },
  {
    id: 56,
    code: 192,
    alpha2: 'cu',
    alpha3: 'cub',
    nom_fr: 'cuba',
    nom_en: 'cuba',
    zone: 2,
  },
  {
    id: 57,
    code: 196,
    alpha2: 'cy',
    alpha3: 'cyp',
    nom_fr: 'chypre',
    nom_en: 'cyprus',
    zone: 1,
  },
  {
    id: 58,
    code: 203,
    alpha2: 'cz',
    alpha3: 'cze',
    nom_fr: 'république tchèque',
    nom_en: 'czech republic',
    zone: 1,
  },
  {
    id: 59,
    code: 204,
    alpha2: 'bj',
    alpha3: 'ben',
    nom_fr: 'bénin',
    nom_en: 'benin',
    zone: 2,
  },
  {
    id: 60,
    code: 208,
    alpha2: 'dk',
    alpha3: 'dnk',
    nom_fr: 'danemark',
    nom_en: 'denmark',
    zone: 1,
  },
  {
    id: 60,
    code: 208,
    alpha2: 'dk',
    alpha3: 'dnk',
    nom_fr: 'danemark',
    nom_original: 'danmark',
    zone: 1,
  },
  {
    id: 60,
    code: 208,
    alpha2: 'dk',
    alpha3: 'dnk',
    nom_fr: 'danemark',
    nom_original: 'denemarken',
    zone: 1,
  },
  {
    id: 61,
    code: 212,
    alpha2: 'dm',
    alpha3: 'dma',
    nom_fr: 'dominique',
    nom_en: 'dominica',
    zone: 2,
  },
  {
    id: 62,
    code: 214,
    alpha2: 'do',
    alpha3: 'dom',
    nom_fr: 'république dominicaine',
    nom_en: 'dominican republic',
    zone: 2,
  },
  {
    id: 63,
    code: 218,
    alpha2: 'ec',
    alpha3: 'ecu',
    nom_fr: 'équateur',
    nom_en: 'ecuador',
    zone: 2,
  },
  {
    id: 64,
    code: 222,
    alpha2: 'sv',
    alpha3: 'slv',
    nom_fr: 'el salvador',
    nom_en: 'el salvador',
    zone: 2,
  },
  {
    id: 65,
    code: 226,
    alpha2: 'gq',
    alpha3: 'gnq',
    nom_fr: 'guinée équatoriale',
    nom_en: 'equatorial guinea',
    zone: 2,
  },
  {
    id: 66,
    code: 231,
    alpha2: 'et',
    alpha3: 'eth',
    nom_fr: 'éthiopie',
    nom_en: 'ethiopia',
    zone: 2,
  },
  {
    id: 67,
    code: 232,
    alpha2: 'er',
    alpha3: 'eri',
    nom_fr: 'érythrée',
    nom_en: 'eritrea',
    zone: 2,
  },
  {
    id: 68,
    code: 233,
    alpha2: 'ee',
    alpha3: 'est',
    nom_fr: 'estonie',
    nom_en: 'estonia',
    zone: 1,
  },
  {
    id: 69,
    code: 234,
    alpha2: 'fo',
    alpha3: 'fro',
    nom_fr: 'îles féroé',
    nom_en: 'faroe islands',
    zone: 2,
  },
  {
    id: 70,
    code: 238,
    alpha2: 'fk',
    alpha3: 'flk',
    nom_fr: 'îles (malvinas) falkland',
    nom_en: 'falkland islands',
    zone: 2,
  },
  {
    id: 71,
    code: 239,
    alpha2: 'gs',
    alpha3: 'sgs',
    nom_fr: 'géorgie du sud et les îles sandwich du sud',
    nom_en: 'south georgia and the south sandwich islands',
    zone: 2,
  },
  {
    id: 72,
    code: 242,
    alpha2: 'fj',
    alpha3: 'fji',
    nom_fr: 'fidji',
    nom_en: 'fiji',
    zone: 2,
  },
  {
    id: 73,
    code: 246,
    alpha2: 'fi',
    alpha3: 'fin',
    nom_fr: 'finlande',
    nom_en: 'finland',
    nom_original: 'suomi',
    zone: 1,
  },
  {
    id: 74,
    code: 248,
    alpha2: 'ax',
    alpha3: 'ala',
    nom_fr: 'îles åland',
    nom_en: 'åland islands',
    zone: 2,
  },
  {
    id: 75,
    code: 250,
    alpha2: 'fr',
    alpha3: 'fra',
    nom_fr: 'france',
    nom_en: 'france',
    zone: 2,
  },
  {
    id: 76,
    code: 254,
    alpha2: 'gf',
    alpha3: 'guf',
    nom_fr: 'guyane française',
    nom_en: 'french guiana',
    zone: 2,
  },
  {
    id: 77,
    code: 258,
    alpha2: 'pf',
    alpha3: 'pyf',
    nom_fr: 'polynésie française',
    nom_en: 'french polynesia',
    zone: 2,
  },
  {
    id: 78,
    code: 260,
    alpha2: 'tf',
    alpha3: 'atf',
    nom_fr: 'terres australes françaises',
    nom_en: 'french southern territories',
    zone: 2,
  },
  {
    id: 79,
    code: 262,
    alpha2: 'dj',
    alpha3: 'dji',
    nom_fr: 'djibouti',
    nom_en: 'djibouti',
    zone: 2,
  },
  {
    id: 80,
    code: 266,
    alpha2: 'ga',
    alpha3: 'gab',
    nom_fr: 'gabon',
    nom_en: 'gabon',
    zone: 2,
  },
  {
    id: 81,
    code: 268,
    alpha2: 'ge',
    alpha3: 'geo',
    nom_fr: 'géorgie',
    nom_en: 'georgia',
    zone: 2,
  },
  {
    id: 82,
    code: 270,
    alpha2: 'gm',
    alpha3: 'gmb',
    nom_fr: 'gambie',
    nom_en: 'gambia',
    zone: 2,
  },
  {
    id: 83,
    code: 275,
    alpha2: 'ps',
    alpha3: 'pse',
    nom_fr: 'territoire palestinien occupé',
    nom_en: 'occupied palestinian territory',
    zone: 2,
  },
  {
    id: 84,
    code: 276,
    alpha2: 'de',
    alpha3: 'deu',
    nom_fr: 'allemagne',
    nom_en: 'germany',
    zone: 1,
  },
  {
    id: 85,
    code: 288,
    alpha2: 'gh',
    alpha3: 'gha',
    nom_fr: 'ghana',
    nom_en: 'ghana',
    zone: 2,
  },
  {
    id: 86,
    code: 292,
    alpha2: 'gi',
    alpha3: 'gib',
    nom_fr: 'gibraltar',
    nom_en: 'gibraltar',
    zone: 2,
  },
  {
    id: 87,
    code: 296,
    alpha2: 'ki',
    alpha3: 'kir',
    nom_fr: 'kiribati',
    nom_en: 'kiribati',
    zone: 2,
  },
  {
    id: 88,
    code: 300,
    alpha2: 'gr',
    alpha3: 'grc',
    nom_fr: 'grèce',
    nom_en: 'greece',
    zone: 1,
  },
  {
    id: 89,
    code: 304,
    alpha2: 'gl',
    alpha3: 'grl',
    nom_fr: 'groenland',
    nom_en: 'greenland',
    zone: 2,
  },
  {
    id: 90,
    code: 308,
    alpha2: 'gd',
    alpha3: 'grd',
    nom_fr: 'grenade',
    nom_en: 'grenada',
    zone: 2,
  },
  {
    id: 91,
    code: 312,
    alpha2: 'gp',
    alpha3: 'glp',
    nom_fr: 'guadeloupe',
    nom_en: 'guadeloupe',
    zone: 2,
  },
  {
    id: 92,
    code: 316,
    alpha2: 'gu',
    alpha3: 'gum',
    nom_fr: 'guam',
    nom_en: 'guam',
    zone: 2,
  },
  {
    id: 93,
    code: 320,
    alpha2: 'gt',
    alpha3: 'gtm',
    nom_fr: 'guatemala',
    nom_en: 'guatemala',
    zone: 2,
  },
  {
    id: 94,
    code: 324,
    alpha2: 'gn',
    alpha3: 'gin',
    nom_fr: 'guinée',
    nom_en: 'guinea',
    zone: 2,
  },
  {
    id: 95,
    code: 328,
    alpha2: 'gy',
    alpha3: 'guy',
    nom_fr: 'guyana',
    nom_en: 'guyana',
    zone: 2,
  },
  {
    id: 96,
    code: 332,
    alpha2: 'ht',
    alpha3: 'hti',
    nom_fr: 'haïti',
    nom_en: 'haiti',
    zone: 2,
  },
  {
    id: 97,
    code: 334,
    alpha2: 'hm',
    alpha3: 'hmd',
    nom_fr: 'îles heard et mcdonald',
    nom_en: 'heard island and mcdonald islands',
    zone: 2,
  },
  {
    id: 98,
    code: 336,
    alpha2: 'va',
    alpha3: 'vat',
    nom_fr: 'saint-siège (état de la cité du vatican)',
    nom_en: 'vatican city state',
    zone: 1,
  },
  {
    id: 99,
    code: 340,
    alpha2: 'hn',
    alpha3: 'hnd',
    nom_fr: 'honduras',
    nom_en: 'honduras',
    zone: 2,
  },
  {
    id: 100,
    code: 344,
    alpha2: 'hk',
    alpha3: 'hkg',
    nom_fr: 'hong-kong',
    nom_en: 'hong kong',
    zone: 2,
  },
  {
    id: 101,
    code: 348,
    alpha2: 'hu',
    alpha3: 'hun',
    nom_fr: 'hongrie',
    nom_en: 'hungary',
    zone: 1,
  },
  {
    id: 102,
    code: 352,
    alpha2: 'is',
    alpha3: 'isl',
    nom_fr: 'islande',
    nom_en: 'iceland',
    zone: 2,
  },
  {
    id: 103,
    code: 356,
    alpha2: 'in',
    alpha3: 'ind',
    nom_fr: 'inde',
    nom_en: 'india',
    zone: 2,
  },
  {
    id: 104,
    code: 360,
    alpha2: 'id',
    alpha3: 'idn',
    nom_fr: 'indonésie',
    nom_en: 'indonesia',
    zone: 2,
  },
  {
    id: 105,
    code: 364,
    alpha2: 'ir',
    alpha3: 'irn',
    nom_fr: "république islamique d'iran",
    nom_en: 'islamic republic of iran',
    zone: 2,
  },
  {
    id: 106,
    code: 368,
    alpha2: 'iq',
    alpha3: 'irq',
    nom_fr: 'iraq',
    nom_en: 'iraq',
    zone: 2,
  },
  {
    id: 107,
    code: 372,
    alpha2: 'ie',
    alpha3: 'irl',
    nom_fr: 'irlande',
    nom_en: 'ireland',
    zone: 1,
  },
  {
    id: 108,
    code: 376,
    alpha2: 'il',
    alpha3: 'isr',
    nom_fr: 'israël',
    nom_en: 'israel',
    zone: 2,
  },
  {
    id: 109,
    code: 380,
    alpha2: 'it',
    alpha3: 'ita',
    nom_fr: 'italie',
    nom_en: 'italy',
    nom_original: 'italia',
    zone: 1,
  },
  {
    id: 109,
    code: 380,
    alpha2: 'it',
    alpha3: 'ita',
    nom_fr: 'italie',
    nom_en: 'italia',
    zone: 1,
  },
  {
    id: 110,
    code: 384,
    alpha2: 'ci',
    alpha3: 'civ',
    nom_fr: "côte d'ivoire",
    nom_en: "côte d'ivoire",
    zone: 2,
  },
  {
    id: 111,
    code: 388,
    alpha2: 'jm',
    alpha3: 'jam',
    nom_fr: 'jamaïque',
    nom_en: 'jamaica',
    zone: 2,
  },
  {
    id: 112,
    code: 392,
    alpha2: 'jp',
    alpha3: 'jpn',
    nom_fr: 'japon',
    nom_en: 'japan',
    zone: 2,
  },
  {
    id: 113,
    code: 398,
    alpha2: 'kz',
    alpha3: 'kaz',
    nom_fr: 'kazakhstan',
    nom_en: 'kazakhstan',
    zone: 2,
  },
  {
    id: 114,
    code: 400,
    alpha2: 'jo',
    alpha3: 'jor',
    nom_fr: 'jordanie',
    nom_en: 'jordan',
    zone: 2,
  },
  {
    id: 115,
    code: 404,
    alpha2: 'ke',
    alpha3: 'ken',
    nom_fr: 'kenya',
    nom_en: 'kenya',
    zone: 2,
  },
  {
    id: 116,
    code: 408,
    alpha2: 'kp',
    alpha3: 'prk',
    nom_fr: 'république populaire démocratique de corée',
    nom_en: "democratic people's republic of korea",
    zone: 2,
  },
  {
    id: 117,
    code: 410,
    alpha2: 'kr',
    alpha3: 'kor',
    nom_fr: 'république de corée',
    nom_en: 'republic of korea',
    zone: 2,
  },
  {
    id: 118,
    code: 414,
    alpha2: 'kw',
    alpha3: 'kwt',
    nom_fr: 'koweït',
    nom_en: 'kuwait',
    zone: 2,
  },
  {
    id: 119,
    code: 417,
    alpha2: 'kg',
    alpha3: 'kgz',
    nom_fr: 'kirghizistan',
    nom_en: 'kyrgyzstan',
    zone: 2,
  },
  {
    id: 120,
    code: 418,
    alpha2: 'la',
    alpha3: 'lao',
    nom_fr: 'république démocratique populaire lao',
    nom_en: "lao people's democratic republic",
    zone: 2,
  },
  {
    id: 121,
    code: 422,
    alpha2: 'lb',
    alpha3: 'lbn',
    nom_fr: 'liban',
    nom_en: 'lebanon',
    zone: 2,
  },
  {
    id: 122,
    code: 426,
    alpha2: 'ls',
    alpha3: 'lso',
    nom_fr: 'lesotho',
    nom_en: 'lesotho',
    zone: 2,
  },
  {
    id: 123,
    code: 428,
    alpha2: 'lv',
    alpha3: 'lva',
    nom_fr: 'lettonie',
    nom_en: 'latvia',
    zone: 1,
  },
  {
    id: 124,
    code: 430,
    alpha2: 'lr',
    alpha3: 'lbr',
    nom_fr: 'libéria',
    nom_en: 'liberia',
    zone: 2,
  },
  {
    id: 125,
    code: 434,
    alpha2: 'ly',
    alpha3: 'lby',
    nom_fr: 'jamahiriya arabe libyenne',
    nom_en: 'libyan arab jamahiriya',
    zone: 2,
  },
  {
    id: 126,
    code: 438,
    alpha2: 'li',
    alpha3: 'lie',
    nom_fr: 'liechtenstein',
    nom_en: 'liechtenstein',
    zone: 1,
  },
  {
    id: 127,
    code: 440,
    alpha2: 'lt',
    alpha3: 'ltu',
    nom_fr: 'lituanie',
    nom_en: 'lithuania',
    zone: 1,
  },
  {
    id: 128,
    code: 442,
    alpha2: 'lu',
    alpha3: 'lux',
    nom_fr: 'luxembourg',
    nom_en: 'luxembourg',
    nom_original: 'luxemburg',
    zone: 1,
  },
  {
    id: 129,
    code: 446,
    alpha2: 'mo',
    alpha3: 'mac',
    nom_fr: 'macao',
    nom_en: 'macao',
    zone: 2,
  },
  {
    id: 130,
    code: 450,
    alpha2: 'mg',
    alpha3: 'mdg',
    nom_fr: 'madagascar',
    nom_en: 'madagascar',
    zone: 2,
  },
  {
    id: 131,
    code: 454,
    alpha2: 'mw',
    alpha3: 'mwi',
    nom_fr: 'malawi',
    nom_en: 'malawi',
    zone: 2,
  },
  {
    id: 132,
    code: 458,
    alpha2: 'my',
    alpha3: 'mys',
    nom_fr: 'malaisie',
    nom_en: 'malaysia',
    zone: 2,
  },
  {
    id: 133,
    code: 462,
    alpha2: 'mv',
    alpha3: 'mdv',
    nom_fr: 'maldives',
    nom_en: 'maldives',
    zone: 2,
  },
  {
    id: 134,
    code: 466,
    alpha2: 'ml',
    alpha3: 'mli',
    nom_fr: 'mali',
    nom_en: 'mali',
    zone: 2,
  },
  {
    id: 135,
    code: 470,
    alpha2: 'mt',
    alpha3: 'mlt',
    nom_fr: 'malte',
    nom_en: 'malta',
    zone: 1,
  },
  {
    id: 136,
    code: 474,
    alpha2: 'mq',
    alpha3: 'mtq',
    nom_fr: 'martinique',
    nom_en: 'martinique',
    zone: 2,
  },
  {
    id: 137,
    code: 478,
    alpha2: 'mr',
    alpha3: 'mrt',
    nom_fr: 'mauritanie',
    nom_en: 'mauritania',
    zone: 2,
  },
  {
    id: 138,
    code: 480,
    alpha2: 'mu',
    alpha3: 'mus',
    nom_fr: 'maurice',
    nom_en: 'mauritius',
    zone: 2,
  },
  {
    id: 139,
    code: 484,
    alpha2: 'mx',
    alpha3: 'mex',
    nom_fr: 'mexique',
    nom_en: 'mexico',
    zone: 2,
  },
  {
    id: 140,
    code: 492,
    alpha2: 'mc',
    alpha3: 'mco',
    nom_fr: 'monaco',
    nom_en: 'monaco',
    zone: 1,
  },
  {
    id: 141,
    code: 496,
    alpha2: 'mn',
    alpha3: 'mng',
    nom_fr: 'mongolie',
    nom_en: 'mongolia',
    zone: 2,
  },
  {
    id: 142,
    code: 498,
    alpha2: 'md',
    alpha3: 'mda',
    nom_fr: 'république de moldova',
    nom_en: 'republic of moldova',
    zone: 2,
  },
  {
    id: 143,
    code: 500,
    alpha2: 'ms',
    alpha3: 'msr',
    nom_fr: 'montserrat',
    nom_en: 'montserrat',
    zone: 2,
  },
  {
    id: 144,
    code: 504,
    alpha2: 'ma',
    alpha3: 'mar',
    nom_fr: 'maroc',
    nom_en: 'morocco',
    zone: 2,
  },
  {
    id: 145,
    code: 508,
    alpha2: 'mz',
    alpha3: 'moz',
    nom_fr: 'mozambique',
    nom_en: 'mozambique',
    zone: 2,
  },
  {
    id: 146,
    code: 512,
    alpha2: 'om',
    alpha3: 'omn',
    nom_fr: 'oman',
    nom_en: 'oman',
    zone: 2,
  },
  {
    id: 147,
    code: 516,
    alpha2: 'na',
    alpha3: 'nam',
    nom_fr: 'namibie',
    nom_en: 'namibia',
    zone: 2,
  },
  {
    id: 148,
    code: 520,
    alpha2: 'nr',
    alpha3: 'nru',
    nom_fr: 'nauru',
    nom_en: 'nauru',
    zone: 2,
  },
  {
    id: 149,
    code: 524,
    alpha2: 'np',
    alpha3: 'npl',
    nom_fr: 'népal',
    nom_en: 'nepal',
    zone: 2,
  },
  {
    id: 150,
    code: 528,
    alpha2: 'nl',
    alpha3: 'nld',
    nom_fr: 'pays-bas',
    nom_en: 'netherlands',
    nom_original: 'nederland',
    zone: 1,
  },
  {
    id: 150,
    code: 528,
    alpha2: 'nl',
    alpha3: 'nld',
    nom_fr: 'pays-bas',
    nom_en: 'netherlands',
    nom_original: 'nederlanderna',
    zone: 1,
  },
  {
    id: 151,
    code: 530,
    alpha2: 'an',
    alpha3: 'ant',
    nom_fr: 'antilles néerlandaises',
    nom_en: 'netherlands antilles',
    zone: 2,
  },
  {
    id: 152,
    code: 533,
    alpha2: 'aw',
    alpha3: 'abw',
    nom_fr: 'aruba',
    nom_en: 'aruba',
    zone: 2,
  },
  {
    id: 153,
    code: 540,
    alpha2: 'nc',
    alpha3: 'ncl',
    nom_fr: 'nouvelle-calédonie',
    nom_en: 'new caledonia',
    zone: 2,
  },
  {
    id: 154,
    code: 548,
    alpha2: 'vu',
    alpha3: 'vut',
    nom_fr: 'vanuatu',
    nom_en: 'vanuatu',
    zone: 2,
  },
  {
    id: 155,
    code: 554,
    alpha2: 'nz',
    alpha3: 'nzl',
    nom_fr: 'nouvelle-zélande',
    nom_en: 'new zealand',
    zone: 2,
  },
  {
    id: 156,
    code: 558,
    alpha2: 'ni',
    alpha3: 'nic',
    nom_fr: 'nicaragua',
    nom_en: 'nicaragua',
    zone: 2,
  },
  {
    id: 157,
    code: 562,
    alpha2: 'ne',
    alpha3: 'ner',
    nom_fr: 'niger',
    nom_en: 'niger',
    zone: 2,
  },
  {
    id: 158,
    code: 566,
    alpha2: 'ng',
    alpha3: 'nga',
    nom_fr: 'nigéria',
    nom_en: 'nigeria',
    zone: 2,
  },
  {
    id: 159,
    code: 570,
    alpha2: 'nu',
    alpha3: 'niu',
    nom_fr: 'niué',
    nom_en: 'niue',
    zone: 2,
  },
  {
    id: 160,
    code: 574,
    alpha2: 'nf',
    alpha3: 'nfk',
    nom_fr: 'île norfolk',
    nom_en: 'norfolk island',
    zone: 2,
  },
  {
    id: 161,
    code: 578,
    alpha2: 'no',
    alpha3: 'nor',
    nom_fr: 'norvège',
    nom_en: 'norway',
    nom_original: 'norge',
    zone: 2,
  },
  {
    id: 162,
    code: 580,
    alpha2: 'mp',
    alpha3: 'mnp',
    nom_fr: 'îles mariannes du nord',
    nom_en: 'northern mariana islands',
    zone: 2,
  },
  {
    id: 163,
    code: 581,
    alpha2: 'um',
    alpha3: 'umi',
    nom_fr: 'îles mineures éloignées des états-unis',
    nom_en: 'united states minor outlying islands',
    zone: 2,
  },
  {
    id: 164,
    code: 583,
    alpha2: 'fm',
    alpha3: 'fsm',
    nom_fr: 'états fédérés de micronésie',
    nom_en: 'federated states of micronesia',
    zone: 2,
  },
  {
    id: 165,
    code: 584,
    alpha2: 'mh',
    alpha3: 'mhl',
    nom_fr: 'îles marshall',
    nom_en: 'marshall islands',
    zone: 2,
  },
  {
    id: 166,
    code: 585,
    alpha2: 'pw',
    alpha3: 'plw',
    nom_fr: 'palaos',
    nom_en: 'palau',
    zone: 2,
  },
  {
    id: 167,
    code: 586,
    alpha2: 'pk',
    alpha3: 'pak',
    nom_fr: 'pakistan',
    nom_en: 'pakistan',
    zone: 2,
  },
  {
    id: 168,
    code: 591,
    alpha2: 'pa',
    alpha3: 'pan',
    nom_fr: 'panama',
    nom_en: 'panama',
    zone: 2,
  },
  {
    id: 169,
    code: 598,
    alpha2: 'pg',
    alpha3: 'png',
    nom_fr: 'papouasie-nouvelle-guinée',
    nom_en: 'papua new guinea',
    zone: 2,
  },
  {
    id: 170,
    code: 600,
    alpha2: 'py',
    alpha3: 'pry',
    nom_fr: 'paraguay',
    nom_en: 'paraguay',
    zone: 2,
  },
  {
    id: 171,
    code: 604,
    alpha2: 'pe',
    alpha3: 'per',
    nom_fr: 'pérou',
    nom_en: 'peru',
    zone: 2,
  },
  {
    id: 172,
    code: 608,
    alpha2: 'ph',
    alpha3: 'phl',
    nom_fr: 'philippines',
    nom_en: 'philippines',
    zone: 2,
  },
  {
    id: 173,
    code: 612,
    alpha2: 'pn',
    alpha3: 'pcn',
    nom_fr: 'pitcairn',
    nom_en: 'pitcairn',
    zone: 2,
  },
  {
    id: 174,
    code: 616,
    alpha2: 'pl',
    alpha3: 'pol',
    nom_fr: 'pologne',
    nom_en: 'poland',
    zone: 1,
  },
  {
    id: 175,
    code: 620,
    alpha2: 'pt',
    alpha3: 'prt',
    nom_fr: 'portugal',
    nom_en: 'portugal',
    zone: 1,
  },
  {
    id: 176,
    code: 624,
    alpha2: 'gw',
    alpha3: 'gnb',
    nom_fr: 'guinée-bissau',
    nom_en: 'guinea-bissau',
    zone: 2,
  },
  {
    id: 177,
    code: 626,
    alpha2: 'tl',
    alpha3: 'tls',
    nom_fr: 'timor-leste',
    nom_en: 'timor-leste',
    zone: 2,
  },
  {
    id: 178,
    code: 630,
    alpha2: 'pr',
    alpha3: 'pri',
    nom_fr: 'porto rico',
    nom_en: 'puerto rico',
    zone: 2,
  },
  {
    id: 179,
    code: 634,
    alpha2: 'qa',
    alpha3: 'qat',
    nom_fr: 'qatar',
    nom_en: 'qatar',
    zone: 2,
  },
  {
    id: 180,
    code: 638,
    alpha2: 're',
    alpha3: 'reu',
    nom_fr: 'réunion',
    nom_en: 'réunion',
    zone: 2,
  },
  {
    id: 181,
    code: 642,
    alpha2: 'ro',
    alpha3: 'rou',
    nom_fr: 'roumanie',
    nom_en: 'romania',
    zone: 1,
  },
  {
    id: 182,
    code: 643,
    alpha2: 'ru',
    alpha3: 'rus',
    nom_fr: 'fédération de russie',
    nom_en: 'russian federation',
    zone: 2,
  },
  {
    id: 183,
    code: 646,
    alpha2: 'rw',
    alpha3: 'rwa',
    nom_fr: 'rwanda',
    nom_en: 'rwanda',
    zone: 2,
  },
  {
    id: 184,
    code: 654,
    alpha2: 'sh',
    alpha3: 'shn',
    nom_fr: 'sainte-hélène',
    nom_en: 'saint helena',
    zone: 2,
  },
  {
    id: 185,
    code: 659,
    alpha2: 'kn',
    alpha3: 'kna',
    nom_fr: 'saint-kitts-et-nevis',
    nom_en: 'saint kitts and nevis',
    zone: 2,
  },
  {
    id: 186,
    code: 660,
    alpha2: 'ai',
    alpha3: 'aia',
    nom_fr: 'anguilla',
    nom_en: 'anguilla',
    zone: 2,
  },
  {
    id: 187,
    code: 662,
    alpha2: 'lc',
    alpha3: 'lca',
    nom_fr: 'sainte-lucie',
    nom_en: 'saint lucia',
    zone: 2,
  },
  {
    id: 188,
    code: 666,
    alpha2: 'pm',
    alpha3: 'spm',
    nom_fr: 'saint-pierre-et-miquelon',
    nom_en: 'saint-pierre and miquelon',
    zone: 2,
  },
  {
    id: 189,
    code: 670,
    alpha2: 'vc',
    alpha3: 'vct',
    nom_fr: 'saint-vincent-et-les grenadines',
    nom_en: 'saint vincent and the grenadines',
    zone: 2,
  },
  {
    id: 190,
    code: 674,
    alpha2: 'sm',
    alpha3: 'smr',
    nom_fr: 'saint-marin',
    nom_en: 'san marino',
    zone: 1,
  },
  {
    id: 191,
    code: 678,
    alpha2: 'st',
    alpha3: 'stp',
    nom_fr: 'sao tomé-et-principe',
    nom_en: 'sao tome and principe',
    zone: 2,
  },
  {
    id: 192,
    code: 682,
    alpha2: 'sa',
    alpha3: 'sau',
    nom_fr: 'arabie saoudite',
    nom_en: 'saudi arabia',
    zone: 2,
  },
  {
    id: 193,
    code: 686,
    alpha2: 'sn',
    alpha3: 'sen',
    nom_fr: 'sénégal',
    nom_en: 'senegal',
    zone: 2,
  },
  {
    id: 194,
    code: 690,
    alpha2: 'sc',
    alpha3: 'syc',
    nom_fr: 'seychelles',
    nom_en: 'seychelles',
    zone: 2,
  },
  {
    id: 195,
    code: 694,
    alpha2: 'sl',
    alpha3: 'sle',
    nom_fr: 'sierra leone',
    nom_en: 'sierra leone',
    zone: 2,
  },
  {
    id: 196,
    code: 702,
    alpha2: 'sg',
    alpha3: 'sgp',
    nom_fr: 'singapour',
    nom_en: 'singapore',
    zone: 2,
  },
  {
    id: 197,
    code: 703,
    alpha2: 'sk',
    alpha3: 'svk',
    nom_fr: 'slovaquie',
    nom_en: 'slovakia',
    zone: 1,
  },
  {
    id: 198,
    code: 704,
    alpha2: 'vn',
    alpha3: 'vnm',
    nom_fr: 'viet nam',
    nom_en: 'vietnam',
    zone: 2,
  },
  {
    id: 199,
    code: 705,
    alpha2: 'si',
    alpha3: 'svn',
    nom_fr: 'slovénie',
    nom_en: 'slovenia',
    zone: 1,
  },
  {
    id: 200,
    code: 706,
    alpha2: 'so',
    alpha3: 'som',
    nom_fr: 'somalie',
    nom_en: 'somalia',
    zone: 2,
  },
  {
    id: 201,
    code: 710,
    alpha2: 'za',
    alpha3: 'zaf',
    nom_fr: 'afrique du sud',
    nom_en: 'south africa',
    zone: 2,
  },
  {
    id: 202,
    code: 716,
    alpha2: 'zw',
    alpha3: 'zwe',
    nom_fr: 'zimbabwe',
    nom_en: 'zimbabwe',
    zone: 2,
  },
  {
    id: 203,
    code: 724,
    alpha2: 'es',
    alpha3: 'esp',
    nom_fr: 'espagne',
    nom_en: 'spain',
    nom_original: 'espana',
    zone: 1,
  },
  {
    id: 203,
    code: 724,
    alpha2: 'es',
    alpha3: 'esp',
    nom_fr: 'espagne',
    nom_en: 'espana',
    zone: 1,
  },
  {
    id: 204,
    code: 732,
    alpha2: 'eh',
    alpha3: 'esh',
    nom_fr: 'sahara occidental',
    nom_en: 'western sahara',
    zone: 2,
  },
  {
    id: 205,
    code: 736,
    alpha2: 'sd',
    alpha3: 'sdn',
    nom_fr: 'soudan',
    nom_en: 'sudan',
    zone: 2,
  },
  {
    id: 206,
    code: 740,
    alpha2: 'sr',
    alpha3: 'sur',
    nom_fr: 'suriname',
    nom_en: 'suriname',
    zone: 2,
  },
  {
    id: 207,
    code: 744,
    alpha2: 'sj',
    alpha3: 'sjm',
    nom_fr: 'svalbard etîle jan mayen',
    nom_en: 'svalbard and jan mayen',
    zone: 2,
  },
  {
    id: 208,
    code: 748,
    alpha2: 'sz',
    alpha3: 'swz',
    nom_fr: 'swaziland',
    nom_en: 'swaziland',
    zone: 2,
  },
  {
    id: 209,
    code: 752,
    alpha2: 'se',
    alpha3: 'swe',
    nom_fr: 'suède',
    nom_en: 'sweden',
    nom_original: 'sverige',
    zone: 1,
  },
  {
    id: 210,
    code: 756,
    alpha2: 'ch',
    alpha3: 'che',
    nom_fr: 'suisse',
    nom_en: 'switzerland',
    nom_de: 'schweiz',
    nom_it: 'svizzera',
    zone: 1,
  },
  {
    id: 211,
    code: 760,
    alpha2: 'sy',
    alpha3: 'syr',
    nom_fr: 'république arabe syrienne',
    nom_en: 'syrian arab republic',
    zone: 2,
  },
  {
    id: 212,
    code: 762,
    alpha2: 'tj',
    alpha3: 'tjk',
    nom_fr: 'tadjikistan',
    nom_en: 'tajikistan',
    zone: 2,
  },
  {
    id: 213,
    code: 764,
    alpha2: 'th',
    alpha3: 'tha',
    nom_fr: 'thaïlande',
    nom_en: 'thailand',
    zone: 2,
  },
  {
    id: 214,
    code: 768,
    alpha2: 'tg',
    alpha3: 'tgo',
    nom_fr: 'togo',
    nom_en: 'togo',
    zone: 2,
  },
  {
    id: 215,
    code: 772,
    alpha2: 'tk',
    alpha3: 'tkl',
    nom_fr: 'tokelau',
    nom_en: 'tokelau',
    zone: 2,
  },
  {
    id: 216,
    code: 776,
    alpha2: 'to',
    alpha3: 'ton',
    nom_fr: 'tonga',
    nom_en: 'tonga',
    zone: 2,
  },
  {
    id: 217,
    code: 780,
    alpha2: 'tt',
    alpha3: 'tto',
    nom_fr: 'trinité-et-tobago',
    nom_en: 'trinidad and tobago',
    zone: 2,
  },
  {
    id: 218,
    code: 784,
    alpha2: 'ae',
    alpha3: 'are',
    nom_fr: 'émirats arabes unis',
    nom_en: 'united arab emirates',
    zone: 2,
  },
  {
    id: 219,
    code: 788,
    alpha2: 'tn',
    alpha3: 'tun',
    nom_fr: 'tunisie',
    nom_en: 'tunisia',
    zone: 2,
  },
  {
    id: 220,
    code: 792,
    alpha2: 'tr',
    alpha3: 'tur',
    nom_fr: 'turquie',
    nom_en: 'turkey',
    zone: 2,
  },
  {
    id: 221,
    code: 795,
    alpha2: 'tm',
    alpha3: 'tkm',
    nom_fr: 'turkménistan',
    nom_en: 'turkmenistan',
    zone: 2,
  },
  {
    id: 222,
    code: 796,
    alpha2: 'tc',
    alpha3: 'tca',
    nom_fr: 'îles turks et caïques',
    nom_en: 'turks and caicos islands',
    zone: 2,
  },
  {
    id: 223,
    code: 798,
    alpha2: 'tv',
    alpha3: 'tuv',
    nom_fr: 'tuvalu',
    nom_en: 'tuvalu',
    zone: 2,
  },
  {
    id: 224,
    code: 800,
    alpha2: 'ug',
    alpha3: 'uga',
    nom_fr: 'ouganda',
    nom_en: 'uganda',
    zone: 2,
  },
  {
    id: 225,
    code: 804,
    alpha2: 'ua',
    alpha3: 'ukr',
    nom_fr: 'ukraine',
    nom_en: 'ukraine',
    zone: 2,
  },
  {
    id: 226,
    code: 807,
    alpha2: 'mk',
    alpha3: 'mkd',
    nom_fr: "l'ex-république yougoslave de macédoine",
    nom_en: 'the former yugoslav republic of macedonia',
    zone: 2,
  },
  {
    id: 227,
    code: 818,
    alpha2: 'eg',
    alpha3: 'egy',
    nom_fr: 'égypte',
    nom_en: 'egypt',
    zone: 2,
  },
  {
    id: 228,
    code: 826,
    alpha2: 'gb',
    alpha3: 'gbr',
    nom_fr: 'royaume-uni',
    nom_en: 'united kingdom',
    zone: 1,
  },
  {
    id: 229,
    code: 833,
    alpha2: 'im',
    alpha3: 'imn',
    nom_fr: 'île de man',
    nom_en: 'isle of man',
    zone: 2,
  },
  {
    id: 230,
    code: 834,
    alpha2: 'tz',
    alpha3: 'tza',
    nom_fr: 'république-unie de tanzanie',
    nom_en: 'united republic of tanzania',
    zone: 2,
  },
  {
    id: 231,
    code: 840,
    alpha2: 'us',
    alpha3: 'usa',
    nom_fr: 'états-unis',
    nom_en: 'united states',
    zone: 2,
  },
  {
    id: 232,
    code: 850,
    alpha2: 'vi',
    alpha3: 'vir',
    nom_fr: 'îles vierges des états-unis',
    nom_en: 'u.s. virgin islands',
    zone: 2,
  },
  {
    id: 233,
    code: 854,
    alpha2: 'bf',
    alpha3: 'bfa',
    nom_fr: 'burkina faso',
    nom_en: 'burkina faso',
    zone: 2,
  },
  {
    id: 234,
    code: 858,
    alpha2: 'uy',
    alpha3: 'ury',
    nom_fr: 'uruguay',
    nom_en: 'uruguay',
    zone: 2,
  },
  {
    id: 235,
    code: 860,
    alpha2: 'uz',
    alpha3: 'uzb',
    nom_fr: 'ouzbékistan',
    nom_en: 'uzbekistan',
    zone: 2,
  },
  {
    id: 236,
    code: 862,
    alpha2: 've',
    alpha3: 'ven',
    nom_fr: 'venezuela',
    nom_en: 'venezuela',
    zone: 2,
  },
  {
    id: 237,
    code: 876,
    alpha2: 'wf',
    alpha3: 'wlf',
    nom_fr: 'wallis et futuna',
    nom_en: 'wallis and futuna',
    zone: 2,
  },
  {
    id: 238,
    code: 882,
    alpha2: 'ws',
    alpha3: 'wsm',
    nom_fr: 'samoa',
    nom_en: 'samoa',
    zone: 2,
  },
  {
    id: 239,
    code: 887,
    alpha2: 'ye',
    alpha3: 'yem',
    nom_fr: 'yémen',
    nom_en: 'yemen',
    zone: 2,
  },
  {
    id: 240,
    code: 891,
    alpha2: 'cs',
    alpha3: 'scg',
    nom_fr: 'serbie-et-monténégro',
    nom_en: 'serbia and montenegro',
    zone: 2,
  },
  {
    id: 241,
    code: 894,
    alpha2: 'zm',
    alpha3: 'zmb',
    nom_fr: 'zambie',
    nom_en: 'zambia',
    zone: 2,
  },
]
