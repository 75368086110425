import React from 'react'
import { Message } from 'semantic-ui-react'
import isNil from 'lodash/isNil'

// import Debug from 'debug'
// const debug = Debug('app:utils:Form')

export function renderError({ meta: { touched, error, warning } }: any): JSX.Element | false {
  return touched && (error || warning) ? (
    <Message error visible={!!(touched && (error || warning))}>
      {error || warning}
    </Message>
  ) : (
    false
  )
}

// Validator functions
// IMPORTANT WARNING : A validator function MUST be static.
// For example, a maxLength(maxLength) validator that would return a closure will NOT work because
// of redux-form limitations. For that example, one must for instance create a static maxLength15 function

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export function number(value: string): string | undefined {
  return value && isNaN(Number(value)) ? 'Ce champ doit contenir un nombre.' : undefined
}
export function email(value: string): string | undefined {
  return value && !EMAIL_REGEX.test(value) ? 'Ce champ doit contenir un adresse e-mail valide.' : undefined
}
export function phoneNumber(value: string): string | undefined {
  return value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Ce champ doit contenir un numero de téléphone valide.' : undefined
}
export function required(value: string): string | undefined {
  return !isNil(value) && value !== '' ? undefined : 'Ce champ est requis.'
}

export type Validator = (value: string) => string | undefined

export function maxLength(Length: number): Validator {
  return (value: string): string | undefined => {
    return value && value.length > Length ? `Ce champ doit contenir au plus ${Length} caractères` : undefined
  }
}

// Calibrated maxLength that changes depending on postage_speed for addresses
export function maxLengthAddress(value: string): string | undefined {
  return maxLength(45)(value)
}
