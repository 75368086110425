import axios from 'axios'
import Debug from 'debug'
import * as flat from 'flat'
import compact from 'lodash/compact'
import findIndex from 'lodash/findIndex'
import toLower from 'lodash/toLower'
import React from 'react'
import { getToken } from './AuthService'
import { API_URL } from './Constants'
import { logException } from './ErrorLogger'

const debug = Debug('app:utils:Utils')

export function handleFocus(event: FocusEvent): void {
  ;(event.target as any).select()
}

export function b64toBlob(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export function isFirstCourrier(courriers: any[], type: string): boolean {
  return findIndex(courriers, { type: type }) === 0
}

export function isLastCourrier(courriers: any[], type: string): boolean {
  debug('courriers.length : ', courriers.length)
  return findIndex(courriers, { type: type }) === 0
}

export function map_postage_type(postage_type: string): string {
  const tab: Record<string, string> = {
    verte: 'Verte',
    destineo_esprit_libre_seuil_1: 'Destineo Esprit Libre - Seuil 1',
    destineo_esprit_libre_seuil_2: 'Destineo Esprit Libre - Seuil 2',
    prioritaire: 'Prioritaire',
    lr: 'Recommandé',
    ecopli: 'Ecopli',
    lrar: 'Recommandé avec AR',
    lre: 'LRE',
    ere: 'ERE',
  }
  return tab[postage_type] || postage_type
}

export function encode_form(data: any): string {
  return Object.keys(data)
    .map((key: any) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export function get_all_keys_from_object(array: any[]): string[] {
  const results: string[] = []
  array.forEach((item: any) => {
    Object.keys(flat.flatten(item)).forEach((params: any) => {
      if (results.indexOf(params) === -1) {
        results.push(params)
      }
    })
  })
  return results
}

export const map_campaign_status = {
  created: "En cours d'édition",
  in_progress: "En cours d'envoi",
  sent: 'Envoyé',
  canceled: 'Annulé',
}

export async function duplicateTemplate(id: string): Promise<any> {
  try {
    debug('Duplicating template %s', id)
    const response = await axios({
      url: `${API_URL}/templates/${id}`,
      method: 'get',
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    debug('Getting template %s  : %O', id, response)

    const response_2 = await axios({
      url: `${API_URL}/templates`,
      method: 'post',
      headers: { Authorization: `Bearer ${getToken()}` },
      data: response.data.template,
    })

    debug('Creating new template from %s  : %O', id, response)
    return response_2
  } catch (ex) {
    logException(ex)
  }
}

export function displayJson(data: any): JSX.Element {
  return <pre style={{ overflow: 'auto' }}>{JSON.stringify(data, null, 2)}</pre>
}

export const translate_event: any = {
  'letter.created': 'Créée',
  'letter.accepted': 'Acceptée',
  'letter.filing_proof': 'Preuve de dépot',
  'letter.sent': 'Envoyée',
  'letter.error': 'Erreur',
  'letter.canceled': 'Annulée',
  'letter.in_transit': 'En transit',
  'letter.waiting_to_be_withdrawn': "En attente d'être retirée",
  'letter.distributed': 'Distribuée',
  'letter.delivery_proof': 'Accusé de réception',
  'letter.returned_to_sender': "Renvoyée à l'expéditeur",
  'letter.electronic.opened': 'Ouverte',
  'letter.wrong_address': `N'habite pas à l'adresse indiquée`,
  'letter.electronic.sent': 'Envoyée',
  'letter.electronic.not_distributed_temp': 'Non distribuée',
  'letter.electronic.blocked': 'Bloquée',
  'letter.electronic.waiting_download': 'En attente téléchargement',
  'letter.electronic.accepted': 'Acceptée',
  'letter.electronic.not_distributed': 'Non distribuée',
  'letter.electronic.negligence': 'Négligence',
  'letter.electronic.refused': 'Refusée',
  'letter.electronic.canceled': 'Annulée',
  'letter.electronic.locked': 'Bloquée',
  'letter.electronic.failed_internal': 'Envoi échoué',
  'letter.electronic.failed_tracking': 'Suivi impossible',
  'letter.electronic.failed_external': 'Envoi échoué',
  'postcard.created': 'Créée',
  'postcard.accepted': 'Acceptée',
  'postcard.sent': 'Envoyée',
  'postcard.canceled': 'Annulée',
  'sms.created': 'Créé',
}

export const postage_speed_options = [
  { key: 'express', text: "Express (Aujourd'hui si déposé avant aujourd'hui ~minuit)", value: 'express' },
  { key: 'D', text: 'D (Le jour même si déposé avant ~midi)', value: 'D' },
  { key: 'D1', text: 'D1 (Le lendemain si déposé avant ~midi)', value: 'D1' },
]

export const postage_type_options = [
  {
    key: 1,
    value: 'all',
    text: 'Affranchissements',
  },
  {
    key: 2,
    value: 'ecopli',
    text: 'Ecopli',
  },
  {
    key: 3,
    value: 'prioritaire',
    text: 'Prioritaire',
  },
  {
    key: 4,
    value: 'lr',
    text: 'Recommandé sans AR',
  },
  {
    key: 5,
    value: 'lrar',
    text: 'Recommandé avec AR',
  },
  {
    key: 6,
    value: 'ere',
    text: 'ERE',
  },
  {
    key: 7,
    value: 'lre',
    text: 'LRE',
  },
  {
    key: 10,
    value: 'email',
    text: 'E-mail avec pièce jointe',
  },
]

export const postage_type_options_with_destineo = [
  ...postage_type_options,
  {
    key: 8,
    value: 'destineo_esprit_libre_seuil_1',
    text: 'Destineo Esprit Libre - Seuil 1',
  },
  {
    key: 9,
    value: 'destineo_esprit_libre_seuil_2',
    text: 'Destineo Esprit Libre - Seuil 2',
  },
]

export const mode_options = [
  {
    key: 1,
    value: 'livetest',
    text: 'Live et Test',
  },
  {
    key: 2,
    value: 'live',
    text: 'Live',
  },
  {
    key: 3,
    value: 'test',
    text: 'Test',
  },
]
export const color_options = [
  {
    key: 1,
    value: 'colorbw',
    text: 'Couleur et Noir & blanc',
  },
  {
    key: 2,
    value: 'color',
    text: 'Couleur',
  },
  {
    key: 3,
    value: 'bw',
    text: 'Noir & Blanc',
  },
]

export const channel_options = [
  {
    key: 1,
    value: 'all',
    text: 'Électronique et Papier',
  },
  {
    key: 2,
    value: 'electronic',
    text: 'Électronique',
  },
  {
    key: 3,
    value: 'paper',
    text: 'Papier',
  },
]

export const source_file_type_options = [
  {
    label: `Fichier`,
    value: 'file',
  },
  {
    label: `URL`,
    value: 'remote',
  },
  {
    label: `Modèle`,
    value: 'template_id',
  },
  {
    label: `HTML`,
    value: 'html',
  },
]

// https://stackoverflow.com/a/42488360/2633092
export function sumObjectsByKey(...objs: any[]): any {
  return objs.reduce((a: any, b: any) => {
    for (const k in b) {
      if (b.hasOwnProperty(k)) {
        a[k] = (a[k] || 0) + b[k]
      }
    }
    return a
  }, {})
}

export function keywordsIsInArray(array: any[], keywords: any): any {
  // debug('keywordsIsInArray')
  let state = true

  if (!keywords || !array.length) {
    return false
  }

  array = array.join(' ').split(/\s/g)
  keywords = compact(keywords.split(/\s/g))

  array = array.map((item: any) => toLower(item))
  keywords = keywords.map((item: any) => toLower(item))

  // debug('array :', array)
  // debug('keywords :', keywords)

  if (!keywords.length || !array.length) {
    return false
  }
  keywords.forEach((keyword: any) => {
    if (array.indexOf(keyword) === -1) {
      state = false
    }
  })

  // debug('state : ', state)

  return state
}

export function JsonLd({ data }: any): JSX.Element {
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
}

export function convertStringToRegex(string: string): RegExp | null {
  try {
    if (string) {
      const flags = string.replace(/.*\/([gimy]*)$/, '$1')
      const pattern = string.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1')
      const converted_regex = new RegExp(pattern, flags)
      return converted_regex
    } else {
      return null
    }
  } catch (ex) {
    debug('convertStringToRegex | ex :', ex)
    return null
  }
}

// Takes a list of lines and tries to match them to the fields of an address
// Typically used with the lines read from PDFs
export function addressBlockToFields(address_block: string[]): any {
  if (address_block.length) {
    const block = [...address_block] // we don't want to mutate the original
    const name = block.shift()
    try {
      const splited = block.pop()?.split(' ')
      const [address_postalcode, address_city] = [splited?.shift(), splited?.join(' ')]
      const [address_line1, address_line2, address_line3] = block
      return { name, address_line1, address_line2, address_line3, address_postalcode, address_city }
    } catch (err) {
      return false
    }
  }
  return false
}
