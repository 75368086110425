import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header, Icon, Image, Modal } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Debug from 'debug'
import FilePlayer from 'react-player/lib/players/FilePlayer'
import CallMeButton from '../../components/CallMeButton'

const debug = Debug('app:containers:Recouvrement')
interface RecouvrementState {
  modalOpen: boolean
  gifUrl: string
}

class Recouvrement extends Component<any, RecouvrementState> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: false,
      gifUrl: '',
    }
  }

  openModal = (data: string): any => {
    debug('data : ', data)
    if (data === 'gif-campagne') {
      this.setState({
        gifUrl: 'https://res.cloudinary.com/lifebot/video/upload/ac_none/v1519300094/Video_demo_campagne.mp4',
      })
    } else if (data === 'gif-drag-drop') {
      this.setState({
        gifUrl: 'https://res.cloudinary.com/lifebot/video/upload/ac_none/v1519300434/Demo_video_envois_groupees_2.mp4',
      })
    }
    this.setState({
      modalOpen: true,
    })
  }

  handleClose = (): any => {
    this.setState({
      modalOpen: false,
    })
  }

  render(): JSX.Element {
    return (
      <div>
        <Helmet>
          <title>Recouvrement - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          <meta name="description" content="Automatisez l'envoi de vos relances d'impayés par la Poste grâce aux solutions d'envoi postal MySendingBox." />

          <meta property="og:title" content="Recouvrement - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
          <meta
            property="og:description"
            content="Automatisez l'envoi de vos relances d'impayés par la Poste grâce aux solutions d'envoi postal MySendingBox."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider hidden />

          <Grid columns="equal">
            <Grid.Row centered>
              <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                <Button.Group size="medium">
                  <Button as={Link} to="/solutions/facturation-envoi-de-facture-par-courrier">
                    FACTURATION
                  </Button>
                  <Button color="violet" as={Link} to="/solutions/recouvrement-envoi-de-recommande-par-courrier">
                    RECOUVREMENT
                  </Button>
                </Button.Group>

                <Divider hidden />

                <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                  Automatisez vos relances d'impayés
                </Header>

                <Header as="h2" textAlign="center" size="medium">
                  Envoyez facilement des courriers recommandés avec accusés de réception
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column width="16" textAlign="center">
                <Button as={Link} to="/solutions/" size="big" primary content="Découvrir nos solutions" />
                <CallMeButton text="Faites-vous rappeler" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />
                <Divider section />
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/recouvrement_3.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        Glissez-déposez vos courriers
                      </Header>
                      <p>
                        Vous avez déjà généré vos courriers ?
                        <br />
                        Envoyez-les sur la plateforme. L'adresse sera lue et chaque document envoyé avec les options choisies.{' '}
                        <span role="img" aria-label="bolt">
                          ⚡️
                        </span>
                        <br />
                        <a href="#demo" onClick={() => this.openModal('gif-drag-drop')}>
                          {' '}
                          <Icon name="play circle outline" />
                          Vidéo de démonstration &nbsp;
                          <small>(51 sec)</small>
                        </a>
                      </p>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="m-top-30">
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        Valeur légale et juridique
                      </Header>
                      <p>
                        Distribué en main propre par le facteur.
                        <br />
                        Conservation gratuite des fichiers pendant 3 ans.
                        <br />
                        Gestion électronique des accusés de réception.
                      </p>
                    </Grid.Column>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/recouvrement_2.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="m-top-30" style={{ paddingTop: '40px' }}>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/courrier_4.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        Suivi en ligne
                      </Header>
                      <p>
                        Suivez vos recommandés de l'impression à la réception depuis une interface moderne.
                        <br />
                        Retrouvez en ligne les preuves de dépot et les accusés de réception de vos recommandés.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden section />
                <Header size="small">
                  Vous souhaitez intégrer l'envoi de recommandé dans vos processus métier ?
                  <br />
                  <Link to="/solutions/api-envoi-courrier-postal-recommande">Intégrez notre API simple et moderne</Link>
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider section />
                <Divider hidden />

                <Header as="h2">Le courrier intelligent avec MySendingBox</Header>

                <Header size="small">Une suite d'outils complète pour envoyer des courriers recommandés</Header>

                <Divider hidden />
                <Divider hidden />

                <Button as={Link} to="/solutions/" size="big" primary content="Découvrir nos solutions" />
                <CallMeButton text="Faites-vous rappeler" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Modal open={this.state.modalOpen} onClose={this.handleClose} basic closeIcon size="large">
          <Modal.Content>
            <FilePlayer muted playing loop controls width="100%" height="100%" style={{ width: '100%' }} url={this.state.gifUrl} />
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" onClick={this.handleClose} inverted>
              <Icon name="checkmark" /> Fermer
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default Recouvrement
