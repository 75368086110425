import React, { Component, MouseEvent } from 'react'
import { Accordion, Button, ButtonProps, Card, Container, Divider, Grid, Header, Icon, Image, Label, List, Loader, Segment, Statistic } from 'semantic-ui-react'
import Debug from 'debug'
import { Helmet } from 'react-helmet'
import { createClient } from 'contentful'
import { Link, RouteChildrenProps } from 'react-router-dom'
import { logException } from '../utils/ErrorLogger'
import SegmentCustom from '../components/SegmentCustom'
import { IntercomAPI } from 'react-intercom'
import marked from './../utils/MarkdownRenderer'
import orderBy from 'lodash/orderBy'

const debug = Debug('app:containers:Templates')

type TemplateProps = RouteChildrenProps

interface TemplateState {
  loading: boolean
  template: any | null
  side: string
  items: any[]
  activeIndex: number
}

class Template extends Component<TemplateProps, TemplateState> {
  constructor(props: TemplateProps) {
    super(props)
    this.state = {
      loading: false,
      template: null,
      side: 'recto',
      items: [],
      activeIndex: 0,
    }
  }

  async componentDidMount(): Promise<void> {
    await this.fetchTemplate()
    this.fetchFAQ()
  }

  fetchTemplate = async (): Promise<any> => {
    try {
      let preview = false
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        preview = true
      }
      this.setState({
        loading: true,
      })

      const client = createClient({
        space: 'uu95v6ljdw3h',
        host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
        accessToken: preview
          ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
          : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
      })

      debug('Fetching Entries....')

      const response = await client.getEntries<any>({
        content_type: 'templates',
        'fields.url': (this.props.match?.params as any).template_id,
      })
      debug(response.items)
      this.setState({
        loading: false,
        template: {
          ...response.items[0].fields,
          updated_at: response.items[0].sys.updatedAt,
          created_at: response.items[0].sys.createdAt,
        },
      })
    } catch (ex) {
      logException(ex)
    }
  }

  handleSideChange = (e: MouseEvent, data: ButtonProps): any => {
    // debug('handleColorChange : ', data.value)
    this.setState({
      side: data.value,
    })
  }

  fetchFAQ = async (): Promise<any> => {
    const preview = true
    this.setState({
      loading: true,
    })

    const client = createClient({
      space: 'uu95v6ljdw3h',
      host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: preview
        ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
        : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
    })

    debug('Fetching Entries....')

    try {
      const response = await client.getEntries({
        content_type: 'faq',
        'fields.category': this.state.template.type === 'postcard' ? 'template_postcard' : 'template_letter',
      })
      debug('items : ', response.items)
      this.setState({
        items: orderBy(
          response.items.map((item: any) => {
            return item.fields
          }),
          ['order'],
          ['asc']
        ),
        loading: false,
      })
    } catch (err) {
      logException(err)
    }
  }

  render(): JSX.Element {
    const { template, loading, side, activeIndex } = this.state

    if (!template || loading) {
      return (
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          <Loader active>Chargement en cours...</Loader>
        </Segment>
      )
    } else {
      return (
        <div>
          <Helmet>
            <title>{template.title} - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
            <meta name="description" content={`${template.description}`} />

            <meta property="og:title" content={`${template.title} - MySendingBox - Solutions d'envoi de courrier et de carte postale`} />

            <meta property="og:description" content={`${template.description}`} />

            <meta
              property="og:image"
              content={`https://res.cloudinary.com/lifebot/image/upload/c_pad,g_center,h_630,w_1200/${
                template.type === 'letter' ? template.image_url : template.image_url_front
              }`}
            />
          </Helmet>

          <Container
            fluid={false}
            style={{
              flex: 1,
              minHeight: '400px',
              /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%',
            }}
          >
            <Divider hidden />

            <Grid columns="equal">
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={16} textAlign="center">
                  <Header as="h1" style={{ fontSize: '2.6em' }}>
                    {template.title}

                    <Header.Subheader style={{ fontSize: '20px' }}>{template.description}</Header.Subheader>
                    {/* <Header.Subheader>
                    Créez vos template HTML pour optimiser vos envois de courrier via l'API MySendingBox.
                  </Header.Subheader> */}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column computer="6" tablet="10" mobile="14" textAlign="center">
                  <Divider />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered verticalAlign="middle">
                <Grid.Column computer="8" tablet="8" mobile="16" textAlign="center">
                  {template.type === 'postcard' && (
                    <Button.Group size="small">
                      <Button color={side === 'recto' ? 'violet' : undefined} value="recto" onClick={this.handleSideChange}>
                        Recto
                      </Button>
                      <Button color={side === 'verso' ? 'violet' : undefined} value="verso" onClick={this.handleSideChange}>
                        Verso
                      </Button>
                    </Button.Group>
                  )}

                  <Card raised fluid>
                    <Image
                      src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_700/${
                        template.type === 'letter'
                          ? template.image_url
                          : side === 'recto'
                          ? template.image_url_front
                          : template.image_url_back
                          ? template.image_url_back
                          : 'postcard_welcome_verso_cioxsg.png'
                      }`}
                    />
                    <Card.Content extra textAlign="left">
                      <Icon name={template.type === 'letter' ? 'file text outline' : 'image'} />
                      {template.title}
                    </Card.Content>
                  </Card>
                </Grid.Column>

                <Grid.Column computer="1">
                  <Divider hidden />
                </Grid.Column>

                <Grid.Column computer="3" tablet="3" mobile="16">
                  <Header>Personnaliser {template.type === 'letter' ? 'ce courrier' : 'cette carte postale'}</Header>
                  <p className="justify-text">
                    MySendingBox vous permet de très facilement créer des campagnes de {template.type === 'postcard' ? 'carte postale' : 'courrier'} avec un
                    design unique pour chaque destinataire.
                  </p>
                  <br />
                  <Button
                    size="large"
                    fluid
                    primary
                    as={Link}
                    to={`/app/dashboard/templates/?duplicate_template_id=${
                      template.type === 'letter' ? template.id : template.id_front + ',' + template.id_back
                    }`}
                  >
                    Dupliquer ce modèle dans votre compte
                  </Button>
                  <br />
                  <div className="center-text">
                    <small>
                      <Link to="/templates">ou découvrir d'autres modèles</Link>
                    </small>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column computer="6" tablet="10" mobile="14" textAlign="center">
                  <Divider section />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns="equal" verticalAlign="middle" centered>
                <Grid.Column computer="7" tablet="10" mobile="14">
                  <Header size="huge" textAlign="center">
                    Ressources
                  </Header>
                  <Divider hidden />
                  <List divided selection size="large">
                    <List.Item as={Link} to="/guide/creer-mailing-postal-publipostage-personnalise-courrier-word-powerpoint-laposte">
                      <List.Content floated="left" style={{ color: 'rgba(0,0,0,0.8)' }}>
                        <Label color="orange" horizontal>
                          Guide
                        </Label>
                        Créer un publipostage personnalisé de courrier
                      </List.Content>
                    </List.Item>
                    <List.Item as={Link} to="/guide/creer-campagne-publipostage-personnalise-carte-postale-powerpoint-laposte">
                      <List.Content floated="left" style={{ color: 'rgba(0,0,0,0.8)' }}>
                        <Label color="orange" horizontal>
                          Guide
                        </Label>
                        Créer une campagne personnalisée de carte postale
                      </List.Content>
                    </List.Item>
                    <List.Item as={Link} to="/guide/creer-un-modele-de-carte-postale-avec-powerpoint">
                      <List.Content floated="left" style={{ color: 'rgba(0,0,0,0.8)' }}>
                        <Label color="orange" horizontal>
                          Guide
                        </Label>
                        Créer un modèle de carte postale avec PowerPoint
                      </List.Content>
                    </List.Item>
                    <List.Item as={Link} to="/blog/zapier-carte-postale-personnalisee-contact-prospect-pipedrive-seeuletter">
                      <List.Content floated="left" style={{ color: 'rgba(0,0,0,0.8)' }}>
                        <Label color="blue" horizontal>
                          Blog
                        </Label>
                        [Zapier] Envoyer automatiquement une carte postale à vos contacts Pipedrive
                      </List.Content>
                    </List.Item>
                    <List.Item as={Link} to="/blog/zapier-carte-postale-personnalisee-nouveau-prospect-hubspot-seeuletter">
                      <List.Content floated="left" style={{ color: 'rgba(0,0,0,0.8)' }}>
                        <Label color="blue" horizontal>
                          Blog
                        </Label>
                        [Zapier] Envoyer une carte postale de bienvenue pour chaque nouveau prospect Hubspot
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column computer="6" tablet="10" mobile="14" textAlign="center">
                  <Divider section />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column className="m-bottom-10" textAlign="center" mobile="16" tablet="5" computer="5" stretched>
                  <SegmentCustom className="center-block p-no-top" compact transparent opacity={0.45} inverted={false} attached={false} padded>
                    <Header as="h3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_100/application_icon_home_fl6thu.png"
                        centered
                        style={{ display: 'block', width: '100%', paddingRight: '60px', paddingLeft: '60px', margin: 'auto' }}
                      />
                      <Label circular color="violet" size="mini">
                        1
                      </Label>{' '}
                      &nbsp; Créez
                    </Header>
                    <p>
                      votre template PowerPoint et
                      <br />
                      intégrez vos variables.
                    </p>
                  </SegmentCustom>
                </Grid.Column>
                <Grid.Column className="m-bottom-10" textAlign="center" mobile="16" tablet="5" computer="5" stretched>
                  <SegmentCustom className="center-block p-no-top" compact transparent opacity={0.45} inverted={false} attached={false} padded>
                    <Header as="h3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_100/bright_icon_home_noifsb.png"
                        centered
                        style={{ display: 'block', width: '100%', paddingRight: '60px', paddingLeft: '60px', margin: 'auto' }}
                      />
                      <Label circular color="violet" size="mini">
                        2
                      </Label>{' '}
                      &nbsp; Ajoutez
                    </Header>
                    <p>
                      votre liste de
                      <br />
                      destinataires.
                    </p>
                  </SegmentCustom>
                </Grid.Column>
                <Grid.Column className="m-bottom-10" textAlign="center" mobile="16" tablet="5" computer="5" stretched>
                  <SegmentCustom className="center-block p-no-top" compact transparent opacity={0.45} inverted={false} attached={false} padded>
                    <Header as="h3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_100/send_icon_home_iilxzk.png"
                        centered
                        style={{ display: 'block', width: '100%', paddingRight: '60px', paddingLeft: '60px', margin: 'auto' }}
                      />
                      <Label circular color="violet" size="mini">
                        3
                      </Label>{' '}
                      &nbsp; Envoyez
                    </Header>
                    <p>
                      vos {template.type === 'letter' ? 'courriers' : 'cartes'} à chaque
                      <br />
                      destinataire en 1 clic.
                    </p>
                  </SegmentCustom>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column width="16" textAlign="center">
                  <Divider hidden />

                  <Button primary size="big" as={Link} to="/app/dashboard/campaigns">
                    Créer une campagne de {template.type === 'letter' ? 'courrier' : 'carte postale'}
                  </Button>
                </Grid.Column>
              </Grid.Row>

              {template.type === 'postcard' && (
                <Grid.Row centered>
                  <Grid.Column computer="6" tablet="10" mobile="14" textAlign="center">
                    <Divider section />
                  </Grid.Column>
                </Grid.Row>
              )}

              {template.type === 'postcard' && (
                <Grid.Row centered verticalAlign="middle">
                  <Grid.Column computer="4" tablet="10" mobile="14" textAlign="left">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Header size="small" className="m-no-bottom">
                            <Image src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_45/cadeau_icon_landing_rjgmxd.png" /> à la création du
                            design
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header size="small" className="m-no-bottom">
                            <Image src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_45/affranchissement_icon_landing_aqncob.png" />{' '}
                            &nbsp;&nbsp;Affranchissement inclus
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header size="small" className="m-no-bottom">
                            <Image src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_45/volumes_icon_landing_pjzxef.png" /> conditions de
                            volumes
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column computer="1" tablet="10" mobile="14" textAlign="center">
                    <Divider hidden />
                  </Grid.Column>
                  <Grid.Column computer="4" tablet="10" mobile="14" textAlign="center">
                    <Segment basic attached style={{ padding: '80px 30px' }}>
                      <i>a partir de</i>
                      <Statistic horizontal>
                        <Statistic.Value>1,35 €</Statistic.Value>
                        <Statistic.Label>
                          / carte <br /> postale
                        </Statistic.Label>
                      </Statistic>
                      <br />
                      <Link to="/tarifs/carte-postale" title="Tarifs envoi de carte postale">
                        Voir les tarifs dégressifs
                      </Link>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row centered>
                <Grid.Column computer="6" tablet="10" mobile="14" textAlign="center">
                  <Divider section />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column computer="10" tablet="14" mobile="16">
                  <Header as="h2" size="large" textAlign="center">
                    Foire aux questions
                  </Header>
                  <Divider hidden />

                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16} textAlign="left">
                        {!!this.state.items.length && (
                          <Accordion styled fluid>
                            {!!this.state.items.length &&
                              this.state.items.map((item: any, index: any) => {
                                return (
                                  <div key={index}>
                                    <Accordion.Title active={activeIndex === index} index={index} /* onClick={this.handleClick}*/>
                                      <Icon name="dropdown" />
                                      {item.title}
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === index}>
                                      <div dangerouslySetInnerHTML={{ __html: marked(item.content) }} />
                                    </Accordion.Content>
                                  </div>
                                )
                              })}
                          </Accordion>
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row centered stretched>
                      <Grid.Column computer={8} tablet={12} mobile={16} textAlign="center">
                        <Header>
                          Vous ne trouvez pas de réponse à vos questions ?{' '}
                          <a
                            onClick={(e: any) => {
                              if (window.Intercom) {
                                e.preventDefault()
                                IntercomAPI('showNewMessage')
                              }
                            }}
                            href="mailto:hello@MySendingBox.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contactez-nous.
                          </a>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      )
    }
  }
}

export default Template
