import { SAVE_USER, SAVE_USER_FAILURE, SAVE_USER_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from './../actions/action_user'
import { LOGOUT_SUCCESS } from './../actions/action_auth'
import get from 'lodash/get'
import { AnyAction } from 'redux'

export interface UserStateType {
  data: any
  status: string | null
  error: string | null
  loading: boolean
}

const INITIAL_STATE: UserStateType = {
  data: null,
  status: null,
  error: null,
  loading: false,
}

export default function (state: UserStateType = INITIAL_STATE, action: AnyAction): UserStateType {
  // debug('reducer_user | action : ' , action);

  switch (action.type) {
    case SAVE_USER:
      return { ...state, status: 'saving', error: null, loading: true }
    case SAVE_USER_SUCCESS:
      return { ...state, data: action.data, status: 'saved', error: null, loading: false }
    case SAVE_USER_FAILURE:
      return { ...state, status: 'error', error: get(action, 'data.error'), loading: false }
    case UPDATE_USER:
      return { ...state, status: 'saving', error: null, loading: true }
    case UPDATE_USER_SUCCESS:
      return { ...state, data: action.data, status: 'saved', error: null, loading: false }
    case UPDATE_USER_FAILURE:
      return { ...state, status: 'error', error: get(action, 'data.error'), loading: false }
    case LOGOUT_SUCCESS:
      return { ...state, data: null, status: null, error: null, loading: false }

    default:
      return state
  }
}
