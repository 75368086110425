import React, { ChangeEvent, Component } from 'react'
import { Button, Form, Input, Message, Modal, Segment, TextArea } from 'semantic-ui-react'
import Debug from 'debug'
import axios from 'axios'
import { connect } from 'react-redux'
import { encode_form } from '../utils/Utils'
import * as ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import PropTypes from 'prop-types'

const debug = Debug('app:containers:CallMeButton')

interface CallMeButtonProps {
  displayStyle?: string
  text: string
}

interface CallMeButtonState {
  modalOpen: boolean
  submitFailed: boolean
  success: boolean
  nom: string
  societe: string
  telephone: string
  message: string
  error: any
  loading: any
}

class CallMeButton extends Component<CallMeButtonProps, CallMeButtonState> {
  static contextTypes = {
    t: PropTypes.func,
  }

  constructor(props: CallMeButtonProps) {
    super(props)
    this.state = {
      modalOpen: false,
      success: false,
      submitFailed: false,
      nom: '',
      societe: '',
      telephone: '',
      message: '',
      error: null,
      loading: null,
    }
  }

  submit = async () => {
    const values = {
      'form-name': 'call-back-form-2',
      location: window.location.href,
      nom: this.state.nom,
      societe: this.state.societe,
      telephone: this.state.telephone,
      message: this.state.message,
    }

    if (!this.state.telephone) {
      return this.setState({
        error: <span>{this.context.t('Votre numéro de téléphone est nécessaire.')}</span>,
      })
    }

    debug('values: ', values)

    try {
      this.setState({
        loading: true,
        error: null,
      })
      const results = await axios({
        url: '/',
        data: encode_form(values),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
      })
      debug('resuts : ', results)

      ReactGA.pageview('/callme-success')
      ReactPixel.track('Lead', {}) // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9

      this.setState({
        loading: false,
        success: true,
        error: null,
        nom: '',
        societe: '',
        telephone: '',
        message: '',
      })
      debug('STATE :', this.state)
      return results
    } catch (err) {
      ReactGA.pageview('/callme-fail')
      this.setState({
        error: (
          <span>
            {this.context.t("Erreur lors de l'envoi de votre demande.")} <br />
            {this.context.t('Veuillez réessayer.')}
          </span>
        ),
        loading: false,
      })
      throw err
    }
  }
  openModal = () => {
    this.setState({
      modalOpen: true,
      error: false,
    })
    ReactGA.pageview('/callme-open')
  }
  handleClose = () => {
    this.setState({
      modalOpen: false,
      error: false,
    })
    ReactGA.pageview('/callme-close')
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>, { name, value }: any) => {
    this.setState({ [name]: value } as Pick<CallMeButtonState, keyof CallMeButtonState>)
  }

  render() {
    const { success, error, loading } = this.state
    return (
      <div style={{ display: 'inline-block' }}>
        {this.props.displayStyle === 'link' ? (
          <a onClick={this.openModal} href="#faites-vous-rappeler">
            {this.props.text}
          </a>
        ) : (
          <Button onClick={this.openModal} style={{ marginTop: '10px' }} size="big">
            {this.props.text}
          </Button>
        )}

        <Modal dimmer open={this.state.modalOpen} size="tiny" closeIcon onClose={this.handleClose}>
          <Modal.Header style={{ textAlign: 'center' }}>{this.context.t('Prise de contact')}</Modal.Header>
          <Modal.Content>
            <Form onSubmit={this.submit}>
              <input type="hidden" name="form-name" value="call-back-form-2" />
              <input type="hidden" name="location" value={window.location.href} />

              <Form.Field style={{ textAlign: 'left' }}>
                <label>{this.context.t('Nom')}</label>
                <Input name="nom" type="text" value={this.state.nom} onChange={this.handleChange} className="m-no-bottom" placeholder={this.context.t('Nom')} />
              </Form.Field>
              <Form.Field style={{ textAlign: 'left' }}>
                <label>{this.context.t('Société')}</label>
                <Input
                  name="societe"
                  type="text"
                  value={this.state.societe}
                  onChange={this.handleChange}
                  className="m-no-bottom"
                  placeholder={this.context.t('Société')}
                />
              </Form.Field>
              <Form.Field style={{ textAlign: 'left' }}>
                <label>{this.context.t('Téléphone')}</label>
                <Input
                  name="telephone"
                  type="tel"
                  value={this.state.telephone}
                  onChange={this.handleChange}
                  className="m-no-bottom"
                  placeholder={this.context.t('Téléphone')}
                />
              </Form.Field>
              <Form.Field
                style={{ textAlign: 'left' }}
                control={TextArea}
                label="Message"
                name="message"
                type="message"
                value={this.state.message}
                onChange={this.handleChange}
                className="m-no-bottom"
                placeholder="Message"
                rows={5}
              />
              <Segment basic className="center-text">
                {success && (
                  <React.Fragment>
                    <img
                      height="1"
                      width="1"
                      style={{ display: 'none' }}
                      alt=""
                      src="https://dc.ads.linkedin.com/collect/?pid=152225&conversionId=605089&fmt=gif"
                    />
                    <Message positive>{this.context.t('Bien reçu, nous vous rappellerons très vite.')}</Message>
                  </React.Fragment>
                )}
                {error && (
                  <React.Fragment>
                    <Message negative>{error}</Message>
                  </React.Fragment>
                )}
                <Button icon="check" primary size="large" loading={loading} disabled={loading} content={this.context.t('Valider')} type="submit" />
              </Segment>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default connect()(CallMeButton)
