import { ADD_CODE_PROMO, TOGGLE_FOOTER, TOGGLE_FULLSCREEN, TOGGLE_NAV, TOGGLE_DIMMER, TOGGLE_PREVIEW, TOGGLE_PUSHER } from '../actions/action_ui'
import { AnyAction } from 'redux'

export interface UiStateType {
  dimmer_visibility: boolean
  pusher_visibility: boolean
  nav_visibility: boolean
  footer_visibility: boolean
  fullscreen: boolean
  code_promo: string
  pusher_text?: string
  preview_visibility?: boolean
}

const INITIAL_STATE: UiStateType = {
  dimmer_visibility: false,
  pusher_visibility: false,
  nav_visibility: true,
  footer_visibility: true,
  fullscreen: false,
  code_promo: '',
}

export default function (state: UiStateType = INITIAL_STATE, action: AnyAction): UiStateType {
  // debug('reducer_users | action : ' , action);

  switch (action.type) {
    case TOGGLE_DIMMER:
      return { ...state, dimmer_visibility: action.visible }

    case TOGGLE_PUSHER:
      return { ...state, pusher_visibility: action.visible, pusher_text: action.text }

    case TOGGLE_NAV:
      return { ...state, nav_visibility: action.visible }

    case TOGGLE_FULLSCREEN:
      return { ...state, fullscreen: action.visible }

    case TOGGLE_FOOTER:
      return { ...state, footer_visibility: action.visible }

    case TOGGLE_PREVIEW:
      return { ...state, preview_visibility: action.visible }

    case ADD_CODE_PROMO:
      return { ...state, code_promo: action.code_promo }

    default:
      return state
  }
}
