import React, { Component, MouseEvent } from 'react'
import { Button, ButtonProps, Container, Divider, Grid, Header, Image, VisibilityEventData } from 'semantic-ui-react'
import Embed from 'react-runkit'
import Script from 'react-load-script'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import CallMeButton from '../../components/CallMeButton'
import marked from '../../utils/MarkdownRenderer'
import ContactUsButton from '../../components/ContactUsButton'
// import { BlocLogoCustomers } from '../../components/BlocLogoCustomers'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { LoadingComponent } from '../../components/Loading'
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic'

interface BigBlockProps {
  landing: any
  handleVideoClick: () => void
  startPlaying: (e: null, data: VisibilityEventData) => void
  playing: boolean
}

interface BigBlockState {
  runkitLoaded: boolean
  width: SemanticWIDTHS | null
  side: string
}

class BigBlock extends Component<BigBlockProps, BigBlockState> {
  static contextTypes = {
    t: PropTypes.func,
  }

  constructor(props: BigBlockProps) {
    super(props)
    this.state = {
      runkitLoaded: false,
      width: null,
      side: 'recto',
    }
  }

  handleScriptLoad = async (): Promise<any> => {
    this.setState({
      runkitLoaded: true,
    })
  }

  handleSideChange = (e: MouseEvent, data: ButtonProps): any => {
    // debug('handleColorChange : ', data.value)
    this.setState({
      side: data.value,
    })
  }

  getSourceCode = (sourceCodeType: string): any => {
    if (sourceCodeType === 'postcard') {
      return `
// Faites appel à la bibliothèque MySendingBox avec une clé test secrète.
const Seeuletter = require('seeuletter')('test_4345c732-1e06-4096-9446-a54054c9b470')

// Créez une nouvelle carte postale à partir d'un template HTML (vous pouvez aussi passer directement un PDF ou un lien vers un PDF)
const postcard = await Seeuletter.postcards.create({
  description: 'Test Postcard from the Node.js Wrapper',
  to: {
    name: 'Erlich',
    address_line1: '30 rue de rivoli',
    address_city: 'Paris',
    address_country: 'France',
    address_postalcode: '75004'
  },
  // https://www.seeuletter.com/templates
  source_file_front: 'Hy2GUkiyz',
  source_file_front_type: 'template_id',

  source_file_back: 'rkfnt1s1z',
  source_file_back_type: 'template_id',

  variables: {
    PRENOM: 'Erlich',
    NOM: 'Bachman',
    CODE_PROMO_BIENVENUE: 'CODE',
    URL_COURTE_BIENVENUE: 'https://goo.gl/uqTHnD',
    ADRESSE: '30 rue de Rivoli',
    CODE_POSTAL : '75004',
    VILLE : 'Paris',
    PAYS : 'France'

  }
})
`
    } else {
      return `
// Faites appel à la bibliothèque Seeuletter avec une clé test secrète.
const Seeuletter = require('seeuletter')('test_4345c732-1e06-4096-9446-a54054c9b470')

// Créez une nouvelle lettre à partir d'HTML (vous pouvez aussi passer directement un PDF ou un lien vers un PDF)
const letter = await Seeuletter.letters.create({
  description: 'Test Letter',
  to: {
    name: 'Erlich',
    address_line1: '30 rue de rivoli',
    address_city: 'Paris',
    address_country: 'France',
    address_postalcode: '75004'
  },
  postage_type: 'prioritaire',
  color: 'bw',
  source_file: '<html>Hello, {{nom}}</html>',
  source_file_type: 'html',
  variables: {
    nom : 'Erlich'
  }
})
`
    }
  }

  render(): JSX.Element {
    const { landing } = this.props
    const { side } = this.state

    return (
      <div>
        <Helmet>
          <title>{landing.title}</title>
          <meta name="description" content={landing.description} />

          <meta property="og:title" content={landing.title} />
          <meta property="og:description" content={landing.description} />
        </Helmet>
        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row centered verticalAlign="middle">
              <Grid.Column computer={landing.image ? 10 : 14} tablet="16" mobile="16" textAlign="center">
                <Grid columns="equal">
                  <Grid.Row centered>
                    <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                      <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                        {landing.header}
                      </Header>

                      <Header as="h2" textAlign="center" size="medium">
                        <div dangerouslySetInnerHTML={{ __html: marked(landing.subHeader || '<i>Aucun contenu</i>') }} />
                      </Header>
                      <Divider hidden />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content={landing.textButton || 'Commencer'} />
                      <CallMeButton text={this.context.t('Faites-vous rappeler')} />
                      {landing.textUnderButtons && (
                        <p style={{ marginTop: '10px' }}>
                          <small>{landing.textUnderButtons || 'Sans abonnement ni engagement.'}</small>
                        </p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              {landing.image && (
                <Grid.Column className={landing.imageHideMobile && 'mobile hidden tablet hidden'} computer={6} tablet="16" mobile="16" textAlign="center">
                  <Image className={`center-block`} width="90%" src={`${side === 'recto' ? landing.image : 'verso'}`} />

                  {landing.imageVerso && (
                    <Button.Group size="small">
                      <Button color={side === 'recto' ? 'violet' : undefined} value="recto" onClick={this.handleSideChange}>
                        Recto
                      </Button>
                      <Button color={side === 'verso' ? 'violet' : undefined} value="verso" onClick={this.handleSideChange}>
                        Verso
                      </Button>
                    </Button.Group>
                  )}
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />
                <Divider section />
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${landing.bloc1Icon || 'mysendingbox/icons/api_1'}.png`}
                        centered
                        style={{ display: 'block', width: '80%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        {landing.bloc1Title}
                      </Header>
                      <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc1Text || '<i>Aucun contenu</i>') }} />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="m-top-30">
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        {landing.bloc2Title}
                      </Header>
                      <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc2Text || '<i>Aucun contenu</i>') }} />
                    </Grid.Column>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${landing.bloc2Icon || 'mysendingbox/icons/api_2'}.png`}
                        centered
                        style={{ display: 'block', width: '80%', margin: 'auto' }}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="m-top-30" style={{ paddingTop: '40px' }}>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${landing.bloc3Icon || 'mysendingbox/icons/api_3'}.png`}
                        centered
                        style={{ display: 'block', width: '80%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        {landing.bloc3Title}
                      </Header>
                      <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc3Text || '<i>Aucun contenu</i>') }} />
                    </Grid.Column>
                  </Grid.Row>

                  {landing.bloc4Title && (
                    <Grid.Row className="m-top-30" style={{ paddingTop: '40px' }}>
                      <Grid.Column computer="12" tablet="12" mobile="13">
                        <Header as="h3" size="medium">
                          {landing.bloc4Title}
                        </Header>
                        <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc4Text || '<i>Aucun contenu</i>') }} />
                      </Grid.Column>
                      <Grid.Column computer="3" tablet="3" mobile="3">
                        <Image
                          src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${
                            landing.bloc4Icon || 'Transmission-r1XllzfcX_xv2alp'
                          }.png`}
                          centered
                          style={{ display: 'block', width: '80%', margin: 'auto' }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Grid.Column>
            </Grid.Row>

            {landing.hideBlocCustomers ? null : <React.Fragment />}

            {landing.hideBlocCode ? null : (
              <React.Fragment>
                <Grid.Row centered>
                  <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                    <Divider section />
                    <Divider hidden />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                  <Grid.Column computer={12} tablet="10" mobile="16" textAlign="center" verticalAlign="middle">
                    <Header as="h3" style={{ fontSize: '2em' }}>
                      {landing.headerVideo || 'Moderne et évolutive'}
                    </Header>

                    <Header size="small" textAlign="center">
                      {landing.subHeaderVideo || "Testez dès maintenant l'API d'envoi de courrier la plus simple et moderne."}
                    </Header>
                    <p>
                      Librairies disponibles :{' '}
                      <a href="https://github.com/seeuletter/seeuletter-node" title="Package Node.js - API MySendingBox d'envoi de courrier">
                        Node.js
                      </a>
                      ,{' '}
                      <a href="https://github.com/seeuletter/seeuletter-ruby" title="Package Ruby - API MySendingBox d'envoi de courrier">
                        Ruby
                      </a>
                      ,{' '}
                      <a href="https://github.com/seeuletter/seeuletter-php" title="Package PHP - API MySendingBox d'envoi de courrier">
                        PHP
                      </a>
                      ,{' '}
                      <a href="https://github.com/seeuletter/seeuletter-python" title="Package Python - API MySendingBox d'envoi de courrier">
                        Python
                      </a>
                      ,{' '}
                      <a href="https://github.com/seeuletter/seeuletter-java" title="Package Java - API MySendingBox d'envoi de courrier">
                        Java
                      </a>
                      .
                    </p>
                  </Grid.Column>

                  <Grid.Column width="16">
                    <Divider hidden />
                  </Grid.Column>

                  <Grid.Column computer={12} tablet="10" mobile="16" textAlign="center">
                    <LazyLoad placeholder={<LoadingComponent />}>
                      <div style={{ textAlign: 'left' }}>
                        <Script url="https://embed.runkit.com" onLoad={this.handleScriptLoad} />

                        {this.state.runkitLoaded ? (
                          <Embed
                            syntaxTheme="stripe-light"
                            nodeVersion="8.x.x"
                            uiStyles={{
                              borderColor: 'transparent',
                              controlsBackground: '#f6f9fc',
                              controlsColor: '#6772e5',
                              hoverColor: '#32325d',
                              logoColor: '#6772e5',
                              editedColor: '#6772e5',
                              runButtonBackground: '#01c3a7',
                              runButtonColor: '#fff',
                              runButtonBorder: 'transparent',
                            }}
                            gutterStyle="inside"
                            source={this.getSourceCode(landing.sourceCodeType)}
                          />
                        ) : (
                          <LoadingComponent />
                        )}
                      </div>
                    </LazyLoad>

                    <Grid>
                      <Grid.Row centered>
                        <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                          <Divider hidden />
                          <Header size="small">{landing.headerApi || "Envoyez un courrier par la Poste en quelques lignes de code avec l'API."}</Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <Divider hidden />

                    <Button color="blue" as={Link} to="/guide/bien-demarrer-avec-seeuletter-envoi-courrier-carte-postale-en-ligne">
                      Guide de démarrage
                    </Button>
                    <Button href="https://docs.MySendingBox.fr/" target="_blank">
                      Consulter la documentation
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider section />
                <Divider hidden />

                <Header as="h2">{landing.headerBottomBloc || 'Le courrier intelligent avec MySendingBox'}</Header>

                <Header size="small">{landing.subHeaderBottomBloc || 'Une API moderne et simple pour envoyer du courrier par la Poste.'}</Header>

                <Divider hidden />
                <Divider hidden />

                <Button as={Link} to="/signup/?nav=false" size="big" color="blue" content={landing.textButton || 'Récupérez votre clé API'} />
                <CallMeButton text="Faites-vous rappeler" />

                <Divider section hidden />

                <ContactUsButton displayAs="link" buttonText="Des questions ? Contactez-nous." />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default BigBlock
