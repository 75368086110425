import React, { FC } from 'react'
import { Button, Container, Divider, Grid, Header, Image } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import CallMeButton from '../../components/CallMeButton'

const Retention: FC = (): JSX.Element => {
  return (
    <div>
      <Helmet>
        <title>Rétention - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
        <meta
          name="description"
          content="Fidélisez vos clients grâce au courrier. Entretenez une relation privilégiée avec vos clients en leur adressant des lettres ou des cartes postales personnalisées."
        />

        <meta property="og:title" content="Rétention - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
        <meta
          property="og:description"
          content="Fidélisez vos clients grâce au courrier. Entretenez une relation privilégiée avec vos clients en leur adressant des lettres ou des cartes postales personnalisées."
        />
      </Helmet>

      <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
        <Divider hidden />

        <Grid columns="equal">
          <Grid.Row centered>
            <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
              <Button.Group size="medium">
                <Button as={Link} to="/solutions/acquisition">
                  ACQUISITION
                </Button>
                <Button color="violet" as={Link} to="/solutions/retention">
                  RETENTION
                </Button>
              </Button.Group>

              <Divider hidden />

              <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                Fidélisez vos clients grâce au courrier.
              </Header>

              <Header as="h2" textAlign="center" size="medium">
                Entretenez une relation privilégiée avec vos clients en leur adressant <br />
                des lettres ou des cartes postales personnalisées.
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column width="16" textAlign="center">
              <Button as={Link} to="/solutions/" size="big" primary content="Découvrir nos solutions" />

              <CallMeButton text="Faites-vous rappeler" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider section />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16">
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_120/mysendingbox/icons/retention_1.png"
                      centered
                      style={{ display: 'block', width: '70%', margin: 'auto' }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Automatisez vos courriers de fidélisation
                    </Header>
                    <p>
                      Adressez le bon courrier au bon moment en fonction d’évènements spécifiques.
                      <br />
                      Connectez MySendingBox à vos applications de marketing automation.
                    </p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="m-top-30">
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Suivez le ROI de vos campagnes courriers
                    </Header>
                    <p>Ajoutez des call to action spécifiques à vos campagnes courriers (codes promotionnels personnalisés, offres privilégiées...)</p>
                  </Grid.Column>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_120/mysendingbox/icons/retention_2.png"
                      centered
                      style={{ display: 'block', width: '70%', margin: 'auto' }}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="m-top-30">
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_120/mysendingbox/icons/retention_3.png"
                      centered
                      style={{ display: 'block', width: '70%', margin: 'auto' }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Démarquez vous dans votre communication client
                    </Header>
                    <p>Valorisez l’information par l’envoi d’un courrier ou d’une carte postale personnalisée pour chaque client.</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider hidden />
              <Divider section />
              <Divider hidden />

              <Header as="h2">Le courrier intelligent avec MySendingBox</Header>

              <Header size="small">Une suite d'outils complète pour envoyer du courrier postal et des cartes postales</Header>
              <Divider hidden />
              <Divider hidden />

              <Button as={Link} to="/solutions/" size="big" primary content="Découvrir nos solutions" />

              <CallMeButton text="Faites-vous rappeler" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default Retention
