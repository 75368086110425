import React, { Component, MouseEvent } from 'react'
import { Button, Card, Container, Divider, Grid, Header, Icon, Image, List, Segment, Loader, ButtonProps } from 'semantic-ui-react'
import queryString from 'query-string'

import Debug from 'debug'
import { Helmet } from 'react-helmet'
import { createClient } from 'contentful'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import { Link, RouteChildrenProps } from 'react-router-dom'
import { logException } from '../utils/ErrorLogger'

const debug = Debug('app:containers:Templates')

type TemplatesProps = RouteChildrenProps

interface TemplateState {
  loading: boolean
  templates: any | null
  type: string
}

class Templates extends Component<TemplatesProps, TemplateState> {
  constructor(props: TemplatesProps) {
    super(props)

    const query = queryString.parse(props.location.search)

    this.state = {
      loading: false,
      templates: null,
      type: query.type === 'letter' ? 'letter' : 'postcard',
    }
  }

  async componentDidMount(): Promise<void> {
    await this.fetchTemplates()
  }

  fetchTemplates = async (): Promise<any> => {
    try {
      let preview = false
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        preview = true
      }

      const client = createClient({
        space: 'uu95v6ljdw3h',
        host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
        accessToken: preview
          ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
          : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
      })

      debug('Fetching Entries....')

      const response = await client.getEntries<any>({
        content_type: 'templates',
        order: '-sys.createdAt',
      })
      debug(response.items)
      const items = orderBy(
        response.items.map((item: any) => {
          return item.fields
        }),
        ['order'],
        ['asc']
      )

      this.setState({
        templates: groupBy(items, 'type'),
      })
      debug("groupBy(items, 'type'): ", groupBy(items, 'type'))
    } catch (ex) {
      logException(ex)
    }
  }

  handleTypeChange = (e: MouseEvent, data: ButtonProps): any => {
    // debug('handleColorChange : ', data.value)
    this.setState({
      type: data.value,
    })

    this.props.history.replace({
      search: queryString.stringify({
        type: data.value,
      }),
    })
  }

  render(): JSX.Element {
    const { templates, type } = this.state

    return (
      <div>
        <Helmet>
          <title>Templates - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          <meta
            name="description"
            content="Bibliotèque de template HTML pour l'API d'envoi de courrier et de carte postale MySendingBox. Simplifiez-vous la vie en utilisant nos modèles de courrier existant."
          />

          <meta property="og:title" content="Templates - MySendingBox - Solutions d'envoi de courrier et de carte postale" />

          <meta
            property="og:description"
            content="Bibliotèque de template HTML pour l'API d'envoi de courrier et de carte postale ou de carte postale MySendingBox. Simplifiez-vous la vie en utilisant nos modèles de courrier existant."
          />

          <meta property="og:image" content="https://res.cloudinary.com/lifebot/image/upload/c_pad,g_center,h_630,w_1200/postcard_welcome_recto_ote3g4.png" />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%' }}>
          <Divider hidden />

          <Grid columns="equal">
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={16} textAlign="center">
                <Header as="h1" style={{ fontSize: '3em' }}>
                  Modèles
                  <Header.Subheader>Des modèles de {this.state.type === 'postcard' ? 'cartes postales' : 'lettres'} prêts à l'emploi</Header.Subheader>
                  {/* <Header.Subheader>
                    Créez vos templates HTML pour optimiser vos envois de courrier via l'API MySendingBox.
                  </Header.Subheader> */}
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="6" tablet="10" mobile="14" textAlign="center">
                <Divider />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="6" tablet="10" mobile="14" textAlign="center">
                <Button.Group size="large">
                  <Button
                    color={type === 'postcard' ? 'violet' : undefined}
                    value="postcard"
                    as={Link}
                    onClick={this.handleTypeChange}
                    to="/templates?type=postcard"
                  >
                    Carte Postale
                  </Button>
                  <Button color={type === 'letter' ? 'violet' : undefined} value="letter" as={Link} onClick={this.handleTypeChange} to="/templates?type=letter">
                    Courrier
                  </Button>
                </Button.Group>
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="3" tablet="3" mobile="16">
                <Header>{this.state.type === 'postcard' ? 'Carte postale' : 'Courrier'}</Header>
                <p className="justify-text">
                  MySendingBox vous permez de très facilement créer des <b>campagnes de {this.state.type === 'postcard' ? 'carte postale' : 'courrier'}</b> avec
                  un <b>design unique</b> pour chaque destinataire.
                </p>

                <Header size="small">
                  <b>3 étapes :</b>
                </Header>
                <List>
                  <List.Item as="a">
                    <Icon name="file excel outline" />
                    <List.Content>
                      <List.Header>Déposez votre liste de destinataire</List.Header>
                      <List.Description>Ajouter un fichier Excel avec les adresses postales de vos destinataires.</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item as="a">
                    <Icon name="image outline" />
                    <List.Content>
                      <List.Header>Créez votre design</List.Header>
                      <List.Description>
                        Personnalisez votre carte postale directement depuis {this.state.type === 'postcard' ? 'PowerPoint' : 'Word'}. (Textes, images,
                        polices...)
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item as="a">
                    <Icon name="send" />
                    <List.Content>
                      <List.Header>Envoyez</List.Header>
                      <List.Description>
                        Validez et c'est envoyé ! A partir de {this.state.type === 'postcard' ? '1,35€/carte' : '0,85€/courrier'}.{' '}
                        <b>Affranchissement inclus</b>
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>

                <br />
                <Button fluid primary size="big" as={Link} to="/app/dashboard/campaigns">
                  Commencer
                </Button>
              </Grid.Column>

              <Grid.Column computer="1">
                <Divider hidden />
              </Grid.Column>
              <Grid.Column computer="9" tablet="9" mobile="16">
                <Grid>
                  <Grid.Row centered={false}>
                    {templates && templates[this.state.type] ? (
                      templates[this.state.type].map((template: any) => {
                        return (
                          <Grid.Column key={template.url} computer="8" tablet="8" mobile="16" textAlign="center">
                            <Card raised fluid link as={Link} to={`/templates/${template.url}`}>
                              <Image
                                src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_700/${
                                  template.type === 'letter' ? template.image_url : template.image_url_front
                                }`}
                              />
                              <Card.Content extra textAlign="left">
                                <Icon name={template.type === 'letter' ? 'file text outline' : 'image'} />
                                {template.title}
                              </Card.Content>
                            </Card>
                            <Divider hidden />
                          </Grid.Column>
                        )
                      })
                    ) : (
                      <Grid.Column width="16" textAlign="center">
                        <Segment basic style={{ minHeight: '300px' }}>
                          <Loader active />
                        </Segment>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default Templates
