import React, { Component } from 'react'
import { Icon, Label, Menu } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

interface NavLinkCustomProps {
  [x: string]: any
  name: string
  isExternal?: boolean
  target?: string
  icon: any
  label?: any
  to: string
}

class NavLinkCustom extends Component<NavLinkCustomProps> {
  render(): JSX.Element {
    const { name, isExternal, target, icon, label, ...other } = this.props

    const iconElement = icon ? <Icon color="orange" name={icon} /> : undefined

    if (isExternal) {
      return (
        <Menu.Item title={name} {...other} href={this.props.to} link target={target} rel={target === '_blank' && 'noopener noreferrer'}>
          <span className="">{iconElement}</span>
          <span className="mobile hidden" style={{ fontSize: '17px' }}>
            &nbsp;&nbsp;&nbsp;{name}
          </span>
          {label && (
            <Label size="tiny" color="orange" attached="top right" title={label.title}>
              {label.text}
            </Label>
          )}
        </Menu.Item>
      )
    } else {
      return (
        <Menu.Item title={name} {...other} as={NavLink} link>
          <span className="">{iconElement}</span>
          <span className="mobile hidden" style={{ fontSize: '17px' }}>
            &nbsp;&nbsp;&nbsp;{name}
          </span>
          {label && (
            <Label size="tiny" color="orange" attached="top right" title={label.title}>
              {label.text}
            </Label>
          )}
        </Menu.Item>
      )
    }
  }
}

export default NavLinkCustom
