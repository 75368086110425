import Debug from 'debug'

const debug = Debug('app:utils:Constants')

function api_url(): string {
  debug('ENV : ', process.env.REACT_APP_NODE_ENV)

  let api_url_value = ''
  if (process.env.REACT_APP_BASE_URL) {
    api_url_value = process.env.REACT_APP_BASE_URL
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      api_url_value = api_url_value + '/api'
    }
  } else {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      api_url_value = 'http://localhost:8081/api'
    } else if (process.env.REACT_APP_NODE_ENV === 'production') {
      api_url_value = 'https://api.mysendingbox.fr'
    } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
      api_url_value = 'https://api-staging.mysendingbox.fr'
    } else {
      api_url_value = 'http://localhost:8081/api'
    }
  }
  debug('api_url_value: ', api_url_value)
  return api_url_value
}

export const API_URL = api_url()

function stripe_key(): string {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return 'pk_live_wEpB4YDJMA0M214rLtjml3JV'
  } else {
    return 'pk_test_D5rGrKbMLCX03I1EanSqKxu6'
  }
}

export const STRIPE_KEY = stripe_key()

export const variables = {
  facebook_url: 'https://www.facebook.com/seeuletter.api/',
  twitter_url: 'https://twitter.com/seeuletter',
  linkedin_url: 'https://www.linkedin.com/company/mysendingbox',
  github_url: 'https://github.com/seeuletter',
  email: 'hello@mysendingbox.fr',
  help_url: 'https://aide.MySendingBox.fr',
  fb_app_id: '177842592673678',
  default_html_2: `<html>
<head>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type">
</head>
<body>

</body>
</html>`,
  default_html: `<html>
<head>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type">

    <style>
        @import url('https://fonts.googleapis.com/css?family=Satisfy');/* latin */

        @font-face {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/MDadn8DQ_3oT6kvnUq_2r_esZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
        }

        * {
            font-family: 'Lato', sans-serif;
            font-size: 12px
        }
        .signature {
          font-family: 'Satisfy', cursive;
          font-size: 30px;
        }

    </style>
</head>
<body>
<page backtop="10mm" backbottom="10mm" backleft="5mm" backright="5mm">

    <table style="width: 100%;">


        <p>

            {{expediteur.prenom}} {{expediteur.nom}}

            <br/>
            {{expediteur.adresse.numero}} {{expediteur.adresse.voie}}
            <br/>
            {{expediteur.adresse.code_postal}} {{expediteur.adresse.ville}}
        </p>
        <p align="right">

           {{destinataire.prenom}} {{destinataire.nom}}

            <br/>
            {{destinataire.adresse.numero}} {{destinataire.adresse.voie}}
            <br/>
            {{destinataire.adresse.code_postal}} {{destinataire.adresse.ville}}
        </p>
        <p align="right">
            Fait à {{fait.lieu}}, le {{fait.date}}.
        </p>
        <p>
            <br/>
            <br/>
        </p>
        <p>
            <u>Objet : {{objet}}.</u>
        </p>
        <p>
            Madame, Monsieur,
        </p>

        <p>
            {{{contenu}}}
        </p>

        <div style="float: right; margin-right:30px; margin-top:30px;">
            <span class="signature">{{expediteur.signature}}</span>
        </div>

    </table>

</page>
</body>
</html>`,
}
