import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header, List, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { variables } from './../utils/Constants'
import { connect, ConnectedProps } from 'react-redux'
import LabelStatus from '../components/LabelStatus'
import { JsonLd } from '../utils/Utils'
import { RootReducerType } from '../reducers'

const connector = connect((state: RootReducerType) => ({
  ui: state.ui,
}))

type FooterProps = ConnectedProps<typeof connector> & {
  produit?: boolean
  prix?: boolean
  link?: boolean
}

class Footer extends Component<FooterProps> {
  /* switchLanguage (lang) {
    this.props.dispatch(updateLanguage(lang))
  } */

  render() {
    return (
      <Container id="footer" fluid style={{ backgroundColor: '#44546a', margin: '0px' }}>
        <JsonLd
          data={{
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: 'https://www.mysendingbox.fr',
            logo: 'https://res.cloudinary.com/lifebot/image/upload/v1505913415/logo_bleu_grand_xd5tfw.png',
            sameAs: ['https://www.linkedin.com/company/mysendingbox'],
            contactPoint: [
              {
                '@type': 'ContactPoint',
                // telephone: '+33175858501',
                contactType: 'customer service',
                areaServed: ['English', 'French'],
                contactOption: 'TollFree',
              },
            ],
          }}
        />
        {!!this.props.ui.footer_visibility && (
          <div>
            <Divider hidden />
            <Divider hidden />
          </div>
        )}
        <Container>
          <Grid divided inverted stackable>
            {!!this.props.ui.footer_visibility && (
              <Grid.Row columns="equal">
                <Grid.Column textAlign="left">
                  {process.env.REACT_APP_WHITE_LABEL === 'canon_fac_simile' ? (
                    <Header inverted to="/" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }}>
                      {/* <span style={{ fontWeight: '500', fontSize: '0.9em', marginLeft: '-10px'}}>「 MySendingBox 」by CORUS</span> */}

                      <span style={{ fontWeight: 500, fontSize: '0.9em' }}>Fac-similé Rhône-Alpes</span>
                      <Header.Subheader>L’agilité d’une PME avec la force d’un groupe Partenaire de Corus</Header.Subheader>
                    </Header>
                  ) : process.env.REACT_APP_WHITE_LABEL === 'axena' ? (
                    <Header inverted to="/" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }}>
                      {/* <span style={{ fontWeight: '500', fontSize: '0.9em', marginLeft: '-10px'}}>「 MySendingBox 」by CORUS</span> */}

                      <span style={{ fontWeight: 500, fontSize: '0.9em' }}>Axena</span>
                      <Header.Subheader>L’agilité d’une PME avec la force d’un groupe Partenaire de Corus</Header.Subheader>
                    </Header>
                  ) : process.env.REACT_APP_WHITE_LABEL === '3h18' ? (
                    <Header inverted to="/" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }}>
                      {/* <span style={{ fontWeight: '500', fontSize: '0.9em', marginLeft: '-10px'}}>「 MySendingBox 」by CORUS</span> */}

                      <span style={{ fontWeight: 500, fontSize: '0.9em' }}>3H18</span>
                      <Header.Subheader>L’agilité d’une PME avec la force d’un groupe Partenaire de Corus</Header.Subheader>
                    </Header>
                  ) : process.env.REACT_APP_WHITE_LABEL === 'euro_tvs' ? (
                    <Header inverted to="/" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }}>
                      {/* <span style={{ fontWeight: '500', fontSize: '0.9em', marginLeft: '-10px'}}>「 MySendingBox 」by CORUS</span> */}

                      <span style={{ fontWeight: 520, fontSize: '0.7em' }}>Euro TVS</span>
                      <Header.Subheader>L’agilité d’une PME avec la force d’un groupe Partenaire de Corus</Header.Subheader>
                    </Header>
                  ) : process.env.REACT_APP_WHITE_LABEL === 'e-print' ? (
                    <Header inverted to="/" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }}>
                      {/* <span style={{ fontWeight: '500', fontSize: '0.9em', marginLeft: '-10px'}}>「 MySendingBox 」by CORUS</span> */}

                      <span style={{ fontWeight: 520, fontSize: '0.7em' }}>E-print</span>
                      <Header.Subheader>L’agilité d’une PME avec la force d’un groupe Partenaire de Corus</Header.Subheader>
                    </Header>
                  ) : process.env.REACT_APP_WHITE_LABEL === 'sederoni' ? (
                    <Header inverted to="/" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }}>
                      {/* <span style={{ fontWeight: '500', fontSize: '0.9em', marginLeft: '-10px'}}>「 MySendingBox 」by CORUS</span> */}

                      <span style={{ fontWeight: 520, fontSize: '0.7em' }}>Sederoni</span>
                      <Header.Subheader>L’agilité d’une PME avec la force d’un groupe Partenaire de Corus</Header.Subheader>
                    </Header>
                  ) : (
                    <Header inverted to="/" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }}>
                      {/* <span style={{ fontWeight: '500', fontSize: '0.9em', marginLeft: '-10px'}}>「 MySendingBox 」by CORUS</span> */}

                      <span style={{ fontWeight: 500, fontSize: '0.9em' }}>「 MySendingBox 」by CORUS</span>
                      <Header.Subheader>Solutions d'envoi de courrier et de carte postale</Header.Subheader>
                    </Header>
                  )}
                  <br />
                  <Segment color="violet" inverted basic style={{ margin: '0px', padding: '0px' }}>
                    <List divided horizontal inverted>
                      <List.Item as="a" href="https://docs.MySendingBox.fr/" target="_blank" rel="noopener noreferrer">
                        Documentation API
                      </List.Item>
                      <List.Item as={Link} to="/guide/bien-demarrer-avec-seeuletter-envoi-courrier-carte-postale-en-ligne">
                        Guide utilisateur
                      </List.Item>
                      <List.Item as={Link} to="/blog/">
                        Blog
                      </List.Item>
                    </List>
                  </Segment>
                  <br />
                  <Segment color="violet" inverted basic style={{ margin: '0px', padding: '0px' }}>
                    <List divided horizontal inverted>
                      <List.Item as="a" href="https://www.corus.fr/" target="_blank" rel="noopener noreferrer">
                        Corus
                      </List.Item>
                    </List>
                  </Segment>
                  <br />
                  <Button
                    inverted
                    title="Repo Github de MySendingBox"
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={variables.github_url}
                    circular
                    icon="github"
                  />
                  &nbsp;&nbsp;
                  {/*                   <Button
                    inverted
                    title="Page Twitter de MySendingBox"
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={variables.twitter_url}
                    circular
                    icon="twitter"
                  />
                  &nbsp;&nbsp; */}
                  <Button
                    inverted
                    title="Page Linkedin de MySendingBox"
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={variables.linkedin_url}
                    circular
                    icon="linkedin"
                  />
                  &nbsp;&nbsp;
                  {/*                   <Button
                    inverted
                    title="Page Facebook de MySendingBox"
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={variables.facebook_url}
                    circular
                    icon="facebook"
                  />
                  &nbsp;&nbsp; */}
                  <Button
                    inverted
                    title="Adresse e-mail de MySendingBox"
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${variables.email}`}
                    circular
                    icon="mail"
                  />
                  <br />
                  <br />
                  {/*
                  <Label color="grey" size="small" as="a" onClick={() => this.switchLanguage("fr")}>
                    <Flag name="fr" />
                    Français
                  </Label>
                  <Label color="grey" size="small" as="a" onClick={() => this.switchLanguage("en")}>
                    <Flag name="gb" />
                    English
                  </Label>

                  <br />
                  <br /> */}
                  <LabelStatus />
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <Header inverted to="/solutions" as={Link}>
                    Solutions
                  </Header>

                  <Segment color="violet" inverted basic>
                    <List inverted>
                      <List.Item>
                        Marketing
                        <List.List>
                          <List.Item as={Link} to="/solutions/acquisition">
                            Acquisition
                          </List.Item>
                          <List.Item as={Link} to="/solutions/retention">
                            Fidélisation
                          </List.Item>
                        </List.List>
                      </List.Item>
                      <List.Item>
                        Transactionnelles
                        <List.List>
                          <List.Item as={Link} to="/solutions/facturation-envoi-de-facture-par-courrier">
                            Facturation
                          </List.Item>
                          <List.Item as={Link} to="/solutions/recouvrement-envoi-de-recommande-par-courrier">
                            Impayés
                          </List.Item>
                        </List.List>
                      </List.Item>
                    </List>
                  </Segment>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <Header inverted to="/" as={Link}>
                    Produits
                  </Header>

                  <Segment color="violet" inverted basic>
                    <List inverted>
                      <List.Item as={Link} to="/services/letters">
                        Courrier
                      </List.Item>
                      <List.Item as={Link} to="/services/postcards">
                        Carte postale
                      </List.Item>

                      <List.Item>&nbsp;</List.Item>

                      <List.Item as={Link} to="/templates">
                        Modèles
                      </List.Item>

                      <List.Item as={Link} to="/guide/affranchissement-lettre-verte-prioritaire-suivie-recommande">
                        Lettre prioritaire
                      </List.Item>
                      <List.Item as={Link} to="/guide/lettre-recommande-preuve-de-depot-prv-lrel">
                        Lettre recommandée
                      </List.Item>
                    </List>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row columns="equal">
              <Grid.Column>
                <Segment textAlign="center" color="violet" inverted basic>
                  <List divided horizontal inverted size="tiny">
                    <List.Item>LIFEBOT, SAS inscrite au Registre du Commerce et des Sociétés de Lyon, sous le numéro 829 125 988</List.Item>
                  </List>
                  <br />

                  <List divided horizontal inverted size="tiny">
                    <List.Item as="a" target="_blank" href="/legal/cgv/">
                      CGV
                    </List.Item>
                    <List.Item as="a" target="_blank" href="/legal/cgu/">
                      CGU
                    </List.Item>
                    <List.Item as="a" target="_blank" href="/legal/privacy-policy/">
                      Politique de confidentialité
                    </List.Item>
                    {/* <List.Item as="a" target="_blank" href="tel:+33175858501" title="Numéro de téléphone de MySendingBox">
                      +33 (0)1 75 85 85 01
                    </List.Item> */}
                    <List.Item as="a" target="_blank" href="mailto:hello@mysendingbox.fr" title="Adresse e-mail de MySendingBox">
                      hello@mysendingbox.fr
                    </List.Item>
                  </List>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Container>
    )
  }
}

export default connector(Footer)
