import { combineReducers } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'

import { reducer as formReducer } from 'redux-form'
import authReducer, { AuthStateType } from './reducer_auth'
import userReducer, { UserStateType } from './reducer_user'
import uiReducer, { UiStateType } from './reducer_ui'
import workflowReducer from './reducer_workflow'
import { i18nState, IreduxI18nState } from 'redux-i18n'
import reduceReducers from 'reduce-reducers'
import { History } from 'history'

export default (history: History): any =>
  combineReducers({
    router: connectRouter(history),
    form: reduceReducers<any>({ allowChange: true }, formReducer, workflowReducer),
    i18nState: i18nState,
    auth: authReducer,
    user: userReducer,
    ui: uiReducer,
  })

export interface RootReducerType {
  router: RouterState
  i18nState: IreduxI18nState
  auth: AuthStateType
  user: UserStateType
  ui: UiStateType
}
