import React, { Component, MouseEvent } from 'react'
import { Button, ButtonProps, Container, Divider, Grid, Header, Icon, Image } from 'semantic-ui-react'
import { AppMedia } from '../../components/MediaHoc'
import { Link } from 'react-router-dom'
import Debug from 'debug'
import Helmet from 'react-helmet'
import { IntercomAPI } from 'react-intercom'

const debug = Debug('app:containers:My_Postcard')

const { Media } = AppMedia
interface My_PostcardState {
  quality: string
}

class My_Postcard extends Component<any, My_PostcardState> {
  constructor(props: any) {
    super(props)
    this.state = {
      quality: '',
    }
  }

  handleQualityChange = (e: MouseEvent, data: ButtonProps): any => {
    debug('data : ', data)
    this.setState({
      quality: data.value,
    })
    if (data.value === 'bof') {
      IntercomAPI('showNewMessage', 'Bonjour, je suis déçu de la qualité de la carte postale que je viens de recevoir...')
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <Helmet>
          <title>Vous avez reçu une carte postale - MySendingBox</title>
          <meta
            name="description"
            content="Intégrez la solution (API) d'impression et d'envoi de courrier la plus flexible et moderne pour envoyer du courrier très simplement depuis votre application ou site web."
          />

          <meta property="og:title" content="Vous avez reçu une carte postale - MySendingBox" />
          <meta
            property="og:description"
            content="Intégrez la solution (API) d'impression et d'envoi de courrier la plus flexible et moderne pour envoyer du courrier ou des cartes postales très simplement depuis votre application ou site web."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row centered>
              <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '40px' }}>
                  Si vous êtes ici, <br />
                  c'est que vous avez reçu notre carte postale !{' '}
                  <span aria-label="party smiley" role="img">
                    🎉
                  </span>
                </Header>

                {/* <Header as="h2" textAlign="center" size="medium">
                  MySendingBox vous permet de facilement intégrer <br/>
                  l'envoi de carte postale dans vos process marketing.
                </Header> */}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="12" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            {this.state.quality === '' && (
              <Grid.Row centered>
                <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                  <Header size="large" textAlign="center">
                    Qu'en pensez-vous ?
                  </Header>
                  <Divider hidden />
                  <Button size="massive" value="top" onClick={this.handleQualityChange}>
                    <span aria-label="top" role="img">
                      👍
                    </span>
                  </Button>
                  <Button size="massive" value="bof" onClick={this.handleQualityChange}>
                    <span aria-label="bof" role="img">
                      👎
                    </span>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}

            {this.state.quality === 'top' && (
              <Grid.Row centered>
                <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                  <Header size="large" textAlign="center">
                    Great !{' '}
                    <span aria-label="top" role="img">
                      😉
                    </span>
                    <br />
                    Vous souhaitez en envoyer à vos clients ?
                    <br />
                    Créez une campagne dès maintenant.
                  </Header>
                </Grid.Column>
              </Grid.Row>
            )}
            {this.state.quality === 'top' && (
              <Grid.Row centered>
                <Grid.Column computer="12" tablet="14" mobile="16" textAlign="center">
                  <Divider hidden />
                </Grid.Column>
              </Grid.Row>
            )}
            {this.state.quality === 'top' && (
              <Grid.Row centered>
                <Grid.Column computer="4" tablet="6" mobile="8" textAlign="center">
                  <Header color="violet" as="h3" icon textAlign="center">
                    <Icon name="code" circular />
                    <Header.Content>API</Header.Content>
                  </Header>

                  <Button color="violet" as={Link} to="/app/dashboard/keys">
                    Découvrir l'API
                  </Button>
                  <Divider hidden />
                  <p>Intégrez notre API pour déclencher des envois depuis vos outils interne.</p>
                </Grid.Column>
                <Grid.Column computer="4" tablet="6" mobile="8" textAlign="center">
                  <Header primary as="h3" icon textAlign="center">
                    <Icon name="send" circular />
                    <Header.Content>Campagne</Header.Content>
                  </Header>

                  <Button primary as={Link} to="/app/dashboard/campaigns">
                    Créer une campagne
                  </Button>
                  <Divider hidden />
                  <p>Créez une campagne de carte postale entièrement personnalisée, sans coder, en quelques clics.</p>
                </Grid.Column>
              </Grid.Row>
            )}

            {this.state.quality === 'bof' && (
              <Grid.Row centered>
                <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                  <Header size="large" textAlign="center">
                    Oups, dites nous ce qui vous a déplu <br /> pour que nous puissions l'améliorer.{' '}
                    <span aria-label="top" role="img">
                      😕
                    </span>
                  </Header>
                  <Button
                    onClick={() => {
                      IntercomAPI('showNewMessage', 'Bonjour, je suis déçu de la qualité de la carte postale que je viens de recevoir...')
                    }}
                  >
                    Envoyez nous un message
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Divider hidden />
                <Divider section />
                <Divider hidden />

                <Header size="large" textAlign="center">
                  Petit rappel, MySendingBox c'est...
                </Header>
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="6" tablet="14" mobile="16">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/personnaliser_fxgj8u.png" /> Une API
                      </Header>
                      <p style={{ marginLeft: '60px' }}>
                        Pour facilement intégrer l'envoi de courrier ou de carte postale dans <b>vos process</b>.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/eco_electricity_icon_t9x6ru.png" /> Un
                        Dashboard
                      </Header>
                      <p style={{ marginLeft: '60px' }}>
                        Pour créer des campagnes <b>sans coder</b>. Et retrouver tous vos envois de courriers et de cartes postales.
                      </p>
                      <Divider as={Media} at="tablet" hidden />
                      <Divider as={Media} at="mobile" hidden />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column computer="6" tablet="14" mobile="16">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/website/cash-HJABZ84Mb_uh8tld.png" /> Sans
                        engagement
                      </Header>
                      <p style={{ marginLeft: '60px' }}>
                        Vous ne payez que ce que vous consommez, tout en bénéficiant de nos <b>tarifs dégressifs</b>.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/contenu_certifié_jr3ahp.png" /> Un support
                        au top
                      </Header>
                      <p style={{ marginLeft: '60px' }}>
                        Une fonctionnalitée manquante ? Un bug ?{' '}
                        <a
                          href="#intercom"
                          title="ouvrir intercom"
                          onClick={() => {
                            IntercomAPI('showNewMessage', '')
                          }}
                        >
                          On est la pour vous aider !
                        </a>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Divider hidden />
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default My_Postcard
