import React, { Component, MouseEvent } from 'react'
import { Accordion, AccordionTitleProps, Button, Container, Divider, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import { IntercomAPI } from 'react-intercom'
import { createClient } from 'contentful'
import orderBy from 'lodash/orderBy'
import marked from './../utils/MarkdownRenderer'

import { Helmet } from 'react-helmet'
import SegmentCustom from '../components/SegmentCustom'
import { Link } from 'react-router-dom'
import Debug from 'debug'
import CallMeButton from '../components/CallMeButton'

const debug = Debug('app:containers:PricingPostcard')

/*
const postage_type_options = [{
  key: 1,
  value: 'prioritaire',
  text: 'Prioritaire'
}, {
  key: 2,
  value: 'lr',
  text: 'Recommandé'
}, {
  key: 3,
  value: 'lrar',
  text: 'Recommandé avec AR'
}] */

interface PricingPostcardState {
  activeIndex: number
  items: any[]
  loading?: boolean
}

class PricingPostcard extends Component<any, PricingPostcardState> {
  constructor(props: any) {
    super(props)
    this.state = {
      activeIndex: 0,
      items: [],
    }
  }

  componentDidMount(): void {
    this.fetchFAQ()
  }

  handleClick = (e: MouseEvent, titleProps: AccordionTitleProps): any => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex as number })
  }

  fetchFAQ = (): any => {
    const preview = true
    this.setState({
      loading: true,
    })

    const client = createClient({
      space: 'uu95v6ljdw3h',
      host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: preview
        ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
        : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
    })

    debug('Fetching Entries....')

    client
      .getEntries({
        content_type: 'faq',
        'fields.category': 'pricing_postcard',
      })
      .then((response: any) => {
        debug('items : ', response.items)
        this.setState({
          items: orderBy(
            response.items.map((item: any) => {
              return item.fields
            }),
            ['order'],
            ['asc']
          ),
          loading: false,
        })
        return true
      })
      .catch((error: any) => {
        return error
      })
  }

  render(): JSX.Element {
    const { activeIndex } = this.state

    return (
      <div>
        <Helmet>
          <title>Tarifs Carte Postale - MySendingBox - Solutions d'envoi de courrier</title>
          <meta name="description" content="Tarifs d'envoi de carte postale depuis l'API RESTful. Envoyer des cartes postales à des tarifs compétitifs." />
          <meta
            property="og:description"
            content="Tarifs d'envoi de carte postale depuis l'API RESTful. Envoyer des cartes postales à des tarifs compétitifs."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%' }}>
          <Divider hidden />

          <Grid columns="equal">
            <Grid.Row centered stretched>
              <Grid.Column computer={10} tablet={14} mobile={16} textAlign="center">
                <SegmentCustom transparent basic>
                  <Button.Group size="medium">
                    <Button as={Link} to="/tarifs/courrier" content="COURRIER" icon="envelope" />
                    <Button color={'violet'} as={Link} to="/tarifs/carte-postale" content="CARTE POSTALE" icon="image" />
                  </Button.Group>
                </SegmentCustom>

                <Header as="h1" size="huge">
                  Prix simples
                  <Header.Subheader>
                    <b> Sans abonnement. Vous ne payez que ce que vous consommez.</b> <br />
                    Pas de frais d'installation. Pas de frais cachés. Sans engagement.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered stretched>
              <Grid.Column computer={12} tablet={16} mobile={16} textAlign="center">
                <Segment attached>
                  <Divider hidden />

                  <Table basic="very" padded="very" unstackable celled collapsing style={{ margin: 'auto' }} size="large">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell textAlign="center">
                          Développeur{' '}
                          <span role="img" aria-label="bolt">
                            ☄️
                          </span>
                          ️
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Startup{' '}
                          <span role="img" aria-label="rocket">
                            🚀
                          </span>
                          ️
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Business{' '}
                          <span role="img" aria-label="stars">
                            ✨️
                          </span>
                          ️
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row textAlign="center">
                        <Table.Cell style={{ height: '180px', width: '200px' }}>
                          <Header as="h4">
                            <Header.Content>Consommation</Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell style={{ height: '180px', width: '200px' }}>
                          <small>de</small> 0 <small>à</small> 100 cartes{' '}
                        </Table.Cell>
                        <Table.Cell style={{ height: '180px', width: '200px' }}>
                          <small>+ de</small> 100 cartes{' '}
                        </Table.Cell>
                        <Table.Cell style={{ height: '180px', width: '200px' }}>
                          <small>+ de</small> 1000 cartes{' '}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row textAlign="center">
                        <Table.Cell>
                          <Header as="h4">
                            <Header.Content>
                              Prix par carte
                              <br />
                              <small>Affranchissement inclus</small>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          2,00 € <small>/ carte</small>
                        </Table.Cell>
                        <Table.Cell>
                          1,80 € <small>/ carte</small>
                        </Table.Cell>
                        <Table.Cell>
                          1,60 € <small>/ carte</small>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered stretched>
              <Grid.Column computer={8} tablet={12} mobile={16} textAlign="center">
                <Header>
                  Vous avez des besoins spécifiques ? <br />
                  Ou des questions sur nos prix ? <br />
                  <a
                    onClick={(e: any) => {
                      if (window.Intercom) {
                        e.preventDefault()
                        IntercomAPI('showNewMessage')
                      }
                    }}
                    href="mailto:hello@MySendingBox.fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactez-nous.
                  </a>
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Divider hidden />
                <Button as={Link} to="/signup/" primary size="huge" content="Commencer" />
                <CallMeButton text="Faites-vous rappeler" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Divider hidden />
                <Header as="h2" size="large" textAlign="center">
                  Foire aux questions
                </Header>
                <Divider hidden />

                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign="left">
                      {!!this.state.items.length && (
                        <Accordion styled fluid>
                          {!!this.state.items.length &&
                            this.state.items.map((item: any, index: any) => {
                              return (
                                <div key={index}>
                                  <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
                                    <Icon name="dropdown" />
                                    {item.title}
                                  </Accordion.Title>
                                  <Accordion.Content active={activeIndex === index}>
                                    <div dangerouslySetInnerHTML={{ __html: marked(item.content) }} />
                                  </Accordion.Content>
                                </div>
                              )
                            })}
                        </Accordion>
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered stretched>
                    <Grid.Column computer={8} tablet={12} mobile={16} textAlign="center">
                      <Header>
                        Vous ne trouvez pas de réponse à vos questions ?{' '}
                        <a
                          onClick={(e: any) => {
                            if (window.Intercom) {
                              e.preventDefault()
                              IntercomAPI('showNewMessage')
                            }
                          }}
                          href="mailto:hello@MySendingBox.fr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Contactez-nous.
                        </a>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default PricingPostcard
