import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header, Image, Message, Segment, Visibility } from 'semantic-ui-react'
import { Link, RouteChildrenProps } from 'react-router-dom'
import Helmet from 'react-helmet'
import Debug from 'debug'
import FilePlayer from 'react-player/lib/players/FilePlayer'
import CallMeButton from '../../components/CallMeButton'
import { connect, ConnectedProps } from 'react-redux'
import { toggleFooter, toggleNav } from '../../actions/action_ui'
import queryString from 'query-string'

const debug = Debug('app:containers:DragAndDropServicesPersonne')

const connector = connect()

type DragAndDropServicesPersonneProps = ConnectedProps<typeof connector> & RouteChildrenProps

interface DragAndDropServicesPersonneState {
  modalOpen: boolean
  gifUrl: string
  playing: boolean
}

class DragAndDropServicesPersonne extends Component<DragAndDropServicesPersonneProps, DragAndDropServicesPersonneState> {
  constructor(props: DragAndDropServicesPersonneProps) {
    super(props)
    this.state = {
      modalOpen: false,
      gifUrl: '',
      playing: false,
    }
  }

  componentDidMount(): void {
    if (!queryString.parse(this.props.location.search).nav) {
      this.props.dispatch(toggleNav(false))
      this.props.dispatch(toggleFooter(false))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(toggleNav(true))
    this.props.dispatch(toggleFooter(true))
  }

  openModal = (data: string) => {
    debug('data : ', data)
    if (data === 'gif-campagne') {
      this.setState({
        gifUrl: 'https://lifebot.s3.amazonaws.com/website/Video-D%C3%A9mo-Drag-and-Drop.mp4',
      })
    }
    this.setState({
      modalOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    })
  }

  handleVideoClick = () => {
    this.setState({
      playing: !this.state.playing,
    })
  }

  startPlaying = () => {
    debug('Visible')
    if (!this.state.playing) {
      debug('Playing..')
      this.setState({
        playing: true,
      })
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Déposez-envoyez - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          <meta
            name="description"
            content="Envoyez vos courriers en quelques clics avec MySendingBox. La solution d'envoi postal la plus souple et moderne. Factures, devis, relances impayées..."
          />

          <meta property="og:title" content="Déposez-envoyez - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
          <meta
            property="og:description"
            content="Envoyez vos courriers en quelques clics avec MySendingBox. La solution d'envoi postal la plus souple et moderne. Factures, devis, relances impayées..."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row centered>
              <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                  Des courriers à transmettre à vos clients ?
                </Header>

                <Header as="h2" textAlign="center" size="medium">
                  Ne perdez plus de temps à imprimer, mettre sous pli et affranchir vos courriers.
                  <br />
                  Externalisez vos envois avec MySendingBox.
                </Header>
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column width="16" textAlign="center">
                <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content="Commencer" />
                <CallMeButton text="Faites-vous rappeler" />

                <Divider hidden />

                <Message compact style={{ padding: '20px' }} positive>
                  <Header size="small">
                    Offre spéciale entreprises SAP.
                    <br />
                    <CallMeButton displayStyle="link" text="Contactez-nous." />
                  </Header>
                </Message>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h3" size="large">
                  3 étapes, 3 clics et c'est tout !
                </Header>

                <Divider hidden section />

                <Grid verticalAlign="middle" textAlign="left" centered>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/hand_gestures-SkxKZ0twM_x0wg18.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        1. Déposez vos documents
                      </Header>
                      <p>
                        Factures, devis, contrats, relances... Déposez tous vos courriers. Les coordonnées et adresses des destinataires sont lues
                        automatiquement.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/level-rk9ab0YDz_apmu50.png"
                        centered
                        style={{ display: 'block', width: '60%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        2. Choisissez vos options
                      </Header>
                      <p>
                        Couleurs, type d'affranchissement, recto/verso, vitesse de remise en poste...
                        <br />
                        Affranchissements disponibles : ecopli, prioritaire, recommandé avec AR.
                        <br />
                        Choissez une règle de transformation.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/send-ByJJG0twM_ioyntl.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        3. Envoyez vos courriers
                      </Header>
                      <p>Visualisez vos courriers et suivez leur acheminement de l'impression à la réception, en ligne.</p>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                      <Divider hidden />
                      <Header size="tiny">
                        Tout se fait depuis votre navigateur web.
                        <br />
                        Vous n'avez rien à installer !
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content="Commencer" />
                      <CallMeButton text="Faites-vous rappeler" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />

                <Divider section />

                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h3" size="large">
                  50 secondes pour envoyer vos courriers
                </Header>
                <Divider hidden />

                <Segment onClick={this.handleVideoClick}>
                  <Visibility onTopVisible={this.startPlaying}>
                    <FilePlayer
                      muted
                      playing={this.state.playing}
                      loop
                      controls
                      width="100%"
                      height="100%"
                      style={{ width: '100%', height: '400px' }}
                      url={`https://res.cloudinary.com/lifebot/video/upload/ac_none/v1519300434/Demo_video_envois_groupees_2.mp4`}
                    />
                  </Visibility>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider />
                <Divider hidden />

                <Header as="h2">Envoyez vos courriers en quelques clics</Header>

                <Header size="small">
                  Sans engagement et à partir de 0,85€/courrier&nbsp;
                  <small>(affranchissement compris)</small>.
                </Header>
                <Divider hidden />
                <Divider hidden />

                <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content="Envoyez du courrier" />

                <CallMeButton text="Faites-vous rappeler" />

                <Divider section hidden />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default connector(DragAndDropServicesPersonne)
