import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header, Image, Segment } from 'semantic-ui-react'
import { Link, RouteChildrenProps } from 'react-router-dom'
import Helmet from 'react-helmet'
import Debug from 'debug'
import FilePlayer from 'react-player/lib/players/FilePlayer'
import CallMeButton from '../../components/CallMeButton'
import { connect, ConnectedProps } from 'react-redux'
import { toggleFooter, toggleNav } from '../../actions/action_ui'
import queryString from 'query-string'

const debug = Debug('app:containers:Campagnes')

const connector = connect()

type CampagnesProps = ConnectedProps<typeof connector> & RouteChildrenProps

interface CampagnesState {
  modalOpen: boolean
  gifUrl: string
  playing: boolean
}

class Campagnes extends Component<CampagnesProps, CampagnesState> {
  constructor(props: CampagnesProps) {
    super(props)
    this.state = {
      modalOpen: false,
      gifUrl: '',
      playing: false,
    }
  }

  componentDidMount(): void {
    if (!queryString.parse(this.props.location.search).nav) {
      this.props.dispatch(toggleNav(false))
      this.props.dispatch(toggleFooter(false))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(toggleNav(true))
    this.props.dispatch(toggleFooter(true))
  }

  openModal = (data: string) => {
    debug('data : ', data)
    if (data === 'gif-campagne') {
      this.setState({
        gifUrl: 'https://lifebot.s3.amazonaws.com/website/Video-D%C3%A9mo-Drag-and-Drop.mp4',
      })
    }
    this.setState({
      modalOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    })
  }

  handleVideoClick = () => {
    this.setState({
      playing: !this.state.playing,
    })
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Publipostage - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          <meta
            name="description"
            content="Créez une campagne de courriers ou de cartes postales personnalisées en quelques clics. Ajoutez vos destinataires, choisissez une design. Reçu sous 5 jours."
          />

          <meta property="og:title" content="Publipostage - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
          <meta
            property="og:description"
            content="Créez une campagne de courriers ou de cartes postales personnalisées en quelques clics. Ajoutez vos destinataires, choisissez une design. Reçu sous 5 jours."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row centered>
              <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                  Le Mailchimp de l'envoi postal
                </Header>

                <Header as="h2" textAlign="center" size="medium">
                  Créez un <b>publipostage</b> ultra personnalisée de courriers ou cartes postales en quelques clics.
                  <br />
                  Surprenez vos clients avec un support original et <b>personnalisé</b>.
                </Header>
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column width="16" textAlign="center">
                <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content="Créer un mailing postal" />
                <CallMeButton text="Faites-vous rappeler" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h3" size="large">
                  Un mailing postal en seulement 3 étapes
                </Header>

                <Divider hidden section />

                <Grid verticalAlign="middle" textAlign="left" centered>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/campagne_1.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        1. Ajoutez vos destinataires
                      </Header>
                      <p>
                        Glissez votre fichier Excel de destinataires
                        <br />
                        Ajoutez des champs personnalisés pour rendre unique chaque carte ou courrier.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/campagne_2.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        2. Créez votre design unique
                      </Header>
                      <p>Images, polices, textes... Personnalisez vos supports de A à Z.</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/campagne_3.png"
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        3. Lancez votre campagne
                      </Header>
                      <p>Suivez l'acheminement de l'impression à la réception dans les boites aux lettres de vos clients.</p>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Divider hidden />

                      <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content="Commencer" />
                      <CallMeButton text="Faites-vous rappeler" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />

                <Divider section />

                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h3" size="large">
                  1 minute pour créer un publipostage courrier
                </Header>
                <Divider hidden />

                <Segment onClick={this.handleVideoClick}>
                  <FilePlayer
                    muted
                    playing={this.state.playing}
                    loop
                    controls
                    width="100%"
                    height="100%"
                    style={{ width: '100%', height: '400px' }}
                    url={`https://res.cloudinary.com/lifebot/video/upload/ac_none/v1519300094/Video_demo_campagne.mp4`}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider />
                <Divider hidden />

                <Header as="h2">Créer un mailing de cartes postales ou de courriers en quelques clics</Header>

                <Header size="small">Sans engagement et à partir de 0,85€/courrier ou 1,35€/carte postale.</Header>
                <Divider hidden />
                <Divider hidden />

                <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content="Créer une campagne" />

                <CallMeButton text="Faites-vous rappeler" />

                <Divider section hidden />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default connector(Campagnes)
