import { Action, AnyAction } from 'redux'

export const WORKFLOW_INITIALIZE = 'WORKFLOW_INITIALIZE'
export const WORKFLOW_GOTO = 'WORKFLOW_GOTO'
export const WORKFLOW_GOTO_NEXT = 'WORKFLOW_GOTO_NEXT'
export const WORKFLOW_GOTO_PREVIOUS = 'WORKFLOW_GOTO'
export const WORKFLOW_DISABLE_STEP_CHANGE = 'WORKFLOW_DISABLE_STEP_CHANGE'
export const WORKFLOW_ENABLE_STEP_CHANGE = 'WORKFLOW_ENABLE_STEP_CHANGE'
export const WORKFLOW_DISABLE_NEXT_STEP_CHANGE = 'WORKFLOW_DISABLE_NEXT_STEP_CHANGE'
export const WORKFLOW_ENABLE_NEXT_STEP_CHANGE = 'WORKFLOW_ENABLE_NEXT_STEP_CHANGE'
export const WORKFLOW_DISABLE_LOADER = 'WORKFLOW_DISABLE_LOADER'
export const WORKFLOW_ENABLE_LOADER = 'WORKFLOW_ENABLE_LOADER'
export const WORKFLOW_DISABLE_IS_NAVIGATION_BLOCKED = 'WORKFLOW_DISABLE_IS_NAVIGATION_BLOCKED'
export const WORKFLOW_ENABLE_IS_NAVIGATION_BLOCKED = 'WORKFLOW_ENABLE_IS_NAVIGATION_BLOCKED'

export function initializeWorkflow(steps: any[], form_id: string): AnyAction {
  return {
    type: WORKFLOW_INITIALIZE,
    steps: steps,
    form_id: form_id,
  }
}

export function workflowGoTo(step: any | 'next' | 'previous'): AnyAction {
  if (step === 'next') {
    return { type: WORKFLOW_GOTO_NEXT }
  } else if (step === 'previous') {
    return { type: WORKFLOW_GOTO_PREVIOUS }
  } else {
    return {
      type: WORKFLOW_GOTO,
      step: step,
    }
  }
}

export function workflowEnableStepChange(): Action<string> {
  return {
    type: WORKFLOW_ENABLE_STEP_CHANGE,
  }
}

export function workflowDisableStepChange(): Action<string> {
  return {
    type: WORKFLOW_DISABLE_STEP_CHANGE,
  }
}

export function workflowEnableNextStepChange(): Action<string> {
  return {
    type: WORKFLOW_ENABLE_NEXT_STEP_CHANGE,
  }
}

export function workflowDisableNextStepChange(): Action<string> {
  return {
    type: WORKFLOW_DISABLE_NEXT_STEP_CHANGE,
  }
}

export function workflowDisableLoader(): Action<string> {
  return {
    type: WORKFLOW_DISABLE_LOADER,
  }
}

export function workflowEnableLoader(): Action<string> {
  return {
    type: WORKFLOW_ENABLE_LOADER,
  }
}

export function workflowDisableIsNavigationBlocked(): Action<string> {
  return {
    type: WORKFLOW_DISABLE_IS_NAVIGATION_BLOCKED,
  }
}

export function workflowEnableIsNavigationBlocked(): Action<string> {
  return {
    type: WORKFLOW_ENABLE_IS_NAVIGATION_BLOCKED,
  }
}
