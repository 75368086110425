import React, { FC } from 'react'

import { Button, SemanticSIZES, SemanticCOLORS } from 'semantic-ui-react'
import { IntercomAPI } from 'react-intercom'

interface ContactUsButtonProps {
  [x: string]: any
  displayAs: 'button' | 'link'
  size?: SemanticSIZES
  color?: SemanticCOLORS
  intercomText?: string
  buttonText?: string
  className?: string
}
const ContactUsButton: FC<ContactUsButtonProps> = ({
  displayAs,
  size = 'medium',
  color = 'blue',
  intercomText,
  buttonText,
  className,
  ...other
}: ContactUsButtonProps): JSX.Element => (
  <>
    {displayAs === 'button' ? (
      <Button
        color={color}
        fluid
        size={size}
        className={className}
        onClick={(e: any) => {
          if (window.Intercom) {
            e.preventDefault()
            IntercomAPI('showNewMessage', intercomText)
          }
        }}
        as="a"
        href={`mailto:hello@MySendingBox.fr?subject=${buttonText}&body=${intercomText}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonText || "Besoin d'aide ?"}
      </Button>
    ) : (
      ''
    )}
    {displayAs === 'link' ? (
      <a
        onClick={(e: any) => {
          if (window.Intercom) {
            e.preventDefault()
            IntercomAPI('showNewMessage', intercomText)
          }
        }}
        className={className}
        href={`mailto:hello@MySendingBox.fr?subject=${"Demande d'informations"}&body=${intercomText}`}
        target="_blank"
        rel="noopener noreferrer"
        {...other}
      >
        {buttonText || "Besoin d'aide ?"}
      </a>
    ) : (
      ''
    )}
  </>
)

export default ContactUsButton
