import React, { Component } from 'react'
import { Icon, Label } from 'semantic-ui-react'
import axios from 'axios/index'
import Debug from 'debug'

const debug = Debug('app:containers:Footer')

interface LabelStatusState {
  status: number
  uptime_ratio: number
}
class LabelStatus extends Component<any, LabelStatusState> {
  constructor(props: any) {
    super(props)
    this.state = {
      status: 1,
      uptime_ratio: 0,
    }
  }
  async componentDidMount(): Promise<void> {
    try {
      const response = await axios({
        url: `https://api.uptimerobot.com/v2/getMonitors`,
        method: 'POST',
        data: {
          api_key: 'm780086012-1d7f95eee22c86f812f3b466',
          monitors: '780086012',
          custom_uptime_ratios: '45',
        },
      })
      if (response.data.stat !== 'ok') {
        throw new Error('Fail to check uptimerobot api')
      }
      debug('Uptime data : ', response.data)

      this.setState({
        status: response.data.monitors[0].status,
        uptime_ratio: response.data.monitors[0].custom_uptime_ratio,
      })
    } catch (ex) {
      debug('ex when checking uptimerobot :', ex)
    }
  }

  render(): JSX.Element {
    const { status } = this.state
    return (
      <Label
        basic
        className="background-transparent"
        color={status === 2 ? 'blue' : status === 1 ? 'grey' : 'orange'}
        size="small"
        as=""
        rel="noopener noreferrer"
        href="https://status.mysendingbox.fr/"
        target="_blank"
      >
        État des services :<Label.Detail>{status === 2 ? 'OK' : status === 1 ? <Icon name="spinner" loading /> : 'Error'}</Label.Detail>
      </Label>
    )
  }
}

export default LabelStatus
