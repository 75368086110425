import get from 'lodash/get'
import words from 'lodash/words'
import ceil from 'lodash/ceil'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import difference from 'lodash/difference'
import toLower from 'lodash/toLower'
import { isValidCountry, country_france, getCountryAlpha2FromName } from './Country'
import Debug from 'debug'
import numeral from 'numeral'
import sumBy from 'lodash/sumBy'
import stringSimilarity from 'string-similarity'
import { ZoneInterface } from '../components/PreviewPdf/interfaces/ZonesInterface'
import { EMAIL_REGEX } from './Form'
import { CampaignTypes } from '../typescript/interfaces/common'
import Phone from 'awesome-phonenumber'

const debug = Debug('app:AppUtils')

numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number: any) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€',
  },
})
numeral.locale('fr')

export function handleMissingData(data: any, type: CampaignTypes): { default?: string; paper?: string; electronic?: string } {
  const channel = type !== 'sms' ? data.channel : undefined
  const error: { default?: string; paper?: string; electronic?: string } = {}
  if (channel === 'multiple' || channel === 'paper') {
    error.paper = getMissingPaperData(data)
  }
  if (channel === 'multiple' || channel === 'electronic') {
    error.electronic = getMissingElectronicData(data)
  }
  if (type === 'sms') {
    error.default = getMissingSMSData(data)
  }
  return error
}

export function getMissingPaperData(data: any): string | undefined {
  // debug('get_missing_data : ', data)

  let max_address_length = 38

  if (data.postage_speed === 'D' || data.postage_speed === 'D1') {
    max_address_length = 45
  } else if (data.type === 'postcard') {
    max_address_length = 80
  } else {
    max_address_length = 38
  }

  if (!get(data, 'to.company')?.replaceAll(/\s/g, '') && !get(data, 'to.name')?.replaceAll(/\s/g, '')) {
    return 'to.company or to.name is required.'
  }
  if (!get(data, 'to.address_line1')?.replaceAll(/\s/g, '')) {
    return 'to.address_line1 is required.'
  }
  if (!get(data, 'to.address_city')?.replaceAll(/\s/g, '')) {
    return 'to.address_city is required.'
  }
  if (!get(data, 'to.address_postalcode')?.replaceAll(/\s/g, '')) {
    return 'to.address_postalcode is required.'
  }
  if (!get(data, 'to.address_country')?.replaceAll(/\s/g, '')) {
    return 'to.address_country is required.'
  }

  if (get(data, 'to.company', '').length > max_address_length) {
    return `to.company is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'to.name', '').length > max_address_length) {
    return `to.name is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'to.address_line1', '').length > max_address_length) {
    return `to.address_line1 is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'to.address_line2', '').length > max_address_length) {
    return `to.address_line2 is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'to.address_line3', '').length > max_address_length) {
    return `to.address_line3 is too long (max ${max_address_length} characters).`
  }
  if ((get(data, 'to.address_postalcode', '') + ' ' + get(data, 'to.address_city', '')).length > max_address_length) {
    return `to.address_city is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'from.company', '').length > max_address_length) {
    return `from.company is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'from.name', '').length > max_address_length) {
    return `from.name is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'from.address_line1', '').length > max_address_length) {
    return `from.address_line1 is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'from.address_line2', '').length > max_address_length) {
    return `from.address_line2 is too long (max ${max_address_length} characters).`
  }
  if (get(data, 'from.address_line3', '').length > max_address_length) {
    return `from.address_line3 is too long (max ${max_address_length} characters).`
  }
  if ((get(data, 'from.address_postalcode', '') + ' ' + get(data, 'from.address_city', '')).length > max_address_length) {
    return `from.address_city is too long (max ${max_address_length} characters).`
  }
  /*
  if (get(data, 'postage_type') === 'lr' || get(data, 'postage_type') === 'lrar') {
    if (!get(data, 'from.company')?.replaceAll(/\s/g, '') && !get(data, 'from.name')?.replaceAll(/\s/g, '')) {
      return 'if postage_type is lr or lrar, from.company or from.name is required.'
    }
    if (!get(data, 'from.address_line1')?.replaceAll(/\s/g, '')) {
      return 'if postage_type is lr or lrar, from.address_line1 is required.'
    }
    if (!get(data, 'from.address_city')?.replaceAll(/\s/g, '')) {
      return 'if postage_type is lr or lrar, from.address_city is required.'
    }
    if (!get(data, 'from.address_postalcode')?.replaceAll(/\s/g, '')) {
      return 'if postage_type is lr or lrar, from.address_postalcode is required.'
    }
    if (!get(data, 'from.address_country')?.replaceAll(/\s/g, '')) {
      return 'if postage_type is lr or lrar, from.address_country is required.'
    }
  }
*/
  if (get(data, 'from.address_country') && !isValidCountry(get(data, 'from.address_country'))) {
    return 'from.address_country is not in the ISO 3166 format. Check the list of supported country here : https://docs.MySendingBox.fr/#country.'
  }

  if (!isValidCountry(get(data, 'to.address_country'))) {
    return 'to.address_country is not in the ISO 3166 format. Check the list of supported country here : https://docs.MySendingBox.fr/#country.'
  } else {
    // debug('Country found.')
  }

  if (country_france.includes(toLower(get(data, 'to.address_country'))) && toLower(get(data, 'to.address_country')) !== 'andorre') {
    if (get(data, 'to.address_postalcode').length !== 5 || isNaN(get(data, 'to.address_postalcode'))) {
      return `to.address_postalcode is not a valid postalcode. Must be 5 numbers.`
    }
  }

  if (!country_france.includes(toLower(data.to.address_country)) && ['lr', 'lrar'].includes(data.postage_type) && data.postage_speed === 'express') {
    return `You can't send letter with postage_type = lr or lrar to foreign country with postage_speed = 'express'.`
  }
  /*
  if (get(data, 'print_sender_address') === true) {
    if (!get(data, 'from.company')?.replaceAll(/\s/g, '') && !get(data, 'from.name')?.replaceAll(/\s/g, '')) {
      return 'if print_sender_address is set to true, from.company or from.name is required. (if envelope is c4 then print_sender_address is forced to true).'
    }
    if (!get(data, 'from.address_line1')?.replaceAll(/\s/g, '')) {
      return 'if print_sender_address is set to true, from.address_line1 is required. (if envelope is c4 then print_sender_address is forced to true).'
    }
    if (!get(data, 'from.address_city')?.replaceAll(/\s/g, '')) {
      return 'if print_sender_address is set to true, from.address_city is required. (if envelope is c4 then print_sender_address is forced to true).'
    }
    if (!get(data, 'from.address_postalcode')?.replaceAll(/\s/g, '')) {
      return 'if print_sender_address is set to true, from.address_postalcode is required. (if envelope is c4 then print_sender_address is forced to true).'
    }
    if (!get(data, 'from.address_country')?.replaceAll(/\s/g, '')) {
      return 'if print_sender_address is set to true, from.address_country is required. (if envelope is c4 then print_sender_address is forced to true).'
    }
  }
  */
  const page_count = sumBy(data.files, 'page_count')
  let sheet_count
  if (data.both_sides === true && page_count > 1) {
    sheet_count = ceil(page_count / 2)
  } else {
    sheet_count = page_count
  }
  if (get(data, 'postage_speed') === 'express' && sheet_count > 30) {
    return `You can't send more than 30 pages (Or 60 pages with both_sides = true) when 'postage_speed' = 'express' (You try to send ${page_count} pages). Contact-us if you have special needs. We will be happy to help !`
  }

  // Neotouch limits seems to be 299 sheets
  if ((get(data, 'postage_speed') === 'D' || get(data, 'postage_speed') === 'D1') && sheet_count > 299) {
    return `You can't send more than 299 pages (Or 598 pages with both_sides = true) (You try to send ${page_count} pages). Contact-us if you have special needs. We will be happy to help !`
  }
}

export function getMissingElectronicData(data: any): string | undefined {
  // Check email
  if (!get(data, 'to.email')?.replaceAll(/\s/g, '')) {
    return 'to.email is required.'
  } else if (!EMAIL_REGEX.test(get(data, 'to.email'))) {
    return 'to.email is invalid (incorrect email format).'
  }
  // Check last name, first_name and company
  if (
    !get(data, 'to.last_name')?.replaceAll(/\s/g, '') &&
    !get(data, 'to.first_name')?.replaceAll(/\s/g, '') &&
    !get(data, 'to.company')?.replaceAll(/\s/g, '')
  ) {
    return 'to.company or to.first_name and to.last_name is required.'
  }
  // Check last name, first_name
  if (
    (!get(data, 'to.last_name')?.replaceAll(/\s/g, '') || !get(data, 'to.first_name')?.replaceAll(/\s/g, '')) &&
    !get(data, 'to.company')?.replaceAll(/\s/g, '')
  ) {
    return 'to.first_name and to.last_name is required.'
  }
}
export function getMissingSMSData(data: any): string | undefined {
  // Check country
  const address_country = get(data, 'to.address_country')
  if (address_country.toUpperCase() !== 'FR' && address_country.toUpperCase() !== 'FRANCE') {
    return 'Phone number must be in metropolitan France format'
  }

  // Check phone
  if (!get(data, 'to.phone')) {
    return 'to.phone is required.'
  } else {
    const parsedPhone = new Phone(get(data, 'to.phone'), getCountryAlpha2FromName(address_country))
    if (!parsedPhone.isValid() || !parsedPhone.isMobile()) {
      return !parsedPhone.isValid() ? 'Invalid phone number' : 'Phone number must be a mobile one'
    }
  }
}

export function getFormatedPrice(price: number, multiplier: number = 1, format: any = '0,0[.]00 $'): string {
  return numeral(price * multiplier)
    .format(format)
    .replace(' ', '\u00a0')
  // replace spaces with non breakable spaces so that the € symbol is not on an other line
}

export function getInternalMonthlyFormatedPrice(price: number, multiplier: number = 1, monthly_fixed_cost: number = 0, format: any = '0,0[.]00 $'): string {
  let res = price * multiplier
  res += monthly_fixed_cost
  return numeral(res).format(format)
  // Olivier Gautier : pourquoi ca marche pas ci dessous, ni avec numeral(numeral(...))
  //  return numeral(getFormatedPrice(price, multiplier, format) + monthly_fixed_cost).format(format)
}

const wordsToRemove = ['objet', 'de', 'contrat', 'chez', 'concerne', 'madame', 'monsieur', 'mme', 'mr', 'm', 'mlle']

/*
export const cleanTextForAssociation = (text) => {
  return words(text).join(' ').replace(/[0-9]/g, '').replace(new RegExp(`^(${wordsToRemove.join('|')})`, 'ig'), '').trim()
}
*/

export function cleanTextForAssociation(text: string): string {
  // debug('cleanTextForAssociation :', text)
  /* let word = words(text.toLowerCase())
  if (word[0] && wordsToRemove.includes(word[0].toLowerCase())) {
    word = drop(word)
  } */
  if (!text) {
    return ''
  }
  const text_array = difference(words(text.toLowerCase()), wordsToRemove)

  return text_array.join(' ').trim()
}

/**
 * This function group elements of an array by a filter variable for a minimum rating value
 * @param array
 * @param filter_variable
 * @param minimum_rating
 * @return {Array|*}
 */
export function groupBySimilarity(array: any[], filter_variable: string, minimum_rating: number): any {
  const grouped = array.map((item: any) => {
    // debug(item.address_string)
    const results = stringSimilarity.findBestMatch(
      item[filter_variable],
      array.map((value: any) => value[filter_variable])
    )
    // debug('results : ', results)
    const results_filtered = filter(results.ratings, (o: any) => o.rating >= (minimum_rating || 0.9))
    // debug('results_filtered : ', results_filtered)
    const results_find = results_filtered.map((value: any) => {
      // debug('value : ', value)
      const findBy: any = {}
      findBy[filter_variable] = value.target
      return { ...array[value.index], rating: value.rating }
    })
    // debug('results_find : ', results_find)
    // debug('')
    // debug('')

    return {
      address_string: results_find[0].address_string,
      items: results_find,
    }
  })

  // debug('grouped: %O', grouped)

  const withoutDuplicates = uniqBy(grouped, filter_variable)
  // debug('withoutDuplicates : ', withoutDuplicates)
  return withoutDuplicates
}

export function removeEmptyKey(obj: any): any {
  Object.keys(obj).forEach((key: any) => key === '' && delete obj[key])
  return obj
}

export function addSizeDocumentToZone(zone: ZoneInterface, orientation: string = 'portrait'): ZoneInterface {
  debug('addSizeDocumentToZone: ', zone, '/ orientation : ', orientation)
  if (orientation === 'portrait') {
    return {
      x: (zone.x * 595) / 100,
      y: (zone.y * 842) / 100,
      width: (zone.width * 595) / 100,
      height: (zone.height * 842) / 100,
    }
  } else if (orientation === 'landscape') {
    return {
      x: (zone.x * 842) / 100,
      y: (zone.y * 595) / 100,
      width: (zone.width * 842) / 100,
      height: (zone.height * 595) / 100,
    }
  } else {
    throw new Error('Unsupported orientation.')
  }
}

export function removeSizeDocumentToZone(zone: ZoneInterface, orientation: string = 'portrait'): ZoneInterface {
  debug('removeSizeDocumentToZone: ', zone, '/ orientation : ', orientation)
  if (orientation === 'portrait') {
    return {
      x: (zone.x / 595) * 100,
      y: (zone.y / 842) * 100,
      width: (zone.width / 595) * 100,
      height: (zone.height / 842) * 100,
    }
  } else if (orientation === 'landscape') {
    return {
      x: (zone.x / 842) * 100,
      y: (zone.y / 595) * 100,
      width: (zone.width / 842) * 100,
      height: (zone.height / 595) * 100,
    }
  } else {
    throw new Error('Unsupported orientation.')
  }
}
