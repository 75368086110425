import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_FAILURE, LOGOUT_SUCCESS, RESET_SUCCESS } from './../actions/action_auth'

import { getProfile } from './../utils/AuthService'
import { checkTokenExpiry } from '../utils/jwtHelper'

import get from 'lodash/get'
import { AnyAction } from 'redux'
// import Debug from 'debug'

// const debug = Debug('app:reducer:auth')

export interface AuthStateType {
  isAuthenticated: boolean
  error: false | string | null
  profile: any | null
  loading: boolean | null
}

const INITIAL_STATE: AuthStateType = {
  isAuthenticated: checkTokenExpiry(),
  error: false,
  profile: getProfile(),
  loading: null,
}

export default function (state: AuthStateType = INITIAL_STATE, action: AnyAction): AuthStateType {
  switch (action.type) {
    case LOGIN:
      return { ...state, error: null, loading: true, isAuthenticated: false }
    case LOGIN_SUCCESS:
      return { ...state, error: null, loading: false, isAuthenticated: true, profile: action.profile }
    case LOGIN_FAILURE:
      return {
        ...state,
        error: get(action, 'error.response.data.error.message') || get(action, 'error.errorDescription') || get(action, 'error.message') || 'Error',
        loading: false,
        isAuthenticated: false,
        profile: null,
      }
    case LOGOUT_SUCCESS:
      return { ...state, error: null, loading: false, isAuthenticated: false, profile: null }
    case LOGOUT_FAILURE:
      return { ...state, error: action.error, loading: false }

    case RESET_SUCCESS:
      return { ...state, error: null, loading: false, isAuthenticated: false, profile: null }
    default:
      return state
  }
}
