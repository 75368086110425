import jwtDecode from 'jwt-decode'
import { getToken } from './AuthService'
// import Debug from 'debug'

// const debug = Debug('app:utils:jwtHelper')

export function checkTokenExpiry(): boolean {
  try {
    const jwt = getToken()
    if (jwt) {
      const jwtExp = jwtDecode<any>(jwt).exp
      const expiryDate = new Date(0)
      expiryDate.setUTCSeconds(jwtExp)

      if (new Date() < expiryDate) {
        return true
      }
    }
    return false
  } catch (err) {
    return false
  }
}
