import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import { connect, ConnectedProps } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { fetchLogout } from '../actions/action_auth'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import get from 'lodash/get'
import { App } from '../utils/CodeSplitting'
import { RootReducerType } from '../reducers'
import { Action } from 'reduce-reducers'

type LoginZoneProps = PropsFromRedux &
  RouteComponentProps & {
    dispatch: ThunkDispatch<RootReducerType, void, Action>
  }
class LoginZone extends Component<LoginZoneProps> {
  logout = () => {
    this.props.dispatch(fetchLogout())
  }

  onMouseOver = () => {
    App.preload()
  }

  renderLoginZone = () => {
    const { auth, pathname } = this.props
    const { user }: any = this.props
    // eslint-disable-next-line no-console
    console.log('user props______________________', user)
    if (auth.isAuthenticated) {
      if (!user.data) {
        return (
          <Button loading onClick={() => this.props.dispatch(fetchLogout())}>
            Loading
          </Button>
        )
      }
      if (pathname.indexOf('/app') === -1) {
        return (
          <div>
            <span className="mobile hidden">
              <Button primary to="/app/dashboard/" as={Link} onMouseOver={this.onMouseOver}>
                Tableau de bord
              </Button>
            </span>
            <span className="mobile only">
              <Button basic icon="home" to="/app/dashboard/" as={Link} onMouseOver={this.onMouseOver} />
            </span>
          </div>
        )
      }
      return (
        <div>
          <span className="mobile hidden">
            <Button as={Link} to="/app/dashboard/settings" onMouseOver={this.onMouseOver}>
              {user.data.name || user.data.email} - {user.data.role === 'admin' && user.data.admin === true ? 'Super Admin' : ''}
            </Button>
          </span>
          <span className="mobile only">
            <Button as={Link} to="/app/dashboard/settings" icon="user" onMouseOver={this.onMouseOver} />
          </span>

          <span className="mobile hidden">
            <Button color="red" onClick={this.logout}>
              Déconnexion
            </Button>
          </span>
          <span className="mobile only">
            <Button color="red" icon="log out" onClick={this.logout} onMouseOver={this.onMouseOver} />
          </span>
        </div>
      )
    } else {
      return (
        <div>
          <Button color="orange" size="large" as={Link} to="/signup" onMouseOver={this.onMouseOver}>
            Inscription
          </Button>
          <Button className="mobile hidden" size="large" as={Link} to="/login" onMouseOver={this.onMouseOver}>
            Connexion
          </Button>
        </div>
      )
    }
  }

  render() {
    return <div>{this.renderLoginZone()}</div>
  }
}

const mapStateToProps = (state: RootReducerType) => ({
  user: state.user,
  auth: state.auth,
  pathname: get(state, 'router.location.pathname'),
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default withRouter(connector(LoginZone))
