import React, { Component } from 'react'
import { Card, Checkbox, Container, Divider, Grid, Header, Icon, Image, Loader, Segment } from 'semantic-ui-react'
import { createClient } from 'contentful'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { formatDate } from '../../utils/dateUtils'
import { Link } from 'react-router-dom'
import { isSuperAdmin } from '../../utils/AuthService'
import { connect, ConnectedProps } from 'react-redux'

import Debug from 'debug'
import { RootReducerType } from '../../reducers'

const debug = Debug('app:containers:blog:Index')

const connector = connect((state: RootReducerType) => ({
  auth: state.auth,
}))

type BlogIndexProps = ConnectedProps<typeof connector>

interface BlogIndexState {
  blogPosts: any | null
  preview: boolean
}

class BlogIndex extends Component<BlogIndexProps, BlogIndexState> {
  constructor(props: BlogIndexProps) {
    super(props)
    this.state = {
      blogPosts: null,
      preview: false,
    }

    debug('window.snapStore :', window.snapStore)

    const response =
      window.snapStore && window.snapStore[`https://cdn.contentful.com/spaces/rwy17oe5lw1b/environments/master/entries?content_type=2wKn6yEnZewu2SCCkus4as`]

    if (response && response.items && response.items.length) {
      this.setState({
        blogPosts: orderBy(
          response.items.map((item: any) => {
            return item.fields
          }),
          ['date'],
          ['desc']
        ),
      })
    }
  }

  componentDidMount(): void {
    if (!this.state.blogPosts) {
      this.fetchPosts()
    }
  }

  fetchPosts = () => {
    const client = createClient({
      space: 'oim6ty3xru2b',
      host: this.state.preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: this.state.preview ? '0Iy_0zYONpseuKcBMBaZrnVNHD52m3BFzO_7FeGvtAc' : 'TJDUyTV3GMnDR5ADPVkHH7eyUtIJ511Q5IOkh4DYLgI',
    })

    debug('Fetching Entries....')

    client
      .getEntries({
        content_type: '2wKn6yEnZewu2SCCkus4as',
      })
      .then((response: any) => {
        debug(response.items)
        return this.setState({
          blogPosts: orderBy<any>(
            response.items.map((item: any) => {
              return item.fields
            }),
            ['date'],
            ['desc']
          ),
        })
      })
      .catch((error: any) => {
        return error
      })
  }

  render() {
    const { blogPosts, preview } = this.state
    const { auth } = this.props

    return (
      <div>
        <Helmet>
          <title>Blog - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          <meta
            name="description"
            content="Blog de l'API RESTful moderne et souple de MySendingBox. Envoyer des lettres vertes, prioritaires ou encore des recommandés."
          />

          <meta property="og:title" content="Blog - MySendingBox - Solutions d'envoi de courrier et de carte postale" />

          <meta
            property="og:description"
            content="Blog de l'API RESTful moderne et souple de MySendingBox. Envoyer des lettres vertes, prioritaires ou encore des recommandés."
          />
        </Helmet>

        <Container
          fluid={false}
          style={{
            flex: 1,
            minHeight: '400px',
            /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%',
          }}
        >
          <Divider hidden />

          <Grid columns="equal">
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={16} textAlign="center">
                {isSuperAdmin(auth) && (
                  <Checkbox
                    label="Preview"
                    toggle
                    checked={preview}
                    onClick={() => {
                      this.setState(
                        {
                          preview: !this.state.preview,
                        },
                        () => {
                          this.fetchPosts()
                        }
                      )
                    }}
                    style={{ float: 'right' }}
                  />
                )}

                <Header as="h1" style={{ fontSize: '3em' }}>
                  Blog
                  <Header.Subheader>Actualités et annonces.</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column width="10" textAlign="center">
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {!blogPosts ? (
                <Grid.Column width="16" stretched>
                  <Segment basic style={{ minHeight: '330px' }} className="center-text">
                    <Loader active>Chargement en cours...</Loader>
                  </Segment>
                </Grid.Column>
              ) : (
                blogPosts.map((item: any) => (
                  <Grid.Column key={item.slug} computer="4" tablet="8" mobile="16" style={{ marginBottom: '15px' }} stretched>
                    <Card fluid as={Link} to={`/blog/${item.slug}`} link>
                      <Image src={`https://res.cloudinary.com/lifebot/image/upload/q_auto,c_thumb,g_center,h_387,w_736/${get(item, 'image')}`} />
                      <Card.Content>
                        <Card.Header>{item.title}</Card.Header>
                        <Card.Meta>
                          <span className="date">{formatDate(item.date, 'dd MMMM yyyy')}</span>
                        </Card.Meta>
                        <Card.Description>{item.description}</Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <Icon name="user" />
                        Par {get(item, 'author[0].fields.name', 'Raphaël')}
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                ))
              )}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default connector(BlogIndex)
