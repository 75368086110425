import React, { useState, FC } from 'react'
import { Button, Container, Divider, Grid, Header, Image } from 'semantic-ui-react'
import Embed from 'react-runkit'

import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import { IntercomAPI } from 'react-intercom'
import CallMeButton from '../../components/CallMeButton'
import Script from 'react-load-script'
import { LoadingComponent } from '../../components/Loading'
import LazyLoad from 'react-lazyload'

const API_Courrier: FC = (): JSX.Element => {
  const [runkitLoaded, setRunkitLoaded] = useState(false)

  const handleScriptLoad = () => {
    setRunkitLoaded(true)
  }

  return (
    <div>
      <Helmet>
        <title>API Courrier - MySendingBox - Solutions d'envoi de courrier</title>
        <meta
          name="description"
          content="Une API moderne pour envoyer du courrier postal depuis vos applications et site web. Envoyez des courriers simples ou recommandés directement depuis vos outils avec l'API courrier."
        />

        <meta property="og:title" content="API Courrier - MySendingBox - Solutions d'envoi de courrier" />
        <meta
          property="og:description"
          content="Une API moderne pour envoyer du courrier postal depuis vos applications et site web. Envoyez des courriers simples ou recommandés directement depuis vos outils avec l'API courrier."
        />
      </Helmet>

      <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
        <Divider section hidden />

        <Grid columns="equal">
          <Grid.Row centered>
            <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
              <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                Une API moderne pour envoyer du courrier postal
              </Header>

              <Header as="h2" textAlign="center" size="medium">
                Intégrez très facilement l'envoi de courriers dans vos process.
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column width="16" textAlign="center">
              <Button as={Link} to="/signup/" size="big" primary content="Récupérez votre clé API Courrier" />

              <CallMeButton text="Faites-vous rappeler" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider section />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16">
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/api_1.png"
                      centered
                      style={{ display: 'block', width: '80%', margin: 'auto' }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      API REST
                    </Header>
                    <p>
                      Prédictible, orienté ressource, réponses au format JSON.
                      <br />
                      Une clé API Courrier de test pour la phase de développement.
                    </p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="m-top-30">
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Flexible
                    </Header>
                    <p>
                      Pour soumettre vos courriers à l'API vous pouvez envoyer un fichier local, une URL vers un fichier, du HTML ou un identifiant de template.
                      <br />
                      Envoyez jusqu'a 5 fichiers simultanément.
                    </p>
                  </Grid.Column>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/api_2.png"
                      centered
                      style={{ display: 'block', width: '80%', margin: 'auto' }}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="m-top-30" style={{ paddingTop: '40px' }}>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/api_3.png"
                      centered
                      style={{ display: 'block', width: '80%', margin: 'auto' }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      SDKs et support
                    </Header>
                    <p>
                      Des SDKs disponibles en{' '}
                      <a href="https://github.com/seeuletter/seeuletter-node" title="Package Node.js - API MySendingBox d'envoi de courrier">
                        Node.js
                      </a>
                      ,{' '}
                      <a href="https://github.com/seeuletter/seeuletter-ruby" title="Package Ruby - API MySendingBox d'envoi de courrier">
                        Ruby
                      </a>
                      ,{' '}
                      <a href="https://github.com/seeuletter/seeuletter-php" title="Package PHP - API MySendingBox d'envoi de courrier">
                        PHP
                      </a>
                      ,{' '}
                      <a href="https://github.com/seeuletter/seeuletter-python" title="Package Python - API MySendingBox d'envoi de courrier">
                        Python
                      </a>{' '}
                      et{' '}
                      <a href="https://github.com/seeuletter/seeuletter-java" title="Package Java - API MySendingBox d'envoi de courrier">
                        Java
                      </a>
                      .
                      <br />
                      Un support technique réactif.
                      <br />
                      Une question technique ?{' '}
                      <a
                        onClick={(e: any) => {
                          if (window.Intercom) {
                            e.preventDefault()
                            IntercomAPI('showNewMessage')
                          }
                        }}
                        href="mailto:hello@MySendingBox.fr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contactez-nous.
                      </a>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="m-top-30" style={{ paddingTop: '40px' }}>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Webhooks - Suivi des courriers
                    </Header>
                    <p>
                      <b>Suivez vos courriers envoyés par la Poste</b> directement depuis l'API grâce aux webhooks.
                      <br />
                      Soyez notifié instantanément lors de la réception de votre <b>recommandé</b>.
                    </p>
                  </Grid.Column>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/api_4.png"
                      centered
                      style={{ display: 'block', width: '80%', margin: 'auto' }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider section />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer={12} tablet="10" mobile="16" textAlign="center" verticalAlign="middle">
              <Header as="h3" style={{ fontSize: '2em' }}>
                Moderne et évolutive
              </Header>

              <Header size="small" textAlign="center">
                Testez dès maintenant l’API d’envoi de courrier la plus simple et moderne.
              </Header>
              <p>
                Librairies disponibles :{' '}
                <a href="https://github.com/seeuletter/seeuletter-node" title="Package Node.js - API MySendingBox d'envoi de courrier">
                  Node.js
                </a>
                ,{' '}
                <a href="https://github.com/seeuletter/seeuletter-ruby" title="Package Ruby - API MySendingBox d'envoi de courrier">
                  Ruby
                </a>
                ,{' '}
                <a href="https://github.com/seeuletter/seeuletter-php" title="Package PHP - API MySendingBox d'envoi de courrier">
                  PHP
                </a>
                ,{' '}
                <a href="https://github.com/seeuletter/seeuletter-python" title="Package Python - API MySendingBox d'envoi de courrier">
                  Python
                </a>
                ,{' '}
                <a href="https://github.com/seeuletter/seeuletter-java" title="Package Java - API MySendingBox d'envoi de courrier">
                  Java
                </a>
                .
              </p>
            </Grid.Column>

            <Grid.Column width="16">
              <Divider hidden />
            </Grid.Column>

            <Grid.Column computer={12} tablet="10" mobile="16" textAlign="center">
              <LazyLoad placeholder={<LoadingComponent />}>
                <div style={{ textAlign: 'left' }}>
                  <Script url="https://embed.runkit.com" onLoad={handleScriptLoad} />

                  {runkitLoaded ? (
                    <Embed
                      syntaxTheme="stripe-light"
                      nodeVersion="8.x.x"
                      uiStyles={{
                        borderColor: 'transparent',
                        controlsBackground: '#f6f9fc',
                        controlsColor: '#6772e5',
                        hoverColor: '#32325d',
                        logoColor: '#6772e5',
                        editedColor: '#6772e5',
                        runButtonBackground: '#01c3a7',
                        runButtonColor: '#fff',
                        runButtonBorder: 'transparent',
                      }}
                      gutterStyle="inside"
                      source={`
// Faites appel à la bibliothèque Seeuletter avec une clé test secrète.
const Seeuletter = require('seeuletter')('test_4345c732-1e06-4096-9446-a54054c9b470')

// Créez une nouvelle lettre à partir d'HTML (vous pouvez aussi passer directement un PDF ou un lien vers un PDF)
const letter = await Seeuletter.letters.create({
description: 'Test Letter',
to: {
  name: 'Erlich',
  address_line1: '30 rue de rivoli',
  address_city: 'Paris',
  address_country: 'France',
  address_postalcode: '75004'
},
postage_type: 'prioritaire',
color: 'bw',
source_file: '<html>Hello, {{nom}}</html>',
source_file_type: 'html',
variables: {
  nom : 'Erlich'
}
})
`}
                    />
                  ) : (
                    <LoadingComponent />
                  )}
                </div>
              </LazyLoad>

              <Divider hidden section />

              <Button primary as={Link} to="/guide/bien-demarrer-avec-seeuletter-envoi-courrier-carte-postale-en-ligne">
                Guide de démarrage
              </Button>
              <Button href="https://docs.MySendingBox.fr/" target="_blank">
                Consulter la documentation
              </Button>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider section />
              <Divider hidden />

              <Header as="h2">Le courrier intelligent avec MySendingBox</Header>

              <Header size="small">Une API moderne et simple pour envoyer du courrier postal.</Header>

              <Divider hidden />
              <Divider hidden />

              <Button as={Link} to="/signup/" size="big" primary content="Récupérez votre clé API" />
              <CallMeButton text="Faites-vous rappeler" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default API_Courrier
