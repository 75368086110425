import React, { Component, MouseEvent } from 'react'
import { Accordion, AccordionTitleProps, Button, ButtonProps, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react'
import { IntercomAPI } from 'react-intercom'

import { createClient } from 'contentful'

import { getFormatedPrice } from '../utils/AppUtils'
import orderBy from 'lodash/orderBy'
import marked from './../utils/MarkdownRenderer'

import { Helmet } from 'react-helmet'
import SegmentCustom from '../components/SegmentCustom'
import { Link, RouteChildrenProps } from 'react-router-dom'
import Debug from 'debug'
import CallMeButton from '../components/CallMeButton'
import ContactUsButton from '../components/ContactUsButton'

const debug = Debug('app:containers:PricingLetter')

type PricingProps = RouteChildrenProps

interface PricingState {
  nb_page: number
  nb_courrier: number
  color: string
  total: null
  affranchissement: string
  prix_service: null
  marge: null
  prix_sans_maileva: null
  prix_affranchissement: null
  activeIndex: number
  items: any[]
  discount_percentage: number
  loading?: boolean
}

class Pricing extends Component<PricingProps, PricingState> {
  pricing: any

  constructor(props: PricingProps) {
    super(props)
    this.state = {
      nb_page: 1,
      nb_courrier: 1,
      color: 'bw',
      total: null,
      affranchissement: 'prioritaire',
      prix_service: null,
      marge: null,
      prix_sans_maileva: null,
      prix_affranchissement: null,
      activeIndex: 0,
      items: [],
      discount_percentage:
        process.env.REACT_APP_WHITE_LABEL === 'canon_fac_simile'
          ? 0.88
          : process.env.REACT_APP_WHITE_LABEL === 'axena' || process.env.REACT_APP_WHITE_LABEL === 'euro_tvs'
          ? 0.9
          : process.env.REACT_APP_WHITE_LABEL === '3h18' || process.env.REACT_APP_WHITE_LABEL === 'e-print'
          ? 0.95
          : 1,
    }

    this.pricing = {
      pack: {
        developer: {
          per_month: {
            bw: 0,
            color: 0,
          },
          letters_included: 0,
          service: {
            bw: 0.45 * this.state.discount_percentage,
            color: 0.66 * this.state.discount_percentage,
          },
          page11plus: 0.12,
          ere: 2.25,
          lre: 3.49,
          mail_electronic: 0.12,
          sms: 0.17,
          service_reco_d_d1: 1.15 * this.state.discount_percentage,
          additional_page: {
            bw: 0.18 * this.state.discount_percentage,
            color: 0.33 * this.state.discount_percentage,
          },
          ar_electronique: 0.75 * this.state.discount_percentage,
        },
        startup: {
          per_month: {
            bw: 90,
            color: 110,
          },
          letters_included: 100,
          service: {
            bw: 0.42 * this.state.discount_percentage,
            color: 0.61 * this.state.discount_percentage,
          },
          page11plus: 0.11,
          ere: 2.15,
          lre: 3.45,
          mail_electronic: 0.1,
          sms: 0.15,
          service_reco_d_d1: 1.14 * this.state.discount_percentage,
          additional_page: {
            bw: 0.17 * this.state.discount_percentage,
            color: 0.32 * this.state.discount_percentage,
          },
          ar_electronique: 0.73 * this.state.discount_percentage,
        },
        business: {
          per_month: {
            bw: 820,
            color: 970,
          },
          letters_included: 1000,
          service: {
            bw: 0.35 * this.state.discount_percentage,
            color: 0.5 * this.state.discount_percentage,
          },
          page11plus: 0.12,
          ere: 1.95,
          lre: 3.4,
          mail_electronic: 0.08,
          sms: 0.12,
          service_reco_d_d1: 1.13 * this.state.discount_percentage,
          additional_page: {
            bw: 0.16 * this.state.discount_percentage,
            color: 0.3 * this.state.discount_percentage,
          },
          ar_electronique: 0.71 * this.state.discount_percentage,
        },
      },
      default: {
        ecopli: 0.619 /* G4 / Tem'post G4 mécanisable 0 à 50 g toute france */,
        prioritaire: 0.694 /* G3 Mécanisable 0 à 50 g toute france */,
        lrar: 4.41 + 0.2 + 1.25 /* Lettre recommandée premium égrenée*/,
        ar: 1.25,
        d: 0.15,
        express: {
          bw: 0.3,
          color: 0.6,
        },
      },
    }
  }

  componentDidMount(): void {
    this.fetchFAQ()
  }

  handleColorChange = (e: MouseEvent, data: ButtonProps): any => {
    // debug('handleColorChange : ', data.value)
    this.setState({
      color: data.value,
    })
  }

  handleClick = (e: MouseEvent, titleProps: AccordionTitleProps): any => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex as number })
  }

  fetchFAQ = (): any => {
    const preview = true
    this.setState({
      loading: true,
    })

    const client = createClient({
      space: 'uu95v6ljdw3h',
      host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: preview
        ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
        : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
    })

    debug('Fetching Entries....')

    client
      .getEntries({
        content_type: 'faq',
        'fields.category': 'pricing_letter',
      })
      .then((response: any) => {
        debug('items : ', response.items)
        return this.setState({
          items: orderBy(
            response.items.map((item: any) => {
              return item.fields
            }),
            ['order'],
            ['asc']
          ),
          loading: false,
        })
      })
      .catch((error: any) => {
        return error
      })
  }

  render(): JSX.Element {
    const { color, activeIndex } = this.state

    debug('this.props.location.pathname: ', this.props.location.pathname)
    return (
      <div>
        <Helmet>
          <title>Tarifs Courrier - MySendingBox - Solutions d'envoi de courrier</title>
          <meta
            name="description"
            content="Tarifs d'envoi de courrier depuis l'API RESTful. Envoyer des lettres vertes, prioritaires ou encore des recommandés."
          />
          <meta
            property="og:description"
            content="Tarifs d'envoi de courrier depuis l'API RESTful. Envoyer des lettres vertes, prioritaires ou encore des recommandés."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%' }}>
          <Divider hidden />

          <Grid columns="equal">
            <Grid.Row centered stretched>
              <Grid.Column computer={10} tablet={14} mobile={16} textAlign="center">
                <SegmentCustom transparent basic>
                  <Button.Group size="medium">
                    <Button color={'violet'} as={Link} to="/tarifs/courrier" content="COURRIER" icon="envelope" />
                    <Button as={Link} to="/tarifs/carte-postale" content="CARTE POSTALE" icon="image" />
                  </Button.Group>
                </SegmentCustom>
                <Header as="h1" size="huge">
                  Prix simples
                  <Header.Subheader>
                    <b> Sans abonnement. Vous ne payez que ce que vous consommez.</b> <br />
                    Pas de frais d'installation. Pas de frais cachés. Sans engagement.
                    <br />
                    <br />
                    Affranchissement au tarif Grand-Compte.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered stretched>
              <Grid.Column computer={12} tablet={16} mobile={16} textAlign="center">
                <Segment attached>
                  <Button.Group size="big">
                    <Button color={color === 'bw' ? 'orange' : undefined} value="bw" onClick={this.handleColorChange}>
                      Noir et blanc
                    </Button>
                    <Button color={color === 'color' ? 'orange' : undefined} value="color" onClick={this.handleColorChange}>
                      Couleur
                    </Button>
                    <Button color={color === 'none' ? 'orange' : undefined} value="none" onClick={this.handleColorChange}>
                      Électronique
                    </Button>
                  </Button.Group>

                  <Divider hidden section />

                  <Table basic="very" padded="very" unstackable celled collapsing style={{ margin: 'auto' }} size="large">
                    <Table.Header>
                      <Table.Row textAlign="center">
                        <Table.HeaderCell style={{ height: '80px', width: '200px' }}>
                          <Header as="h4">
                            <Header.Content>
                              Consommation
                              <br />
                              mensuelle
                            </Header.Content>
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          <small>de</small> 0 <small>à</small> 100 courriers ️
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          <small>+ de</small> 100 courriers ️
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          <small>+ de</small> 1 000 courriers ️
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    {color === 'none' ? (
                      <Table.Body>
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>Lettre recommandée électronique</Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.developer.lre, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.startup.lre, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.business.lre, 1, '0,0[.]00 $')}</Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>Envoi recommandé électronique</Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.developer.ere, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.startup.ere, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.business.ere, 1, '0,0[.]00 $')}</Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>Courrier électronique</Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.developer.mail_electronic, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.startup.mail_electronic, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.business.mail_electronic, 1, '0,0[.]00 $')}</Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>SMS</Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.developer.sms, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.startup.sms, 1, '0,0[.]00 $')}</Table.Cell>
                          <Table.Cell>{getFormatedPrice(this.pricing.pack.business.sms, 1, '0,0[.]00 $')}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    ) : (
                      <Table.Body>
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                Ecopli <small>J+4</small>
                                <br />
                                <small>Une page</small>
                                <br />
                                <small>
                                  <b>Affranchissement inclus</b>
                                </small>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(this.pricing.default.ecopli + this.pricing.pack.developer.service[this.state.color])}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.ecopli, 1, '0,0[.]000 $')}
                                  <br />
                                  Service : {getFormatedPrice(this.pricing.pack.developer.service[this.state.color], 1, '0,0[.]000 $')}
                                </span>
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(this.pricing.default.ecopli + this.pricing.pack.startup.service[this.state.color])}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.ecopli, 1, '0,0[.]000 $')}
                                  <br />
                                  Service : {getFormatedPrice(this.pricing.pack.startup.service[this.state.color], 1, '0,0[.]000 $')}
                                </span>
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(this.pricing.default.ecopli + this.pricing.pack.business.service[this.state.color])}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.ecopli, 1, '0,0[.]000 $')}
                                  <br />
                                  Service : {getFormatedPrice(this.pricing.pack.business.service[this.state.color], 1, '0,0[.]000 $')}
                                </span>
                              }
                            />
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                Prioritaire <small>J+2</small>
                                <br />
                                <small>Une page</small>
                                <br />
                                <small>
                                  <b>Affranchissement inclus</b>
                                </small>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(this.pricing.default.prioritaire + this.pricing.pack.developer.service[this.state.color])}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.prioritaire, 1, '0,0[.]000 $')}
                                  <br />
                                  Service : {getFormatedPrice(this.pricing.pack.developer.service[this.state.color], 1, '0,0[.]000 $')}
                                </span>
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(this.pricing.default.prioritaire + this.pricing.pack.startup.service[this.state.color])}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.prioritaire, 1, '0,0[.]000 $')}
                                  <br />
                                  Service : {getFormatedPrice(this.pricing.pack.startup.service[this.state.color], 1, '0,0[.]000 $')}
                                </span>
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(this.pricing.default.prioritaire + this.pricing.pack.business.service[this.state.color])}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.prioritaire, 1, '0,0[.]000 $')}
                                  <br />
                                  Service : {getFormatedPrice(this.pricing.pack.business.service[this.state.color], 1, '0,0[.]000 $')}
                                </span>
                              }
                            />
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                Recommandé avec AR
                                <br />
                                <small>Une page</small>
                                <br />
                                <small>
                                  <b>Affranchissement inclus</b>
                                </small>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(
                                    this.pricing.default.lrar +
                                      this.pricing.pack.developer.service[this.state.color] +
                                      this.pricing.pack.developer.service_reco_d_d1
                                  )}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.lrar, 1, '0,0[.]000 $')}
                                  <br />
                                  Service :{' '}
                                  {getFormatedPrice(
                                    this.pricing.pack.developer.service[this.state.color] + this.pricing.pack.developer.service_reco_d_d1,
                                    1,
                                    '0,0[.]000 $'
                                  )}
                                </span>
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(
                                    this.pricing.default.lrar +
                                      this.pricing.pack.startup.service[this.state.color] +
                                      this.pricing.pack.startup.service_reco_d_d1
                                  )}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.lrar, 1, '0,0[.]000 $')}
                                  <br />
                                  Service :{' '}
                                  {getFormatedPrice(
                                    this.pricing.pack.startup.service[this.state.color] + this.pricing.pack.startup.service_reco_d_d1,
                                    1,
                                    '0,0[.]000 $'
                                  )}
                                </span>
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <abbr title="">
                                  {getFormatedPrice(
                                    this.pricing.default.lrar +
                                      this.pricing.pack.business.service[this.state.color] +
                                      this.pricing.pack.business.service_reco_d_d1
                                  )}{' '}
                                  <small>/ courrier</small>{' '}
                                </abbr>
                              }
                              content={
                                <span>
                                  Affranchissement : {getFormatedPrice(this.pricing.default.lrar, 1, '0,0[.]000 $')}
                                  <br />
                                  Service :{' '}
                                  {getFormatedPrice(
                                    this.pricing.pack.business.service[this.state.color] + this.pricing.pack.business.service_reco_d_d1,
                                    1,
                                    '0,0[.]000 $'
                                  )}
                                </span>
                              }
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="center">
                          <Table.Cell colSpan="4">
                            <Header as="h3">Options</Header>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                Page supplémentaire
                                <br />
                                <small>Jusqu'à 10 pages</small>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.developer.additional_page[this.state.color])} <small>/ page</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.startup.additional_page[this.state.color])} <small>/ page</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.business.additional_page[this.state.color])} <small>/ page</small>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                Page supplémentaire
                                <br />
                                <small>Au delà de 10 pages</small>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.developer.page11plus, 1, '0,0[.]000 $')} <small>/ page</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.startup.page11plus, 1, '0,0[.]000 $')} <small>/ page</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.business.page11plus, 1, '0,0[.]000 $')} <small>/ page</small>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                Gestion PND
                                <br />
                                <small> par plis retournés </small>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(0.35)} <small>/ pli</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(0.35)} <small>/ pli</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(0.35)} <small>/ pli</small>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                Grande Enveloppe
                                <br />
                                <small>C4</small>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(0.4)} <small>/ enveloppe</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(0.4)} <small>/ enveloppe</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(0.4)} <small>/ enveloppe</small>
                          </Table.Cell>
                        </Table.Row>

                        {process.env.REACT_APP_WHITE_LABEL !== 'canon_fac_simile' &&
                          process.env.REACT_APP_WHITE_LABEL !== 'axena' &&
                          process.env.REACT_APP_WHITE_LABEL !== 'euro_tvs' &&
                          process.env.REACT_APP_WHITE_LABEL !== 'e-print' &&
                          process.env.REACT_APP_WHITE_LABEL !== '3h18' && (
                            <Table.Row textAlign="center">
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    <Popup
                                      trigger={<abbr title="">Dépôt express de recommandé</abbr>}
                                      position="top center"
                                      content="Cachet de la poste à J si dépôt avant ~22h (tous les jours)"
                                    />
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell colSpan="3">
                                <small>+</small>
                                {getFormatedPrice(0.5)} <small>/ page</small>
                              </Table.Cell>
                            </Table.Row>
                          )}

                        <Table.Row textAlign="center">
                          <Table.Cell>
                            <Header as="h4">
                              <Header.Content>
                                <Popup
                                  trigger={<abbr title="">Gestion éléctronique des AR</abbr>}
                                  position="top center"
                                  content={<span>Celui ci sera disponible en ligne après avoir été réceptionné et scanné.</span>}
                                />
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.developer.ar_electronique)} <small>/ courrier</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.startup.ar_electronique)} <small>/ courrier</small>
                          </Table.Cell>
                          <Table.Cell>
                            <small>+</small>
                            {getFormatedPrice(this.pricing.pack.business.ar_electronique)} <small>/ courrier</small>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="center">
                          <Table.Cell colSpan="4">
                            <Header as="h5">
                              L'affranchissement évolue en fonction du nombre de page. <ContactUsButton buttonText="Contactez-nous." displayAs="link" />
                            </Header>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    )}
                  </Table>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered stretched>
              <Grid.Column computer={8} tablet={12} mobile={16} textAlign="center">
                <Header>
                  Vous avez des besoins spécifiques ? <br />
                  Ou des questions sur nos prix ? <br />
                  <a
                    onClick={(e: any) => {
                      if (window.Intercom) {
                        e.preventDefault()
                        IntercomAPI('showNewMessage')
                      }
                    }}
                    href="mailto:hello@MySendingBox.fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactez-nous.
                  </a>
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Divider hidden />
                <Button as={Link} to="/signup/" primary size="huge" content="Commencer" />

                <CallMeButton text="Faites-vous rappeler" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Divider hidden />
                <Header as="h2" size="large" textAlign="center">
                  Foire aux questions
                </Header>
                <Divider hidden />

                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign="left">
                      {!!this.state.items.length && (
                        <Accordion styled fluid>
                          {!!this.state.items.length &&
                            this.state.items.map((item: any, index: any) => {
                              return (
                                <div key={index}>
                                  <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
                                    <Icon name="dropdown" />
                                    {item.title}
                                  </Accordion.Title>
                                  <Accordion.Content active={activeIndex === index}>
                                    <div dangerouslySetInnerHTML={{ __html: marked(item.content) }} />
                                  </Accordion.Content>
                                </div>
                              )
                            })}
                        </Accordion>
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered stretched>
                    <Grid.Column computer={8} tablet={12} mobile={16} textAlign="center">
                      <Header>
                        Vous ne trouvez pas de réponse à vos questions ?{' '}
                        <a
                          onClick={(e: any) => {
                            if (window.Intercom) {
                              e.preventDefault()
                              IntercomAPI('showNewMessage')
                            }
                          }}
                          href="mailto:hello@MySendingBox.fr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Contactez-nous.
                        </a>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default Pricing
