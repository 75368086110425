import React, { Component, MouseEvent } from 'react'
import { Button, ButtonProps, Container, Divider, Grid, Header, Image, Segment, Visibility, VisibilityEventData } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
// import Debug from 'debug'
import FilePlayer from 'react-player/lib/players/FilePlayer'
import CallMeButton from '../../components/CallMeButton'
import marked from '../../utils/MarkdownRenderer'
import ContactUsButton from '../../components/ContactUsButton'
import PropTypes from 'prop-types'

// const debug = Debug('app:containers:Contentful3')

interface List3ItemsCampaignProps {
  landing: any
  handleVideoClick: () => void
  startPlaying: (e: null, data: VisibilityEventData) => void
  playing: boolean
}

interface List3ItemsCampaignState {
  side: string
}

class List3ItemsCampaign extends Component<List3ItemsCampaignProps, List3ItemsCampaignState> {
  static contextTypes = {
    t: PropTypes.func,
  }

  constructor(props: List3ItemsCampaignProps) {
    super(props)
    this.state = {
      side: 'recto',
    }
  }

  handleSideChange = (e: MouseEvent, data: ButtonProps): any => {
    // debug('handleColorChange : ', data.value)
    this.setState({
      side: data.value,
    })
  }

  render(): JSX.Element {
    const { landing } = this.props
    const { side } = this.state

    return (
      <div>
        <Helmet>
          <title>{landing.title}</title>
          <meta name="description" content={landing.description} />

          <meta property="og:title" content={landing.title} />
          <meta property="og:description" content={landing.description} />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns={'equal'}>
            <Grid.Row centered verticalAlign="middle">
              <Grid.Column computer={landing.image ? 10 : 14} tablet="16" mobile="16" textAlign="center">
                <Grid columns="equal">
                  <Grid.Row centered>
                    <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                      <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                        {landing.header}
                      </Header>

                      <Header as="h2" textAlign="center" size="medium">
                        <div dangerouslySetInnerHTML={{ __html: marked(landing.subHeader || '<i>Aucun contenu</i>') }} />
                      </Header>
                      <Divider hidden />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content={landing.textButton || 'Commencer'} />
                      <CallMeButton text={this.context.t('Faites-vous rappeler')} />
                      {landing.textUnderButtons && (
                        <p style={{ marginTop: '10px' }}>
                          <small>{landing.textUnderButtons || 'Sans abonnement ni engagement.'}</small>
                        </p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              {landing.image && (
                <Grid.Column className={landing.imageHideMobile && 'mobile hidden tablet hidden'} computer={6} tablet="16" mobile="16" textAlign="center">
                  <Image className={`center-block`} width="90%" src={`${side === 'recto' ? landing.image : 'verso'}`} />

                  {landing.imageVerso && (
                    <Button.Group size="small">
                      <Button color={side === 'recto' ? 'violet' : undefined} value="recto" onClick={this.handleSideChange}>
                        Recto
                      </Button>
                      <Button color={side === 'verso' ? 'violet' : undefined} value="verso" onClick={this.handleSideChange}>
                        Verso
                      </Button>
                    </Button.Group>
                  )}
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h3" size="large">
                  {landing.headerListBloc || "3 étapes, 3 clics et c'est tout !"}
                </Header>

                <Divider hidden section />

                <Grid verticalAlign="middle" textAlign="left" centered>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${landing.bloc1Icon || 'mysendingbox/icons/campagne_1'}.png`}
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        {landing.bloc1Title}
                      </Header>
                      <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc1Text || '<i>Aucun contenu</i>') }} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${
                          landing.bloc2Icon || 'digital_drawing-BkqZpm3wM_rbzh8h'
                        }.png`}
                        centered
                        style={{ display: 'block', width: '60%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        {landing.bloc2Title}
                      </Header>
                      <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc2Text || '<i>Aucun contenu</i>') }} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <Image
                        src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${landing.bloc3Icon || 'send-ByJJG0twM_ioyntl'}.png`}
                        centered
                        style={{ display: 'block', width: '70%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        {landing.bloc3Title}
                      </Header>
                      <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc3Text || '<i>Aucun contenu</i>') }} />
                    </Grid.Column>
                  </Grid.Row>
                  {landing.bloc4Title && (
                    <Grid.Row>
                      <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                        <Image
                          src={`https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/${
                            landing.bloc4Icon || 'send-volumes_icon_landing_pjzxef'
                          }.png`}
                          centered
                          style={{ display: 'block', width: '70%', margin: 'auto' }}
                        />
                      </Grid.Column>
                      <Grid.Column computer="12" tablet="12" mobile="13">
                        <Header as="h3" size="medium">
                          {landing.bloc4Title}
                        </Header>
                        <div dangerouslySetInnerHTML={{ __html: marked(landing.bloc4Text || '<i>Aucun contenu</i>') }} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  <Grid.Row centered>
                    <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                      <Divider hidden />
                      <Header size="tiny">
                        {this.context.t('Tout se fait depuis votre navigateur web.')}
                        <br />
                        {this.context.t("Vous n'avez rien à installer !")}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content={landing.textButton || 'Commencer'} />
                      <CallMeButton text={this.context.t('Faites-vous rappeler')} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />

                <Divider section />

                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h3" size="large">
                  {landing.headerVideo || '50 secondes pour envoyer vos courriers'}
                  {landing.subHeaderVideo && <Header.Subheader>{landing.subHeaderVideo || ''}</Header.Subheader>}
                </Header>
                <Divider hidden />

                <Segment onClick={this.props.handleVideoClick}>
                  <Visibility onTopVisible={this.props.startPlaying}>
                    <FilePlayer
                      muted
                      playing={this.props.playing}
                      loop
                      controls
                      width="100%"
                      height="100%"
                      style={{ width: '100%', height: '400px' }}
                      url={landing.urlVideo || `https://res.cloudinary.com/lifebot/video/upload/ac_none/v1519300094/Video_demo_campagne.mp4`}
                    />
                  </Visibility>
                </Segment>

                <Grid>
                  <Grid.Row centered>
                    <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                      <Divider hidden />
                      <Header size="small">
                        {landing.headerApi || "Vous souhaitez intégrer l'envoi de courrier dans vos processus métier (ERP/CRM) ?"}
                        <br />
                        <Link to="/solutions/api-envoi-courrier-postal-recommande">{this.context.t('Intégrez notre API simple et moderne')}</Link>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider />
                <Divider hidden />

                <Header as="h2">{landing.headerBottomBloc || 'Envoyez vos courriers en quelques clics'}</Header>

                <Header size="small">
                  {landing.subHeaderBottomBloc || (
                    <span>
                      Sans engagement et à partir de 0,85€/courrier ou 1,35€/carte postale&nbsp;
                      <small>(affranchissement compris)</small>.
                    </span>
                  )}
                </Header>
                <Divider hidden />
                <Divider hidden />

                <Button as={Link} to="/signup/?nav=false" color="orange" size="big" content={landing.textButton || 'Commencer'} />

                <CallMeButton text={this.context.t('Faites-vous rappeler')} />

                <Divider section hidden />

                <ContactUsButton displayAs="link" buttonText={this.context.t('Des questions ? Contactez-nous.')} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default List3ItemsCampaign
