import React, { FC } from 'react'
import { Button, Container, Divider, Grid, Header, Image } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import CallMeButton from '../../components/CallMeButton'

const Acquisition: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Acquisition - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
        <meta
          name="description"
          content="Attirez l'attention de vos futurs clients. MySendingBox vous permet de personnaliser chaque courrier en fonction du destinataire."
        />

        <meta property="og:title" content="Acquisition - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
        <meta
          property="og:description"
          content="Attirez l'attention de vos futurs clients. MySendingBox vous permet de personnaliser chaque courrier en fonction du destinataire."
        />
      </Helmet>

      <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
        <Divider hidden />

        <Grid columns="equal">
          <Grid.Row centered>
            <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
              <Button.Group size="medium">
                <Button color="violet" as={Link} to="/solutions/acquisition">
                  ACQUISITION
                </Button>
                <Button as={Link} to="/solutions/retention">
                  RETENTION
                </Button>
              </Button.Group>

              <Divider hidden />

              <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                Attirez l'attention de vos futurs clients.
              </Header>

              <Header as="h2" textAlign="center" size="medium">
                MySendingBox vous permet de personnaliser chaque courrier <br /> en fonction du destinataire.
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column width="16" textAlign="center">
              <Button as={Link} to="/solutions/" size="big" primary content="Découvrir nos solutions" />
              <CallMeButton text="Faites-vous rappeler" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider section />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16">
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_120/mysendingbox/icons/acquisition_1.png"
                      centered
                      style={{ display: 'block', width: '70%', margin: 'auto' }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Outbound marketing
                    </Header>
                    <p>Facilitez la prise de contact de vos commerciaux par l’envoi d’un courrier.</p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="m-top-30">
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Personnalisation
                    </Header>
                    <p>
                      <b>Démarquez vous par un contenu personnalisé de qualité.</b>
                    </p>
                    <p>Créez simplement votre modèle de lettre ou de carte postale et ajoutez du contenu personnalisé pour chaque destinataire.</p>
                  </Grid.Column>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_120/mysendingbox/icons/acquisition_2.png"
                      centered
                      style={{ display: 'block', width: '70%', margin: 'auto' }}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="m-top-30" style={{ paddingTop: '40px' }}>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_120/mysendingbox/icons/acquisition_3.png"
                      centered
                      style={{ display: 'block', width: '70%', margin: 'auto' }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Automatisation
                    </Header>
                    <p>
                      <b>Intégrez le courrier dans vos process commerciaux et automatisez vos envois.</b>
                    </p>
                    <p>Les solutions MySendingBox s'intègrent très facilement avec vos applications (Zapier, Customer.io, Pipedrive, CRM, ERP...)</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider hidden />
              <Divider section />
              <Divider hidden />

              <Header as="h2">Le courrier intelligent avec MySendingBox</Header>

              <Header size="small">Une suite d'outils complète pour envoyer du courrier postal et des cartes postales</Header>

              <Divider hidden />
              <Divider hidden />

              <Button as={Link} to="/solutions/" size="big" primary content="Découvrir nos solutions" />
              <CallMeButton text="Faites-vous rappeler" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default Acquisition
