import LocalStorage from './../utils/LocalStorageUtils'
import { historyStore } from './../store'
import Debug from 'debug'
import { IntercomAPI } from 'react-intercom'
import { API_URL } from '../utils/Constants'
import axios from 'axios'
import { setProfile, setToken } from '../utils/AuthService'
import { fetchUser } from './action_user'
import { logException } from '../utils/ErrorLogger'
import ReactPixel from 'react-facebook-pixel'
import { ThunkAction } from 'redux-thunk'
import { Action, AnyAction } from 'redux'

const debug = Debug('app:actions:auth')

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const RESET_SUCCESS = 'RESET_SUCCESS'

export function fetchLogin(
  email: string,
  password: string,
  next_url: string = '/app/dashboard/'
): ThunkAction<Promise<true | AnyAction>, any, unknown, Action<string>> {
  debug('fetchLogin')
  return (dispatch: any): Promise<any> => {
    dispatch(login())
    return axios({
      url: `${API_URL}/login`,
      method: 'POST',
      data: {
        email: email,
        password: password,
      },
    }).then(
      async (response: any) => {
        debug('response :', response)
        setToken(response.data.token)
        setProfile(response.data.profile)
        try {
          const user = await dispatch(fetchUser(response.data.profile.user_id))
          if (user) {
            debug('user : ', user)

            ReactPixel.trackCustom('Login', {
              content_name: 'login',
            }) // For tracking custom events
          } else {
            debug('User is empty')
            logException(new Error('User is empty'), response.data.profile)
            return dispatch(loginFailure(new Error('User is empty')))
          }
        } catch (err) {
          return dispatch(loginFailure(new Error('Error when getting user.')))
        }

        dispatch(loginSuccess(response.data.profile))
        historyStore.push(next_url)

        return true
      },
      (err: any) => {
        debug('err in action :', err.response)
        return dispatch(loginFailure(err))
      }
    )
  }
}

export function fetchLogout(next_url?: string): ThunkAction<AnyAction, any, unknown, Action<string>> {
  return (dispatch: any): any => {
    debug('Logging out / next_url : ', next_url)
    IntercomAPI('shutdown')
    LocalStorage.remove('token')
    LocalStorage.remove('profile')
    if (next_url) {
      historyStore.push('/login?next_url=' + next_url)
    } else {
      historyStore.push('/login')
    }
    return dispatch(logoutSuccess())
  }
}

export function login(): Action<string> {
  return {
    type: LOGIN,
  }
}

export function loginSuccess(profile: any): AnyAction {
  return {
    type: LOGIN_SUCCESS,
    profile: profile,
  }
}

export function loginFailure(error: any): AnyAction {
  return {
    type: LOGIN_FAILURE,
    error: error,
  }
}

export function logoutSuccess(): Action<string> {
  return {
    type: LOGOUT_SUCCESS,
  }
}
