import React, { Component } from 'react'
import { Button, Container, Grid, Header, List, Loader, Segment } from 'semantic-ui-react'
import { createClient } from 'contentful'
import { Helmet } from 'react-helmet'
import Debug from 'debug'
import marked from './../utils/MarkdownRenderer'
import { logException } from '../utils/ErrorLogger'
import { Link, RouteChildrenProps } from 'react-router-dom'

const debug = Debug('app:containers:guides:Legal')

type LegalProps = RouteChildrenProps

interface LegalState {
  activeItem: null
  menuItems: null
  loading: boolean
  item: any | null
}

class Legal extends Component<LegalProps, LegalState> {
  constructor(props: LegalProps) {
    super(props)
    this.state = {
      activeItem: null,
      menuItems: null,
      loading: false,
      item: null,
    }

    const response =
      window.snapStore &&
      window.snapStore[
        `https://cdn.contentful.com/spaces/uu95v6ljdw3h/environments/master/entries?content_type=legal&fields.url=${(props.match?.params as any).legal_id}`
      ]

    debug('response: ', response)

    if (response && response.items && response.items.length) {
      debug('Data set from snapStore ! :', response.items)

      this.setState({
        item: { ...response.items[0].fields, updated_at: response.items[0].sys.updatedAt, created_at: response.items[0].sys.createdAt },
        loading: false,
      })
    }
  }

  componentDidMount(): void {
    if (!this.state.item) {
      this.fetchPost()
    }
  }

  componentDidUpdate(prevProps: LegalProps): void {
    debug('componentDidUpdate')
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.fetchPost()
      debug('componentDidUpdate IF')
    }
  }

  fetchPost = (): any => {
    let preview = false
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      preview = true
    }
    this.setState({
      loading: true,
    })

    const client = createClient({
      space: 'uu95v6ljdw3h',
      host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: preview
        ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
        : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
    })

    debug('Fetching Entries....')

    client
      .getEntries<any>({
        content_type: 'legal',
        'fields.url': (this.props.match?.params as any).legal_id,
      })
      .then((response: any) => {
        debug('response : ', response.items)
        if (!response.items.length) {
          throw new Error(`No items found for /legal/${(this.props.match?.params as any).legal_id}`)
        }
        return this.setState({
          item: { ...response.items[0].fields, updated_at: response.items[0].sys.updatedAt, created_at: response.items[0].sys.createdAt },
          loading: false,
        })
      })
      .catch((err: any) => {
        logException(err)
      })
  }

  render(): JSX.Element {
    const { item, loading } = this.state

    return (
      <Container fluid>
        {!loading && item && (
          <Helmet>
            <title>{item.title} - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
            <meta name="description" content={item.description} />
            <meta property="og:title" content={`${item.title} - MySendingBox - Solutions d'envoi de courrier et de carte postale`} />

            <meta property="og:description" content={item.description} />
          </Helmet>
        )}

        <Container style={{ flex: 1 }}>
          <Grid columns="equal">
            <Grid.Row centered>
              <Grid.Column className="m-bottom-10" mobile="16" computer="14" stretched>
                <Segment color="orange" attached={false} textAlign="left">
                  <Header as="h2" textAlign="center">
                    Solutions d'envoi de courrier postal
                  </Header>

                  <p>MySendingBox propose des solutions en ligne permettant d'envoyer très facilement tout type de courrier postal depuis votre ordinateur.</p>
                  <List bulleted>
                    <List.Item>Un outil de lecture d'adresse vous permettant d'envoyer facilement des courrier similaires.</List.Item>
                    <List.Item>Une solution de publipostage pour créer des campagnes de courrier ou de carte postale personnalisées.</List.Item>
                    <List.Item>Une API moderne pour intégrer l'envoi de courrier dans vos processus.</List.Item>
                  </List>

                  <Segment basic textAlign="center">
                    <Button primary as={Link} to="/solutions">
                      En savoir plus
                    </Button>
                  </Segment>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column className="m-bottom-10" mobile="16" computer="14" stretched>
                <Segment primary>
                  {!item ? (
                    <Segment basic style={{ minHeight: '200px' }}>
                      <Loader active />
                    </Segment>
                  ) : (
                    <div>
                      <Header as="h2" textAlign="center">
                        {item.title}
                      </Header>

                      <div className="m-top-30" dangerouslySetInnerHTML={{ __html: marked(item.content || '<i>Aucun contenu</i>') }} />
                    </div>
                  )}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Container>
    )
  }
}

export default Legal
