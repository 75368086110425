import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header, Icon, Image, List, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import CallMeButton from '../components/CallMeButton'
import LazyLoad from 'react-lazyload'
import { connect, ConnectedProps } from 'react-redux'
import { RootReducerType } from '../reducers'

/*
import Debug from 'debug'
*/

/*
const debug = Debug('app:containers:Home')
*/

const connector = connect((state: RootReducerType) => ({
  ui: state.ui,
}))

type HomeProps = ConnectedProps<typeof connector>

class Home extends Component<HomeProps> {
  render() {
    return (
      <div>
        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row verticalAlign="middle">
              <Grid.Column computer="10" tablet="16" mobile="16" textAlign="center">
                <Grid columns="equal">
                  <Grid.Row centered>
                    <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                      <Header as="h1" style={{ fontSize: '3em' }}>
                        Envoyez du courrier simplement.
                      </Header>
                      <Header as="h2" size="medium" className=" m-no-top">
                        La solution sécurisée 100% web pour <br /> l’envoi de vos courriers postaux simples ou recommandés
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Divider hidden />

                      <Button as={Link} to="/solutions/" primary size="big" content="Découvrir nos solutions" />
                      <CallMeButton text="Faites-vous rappeler" />

                      <Divider hidden />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column className="mobile hidden tablet hidden" computer={6} tablet="16" mobile="16" textAlign="center">
                <Image className="center-block" width="60%" src={`https://res.cloudinary.com/lifebot/image/upload/v1543255302/Home/undraw_opened_gi4n.svg`} />
              </Grid.Column>
            </Grid.Row>

            {/*

            <Grid.Row centered={true}>
              <Grid.Column computer="6" tablet="14" mobile="16">

                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h3' className="m-no-bottom">
                        <Image src='https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/externalisation_thptxb.png'/>
                        {' '}Externalisation
                      </Header>
                      <p style={{marginLeft: '60px'}}>
                        Externalisez vos envois de courriers pour réduire vos coûts et gagner du temps.
                      </p>

                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h3' className="m-no-bottom">
                        <Image src='https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/tout_type_de_courrier_v83igd.png'/>
                        {' '}Tout type
                      </Header>
                      <p style={{marginLeft: '60px'}}>
                        Lettre simple, recommandé, recto/verso, couleur, carte postale... : vous définissez vos propres paramètres.
                      </p>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>

              </Grid.Column>

              <Grid.Column as={Media} at="mobile" mobile={16}>
                <Divider hidden/>
              </Grid.Column>

              <Grid.Column as={Media} at="tablet" tablet={16}>
                <Divider hidden/>
              </Grid.Column>

              <Grid.Column computer="6" tablet="14" mobile="16">

                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h3' className="m-no-bottom">
                        <Image src='https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/contenu_certifié_jr3ahp.png'/>
                        {' '}Contenu certifié
                      </Header>
                      <p style={{marginLeft: '60px'}}>
                        Le contenu de vos courriers est certifié via un numéro de suivi unique
                      </p>

                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h3' className="m-no-bottom">
                        <Image src='https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/suivi_en_ligne_tbgqor.png'/>
                        {' '}Suivi en ligne
                      </Header>
                      <p style={{marginLeft: '60px'}}>
                        Suivez l’acheminement de vos courriers en ligne grâce aux webhooks de tracking.
                      </p>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>

              </Grid.Column>

            </Grid.Row>

*/}

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h3" size="large">
                  Comment ça marche ?
                </Header>

                <Divider hidden section />

                <Grid verticalAlign="middle" textAlign="left" centered>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <LazyLoad>
                        <Image
                          src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/v1569860410/mysendingbox/icons/home_1.png"
                          centered
                          style={{ display: 'block', width: '70%', margin: 'auto' }}
                        />
                      </LazyLoad>
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        1. Déposez vos documents en ligne
                      </Header>
                      <p>
                        Factures, devis, contrats, relances...
                        <br />
                        Déposez vos courriers sur「 MySendingBox 」
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <LazyLoad>
                        <Image
                          src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/home_2.png"
                          centered
                          style={{ display: 'block', width: '60%', margin: 'auto' }}
                        />
                      </LazyLoad>
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        2. Choisissez vos options
                      </Header>
                      <p>
                        Couleurs, type d'affranchissement, recto/verso, vitesse de remise en poste...
                        <br />
                        Affranchissements disponibles : ecopli, prioritaire, recommandé avec AR.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="mobile hidden" computer="3" tablet="3" mobile="3">
                      <LazyLoad>
                        <Image
                          src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/home_3.png"
                          centered
                          style={{ display: 'block', width: '70%', margin: 'auto' }}
                        />
                      </LazyLoad>
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        3. Validez vos envois
                      </Header>
                      <p>
                        Vos courriers seront alors imprimés, mis sous pli et remis à la Poste{' '}
                        <Image
                          src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_35/v1538563356/Logo-laposte-icone_s1my03.png"
                          size="mini"
                          spaced
                        />
                        <br />
                        Suivez leur acheminement de l'impression à la réception.
                      </p>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                      <Divider hidden />
                      <Header color="orange" size="tiny">
                        Tout se fait depuis votre navigateur web.
                        <br />
                        Vous n'avez rien à installer !
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Button as={Link} to="/signup/" color="orange" size="big" content="Commencer" />
                      <CallMeButton text="Faites-vous rappeler" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered className="p-no-all">
              <Grid.Column width="10" textAlign="center">
                <Divider hidden />
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="16">
                <Segment basic>
                  <Header as="h3" size="large" textAlign="center">
                    Cas d'utilisations
                  </Header>
                  <Divider hidden section />
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column computer={6} tablet="8" mobile="16" textAlign="left">
                        <Segment compact className="center-block" style={{ padding: '30px 50px' }}>
                          <Header as="h2" size="medium" textAlign="center" style={{ marginLeft: '-20px' }}>
                            <LazyLoad>
                              <Image src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_80/mysendingbox/icons/home_4.png" />
                            </LazyLoad>
                            <Header.Content>Marketing</Header.Content>
                          </Header>
                          <Divider hidden />
                          <List relaxed>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Outbound Marketing</List.Header>
                              </List.Content>
                            </List.Item>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Acquisition / Fidélisation</List.Header>
                              </List.Content>
                            </List.Item>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Offres promotionnelles</List.Header>
                              </List.Content>
                            </List.Item>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Invitations événements</List.Header>
                              </List.Content>
                            </List.Item>
                          </List>

                          <Divider hidden />

                          <Header textAlign="center">
                            <Header.Content>
                              <Link to="/solutions/acquisition">En savoir plus</Link>
                            </Header.Content>
                          </Header>
                        </Segment>
                        <Divider hidden />
                      </Grid.Column>

                      <Grid.Column computer={6} tablet="8" mobile="16" textAlign="left">
                        <Segment compact className="center-block" style={{ padding: '30px 50px' }}>
                          <Header as="h2" size="medium" textAlign="center" style={{ marginLeft: '-20px' }}>
                            <LazyLoad>
                              <Image src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_80/mysendingbox/icons/home_5.png" />
                            </LazyLoad>
                            <Header.Content>Transactionnel</Header.Content>
                          </Header>
                          <Divider hidden />
                          <List relaxed>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Factures / Devis</List.Header>
                              </List.Content>
                            </List.Item>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Recouvrement impayés</List.Header>
                              </List.Content>
                            </List.Item>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Contrats / résiliations</List.Header>
                              </List.Content>
                            </List.Item>
                            <List.Item style={{ marginTop: '20px', fontWeight: '200' }}>
                              <Icon name="triangle right" />
                              <List.Content>
                                <List.Header style={{ fontWeight: '500' }}>Vérification d'adresses</List.Header>
                              </List.Content>
                            </List.Item>
                          </List>

                          <Divider hidden />

                          <Header textAlign="center">
                            <Header.Content>
                              <Link to="/solutions/facturation-envoi-de-facture-par-courrier">En savoir plus</Link>
                            </Header.Content>
                          </Header>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered className="p-no-all">
              <Grid.Column width="10" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Header as="h2">
                  Le courrier intelligent avec <br />
                  {process.env.REACT_APP_WHITE_LABEL === 'canon_fac_simile' && ' Fac-similé et'}
                  {process.env.REACT_APP_WHITE_LABEL === 'axena' && ' Axena et'}
                  {process.env.REACT_APP_WHITE_LABEL === '3h18' && ' 3H18 et'}
                  {process.env.REACT_APP_WHITE_LABEL === 'euro_tvs' && ' Euro TVS et'}
                  {process.env.REACT_APP_WHITE_LABEL === 'sederoni' && ' Sederoni et'}
                  {process.env.REACT_APP_WHITE_LABEL === 'e-print' && ' E-print et'}「 MySendingBox 」by CORUS
                </Header>

                <Header size="small">Une plateforme web sécurisée d'envoi unitaire ou groupé de courriers postaux simples ou recommandés</Header>
                <Divider hidden />
                <Divider hidden />

                <Button as={Link} to="/solutions/" primary size="big" content="Découvrir nos solutions" />
                <CallMeButton text="Faites-vous rappeler" />
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />

            {/*

            <Grid.Row centered={true}>

              <Grid.Column width="10" textAlign="center">
                <Divider hidden/>
                <Divider/>
                <Divider hidden/>

              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered={true}>

              <Grid.Column computer={8} tablet="10" mobile="16" textAlign="center">
                <SegmentCustom className="p-no-top" compact transparent opacity={0.75} inverted={false} attached={false} padded>
                  <Header as="h3">
                    <Image src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_160/v1499986328/question-HysFK_HH-_dwgth6.png" centered={true} style={{display: 'block', width: '25%', margin: 'auto'}}/>
                    Des questions ? Contactez-nous !
                  </Header>
                  <p>
                    En envoyant un mail à <a href="mailto:hello@MySendingBox.fr">hello@MySendingBox.fr</a> ou <a href="#contactez-nous" onClick={(e) => {
                    e.preventDefault()
                    IntercomAPI('showNewMessage')
                  }}>venez nous parler sur le t'chat</a> !
                  </p>
                </SegmentCustom>
              </Grid.Column>
            </Grid.Row> */}
          </Grid>
        </Container>
      </div>
    )
  }
}

export default connector(Home)
