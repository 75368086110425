import React, { FC } from 'react'
import { Segment, SegmentProps } from 'semantic-ui-react'

type SegmentCustomProps = SegmentProps & {
  transparent: boolean
  opacity?: number
  centered?: boolean
}
const SegmentCustom: FC<SegmentCustomProps> = ({ transparent, opacity = 0.08, centered, ...rest }: SegmentCustomProps): JSX.Element => {
  let style: any = {}
  if (transparent) {
    style = {
      backgroundColor: `rgba(255, 255, 255, ${opacity})`,
      // border: 'none'
    }
  }
  if (centered) {
    style.margin = 'auto'
  }
  return <Segment style={style} {...rest} />
}

export default SegmentCustom
