import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header, Icon, Image, Modal } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Debug from 'debug'
import FilePlayer from 'react-player/lib/players/FilePlayer'
import CallMeButton from '../../components/CallMeButton'

const debug = Debug('app:containers:Solutions')
interface SolutionsState {
  modalOpen: boolean
  gifUrl: string
}

class Solutions extends Component<any, SolutionsState> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: false,
      gifUrl: '',
    }
  }

  openModal = (data: string): any => {
    debug('data : ', data)
    if (data === 'gif-campagne') {
      this.setState({
        gifUrl: 'https://res.cloudinary.com/lifebot/video/upload/ac_none/v1519300094/Video_demo_campagne.mp4',
      })
    } else if (data === 'gif-drag-drop') {
      this.setState({
        gifUrl: 'https://res.cloudinary.com/lifebot/video/upload/ac_none/v1519300434/Demo_video_envois_groupees_2.mp4',
      })
    }
    this.setState({
      modalOpen: true,
    })
  }

  handleClose = (): any => {
    this.setState({
      modalOpen: false,
    })
  }

  render(): JSX.Element {
    return (
      <div>
        <Helmet>
          <title>Solutions - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          <meta name="description" content="Des solutions d'envoi de courriers et de cartes postales clé en main." />

          <meta property="og:title" content="Solutions - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
          <meta property="og:description" content="Des solutions d'envoi de courriers et de cartes postales clé en main." />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row verticalAlign="middle">
              <Grid.Column computer="10" tablet="16" mobile="16" textAlign="center">
                <Grid columns="equal">
                  <Grid.Row centered>
                    <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                      <Header as="h1" style={{ fontSize: '3em' }}>
                        Des solutions d'envoi postal qui s'adaptent à vos besoins
                      </Header>
                      <Header as="h2" size="medium" className=" m-no-top">
                        Du recouvrement à la fidélisation,
                        {process.env.REACT_APP_WHITE_LABEL === 'canon_fac_simile' && ' Fac-similé et'}
                        {process.env.REACT_APP_WHITE_LABEL === 'axena' && ' Axena et'}
                        {process.env.REACT_APP_WHITE_LABEL === '3h18' && ' 3H18 et'}
                        {process.env.REACT_APP_WHITE_LABEL === 'euro_tvs' && ' Euro TVS et'}
                        {process.env.REACT_APP_WHITE_LABEL === 'sederoni' && ' Sederoni et'}
                        {process.env.REACT_APP_WHITE_LABEL === 'e-print' && ' E-print et'}「 MySendingBox 」by CORUS vous propose 3 possibilités pour gérer vos
                        envois de courrier postal.
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Grid.Column width="16" textAlign="center">
                      <Divider hidden />

                      <Button as={Link} to="/signup/" primary size="big" content="Commencer" />
                      <CallMeButton text="Faites-vous rappeler" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column className="mobile hidden tablet hidden" computer={6} tablet="16" mobile="16" textAlign="center">
                <Image
                  className="center-block"
                  width="70%"
                  src={`https://res.cloudinary.com/lifebot/image/upload/v1543255305/Home/undraw_in_progress_ql66.svg`}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />
                <Divider section />
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/solution_1.png"
                        centered
                        style={{ display: 'block', width: '80%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        Créez des campagnes de courriers ou de cartes postales
                      </Header>
                      <p>
                        Envoyez du courrier ou des cartes postales personnalisés à plusieurs destinataires.
                        <br />
                        Créez facilement un publipostage ciblé et personnalisé.
                        <br />
                        <b>Idéal pour l’envoi de masse de courrier postal.</b>{' '}
                        <span role="img" aria-label="mailbox">
                          📮
                        </span>
                        <br />
                        <br />
                        <a href="#demo" onClick={() => this.openModal('gif-campagne')}>
                          {' '}
                          <Icon name="play circle outline" />
                          Vidéo de démonstration &nbsp;
                          <small>(1 min)</small>
                        </a>{' '}
                        | <Link to="/landing/campagne-courrier-carte-postale?nav=true">En savoir plus</Link>
                      </p>
                      {/*
                      <p>
                        1. Ajoutez un fichier Excel contenant vos destinataires.
                        <br/>
                        2. Choisissez ou créez un design personnalisés.
                        <br/>
                        3. Envoyez ! ☄️
                      </p> */}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="m-top-30">
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        Déposez-envoyez vos courriers
                      </Header>
                      <p>
                        Vous avez déjà généré vos courriers ?
                        <br />
                        Envoyez-les sur la plateforme. L'adresse sera lue et chaque document envoyé avec les options choisies.{' '}
                        <span role="img" aria-label="bolt">
                          ⚡️
                        </span>
                        <br />
                        <br />
                        <a href="#demo" onClick={() => this.openModal('gif-drag-drop')}>
                          {' '}
                          <Icon name="play circle outline" />
                          Vidéo de démonstration &nbsp;
                          <small>(51 sec)</small>
                        </a>{' '}
                        | <Link to="/landing/glissez-deposez?nav=true">En savoir plus</Link>
                      </p>
                    </Grid.Column>
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/solution_2.png"
                        centered
                        style={{ display: 'block', width: '80%', margin: 'auto' }}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="m-top-30">
                    <Grid.Column computer="3" tablet="3" mobile="3">
                      <Image
                        src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_150/mysendingbox/icons/solution_3.png"
                        centered
                        style={{ display: 'block', width: '80%', margin: 'auto' }}
                      />
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="12" mobile="13">
                      <Header as="h3" size="medium">
                        Intégration API
                      </Header>
                      <p>
                        Intégrez l'envoi de courrier ou de carte postale dans vos process.
                        <br />
                        Une API simple pour automatiser vos envois de courriers (Recommandés en cas d'impayé, une carte de bienvenue pour chaque nouvel
                        inscrit...){' '}
                        <span role="img" aria-label="settings">
                          ⚙
                        </span>
                        <br />
                        <br />
                        <a href="https://docs.MySendingBox.fr/" title="Documentation API Courrier - MySendingBox">
                          Consulter la documentation
                        </a>{' '}
                        | <Link to="/solutions/api-envoi-courrier-postal-recommande">En savoir plus</Link>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
                <Divider hidden />
                <Divider hidden />
                <Divider section />
                <Divider hidden />

                <Header as="h2">Le courrier intelligent avec MySendingBox</Header>

                <Header size="small">Une suite d'outils complète pour envoyer du courrier postal et des cartes postales</Header>
                <Divider hidden />
                <Divider hidden />

                <Button as={Link} to="/signup/" primary size="big" content="Commencer" />
                <CallMeButton text="Faites-vous rappeler" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Modal open={this.state.modalOpen} onClose={this.handleClose} basic closeIcon size="large">
          <Modal.Content>
            <FilePlayer muted playing loop controls width="100%" height="100%" style={{ width: '100%' }} url={this.state.gifUrl} />
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" onClick={this.handleClose} inverted>
              <Icon name="checkmark" /> Fermer
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default Solutions
