import React from 'react'
import { Provider } from 'react-redux'
import { Route } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import store, { historyStore } from './store'
import * as ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import Parent from './containers/Parent'
import Home from './containers/Home'
import get from 'lodash/get'
import PricingLetter from './containers/PricingLetter'
import { Redirect, Switch } from 'react-router-dom'
import GuidesIndex from './containers/guides/Index'
import BlogIndex from './containers/blog/Index'
import BlogArticle from './containers/blog/Article'
import NoMatch from './containers/NoMatch'
import axios from 'axios'
import Templates from './containers/Templates'
import Features from './containers/solutions/Features'
import Acquisition from './containers/solutions/Acquisition'
import Retention from './containers/solutions/Retention'
import Services_Letters from './containers/produits/Services_Letters'
import Services_Postcards from './containers/produits/Services_Postcards'
import Template from './containers/Template'
import PricingPostcard from './containers/PricingPostcard'
import My_Postcard from './containers/produits/My_Postcard'
import Deux_io from './containers/landing/Deux_io'
import queryString from 'query-string'
import Solutions from './containers/solutions/Solutions'
import Campagnes from './containers/landing/Campagnes'
import Facturation from './containers/solutions/Facturation'
import Recouvrement from './containers/solutions/Recouvrement'
import API_Courrier from './containers/solutions/API_Courrier'
import DragAndDropServicesPersonne from './containers/landing/DragAndDropServicesPersonne'
import Legal from './containers/Legal'
import I18n from 'redux-i18n'
import { translations } from './utils/Translations'
import { getToken } from './utils/AuthService'
import Contentful from './containers/landing/Contentful'
import LandingList from './containers/landing/List'
import { checkTokenValidity, getLanguage, logPageView, saveRefereer } from './utils/RequiredUtils' // load on demand
import Debug from 'debug'
import LocalStorage from './utils/LocalStorageUtils'
import MediaHoc from './components/MediaHoc'
import { Location } from 'history'

const debug = Debug('app:routes')

if (navigator.userAgent !== 'ReactSnap' && process.env.REACT_APP_NODE_ENV === 'production') {
  const advancedMatching: any = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  const options: any = {
    autoConfig: true, // set pixel's autoConfig
  }
  ReactGA.initialize('UA-88381353-2')

  ReactPixel.init('212971732485325', advancedMatching, options)
}

getToken() && checkTokenValidity()

let previous: Location
historyStore.listen((location: any) => {
  if (previous) {
    if (location.pathname !== previous.pathname) {
      previous = location
      logPageView(location.pathname + location.search)
    }
  } else {
    previous = location
    logPageView(location.pathname + location.search)
  }
})

// Add a response interceptor
axios.interceptors.response.use(
  (response: any) => {
    // Do something with response data
    return response
  },
  (error: any) => {
    // Do something with response error
    debug('error : ', error.response)
    debug('error : ', get(error, 'response.status'))
    debug('Error message :', get(error, 'response.data.error.message'))
    if (get(error, 'response.data.error.message') === 'jwt_expired') {
      debug('We reload the page to update the app version too.')
      LocalStorage.remove('token')
      LocalStorage.remove('profile')
      window.location.reload()
    }
    return Promise.reject(error)
  }
)
// first load :
logPageView(window.location.pathname + window.location.search)
saveRefereer()

const Routes = (): any => {
  return (
    <MediaHoc>
      <Provider store={store}>
        <I18n translations={translations} initialLang={getLanguage()} fallbackLang="en">
          <ConnectedRouter history={historyStore}>
            <Parent>
              <Switch>
                <Route exact path="/" component={Home} />

                <Route path="/legal/:legal_id" component={Legal} />

                <Redirect from="/solutions/recouvrement-envoi-de-recommandé-par-courrier" to="/solutions/recouvrement-envoi-de-recommande-par-courrier" />
                <Redirect from="/cgu" to="/legal/cgu" />
                <Redirect from="/cgv" to="/legal/cgv" />

                <Redirect from="/gestion-locative" to="/landing/gestion-locative" />

                <Route path="/fonctionnalites" component={Features} />

                <Route path="/deux-io" component={Deux_io} />

                <Route path="/solutions/acquisition" component={Acquisition} />
                <Route path="/solutions/retention" component={Retention} />

                <Route path="/solutions/facturation-envoi-de-facture-par-courrier" component={Facturation} />
                <Route path="/solutions/recouvrement-envoi-de-recommande-par-courrier" component={Recouvrement} />

                <Route path="/solutions/api-envoi-courrier-postal-recommande" component={API_Courrier} />

                <Route path="/solutions" exact component={Solutions} />

                <Route path="/landing/glissez-deposez-services-personne" component={DragAndDropServicesPersonne} />

                <Route path="/landing/campagne-courrier-carte-postale" component={Campagnes} />

                <Route path="/landing/:page_url" component={Contentful} />

                <Route path="/landing" component={LandingList} />

                <Route path="/my-postcard" component={My_Postcard} />

                <Route path="/services/letters" component={Services_Letters} />
                <Route path="/services/postcards" component={Services_Postcards} />

                <Route path="/tarifs/carte-postale" component={PricingPostcard} />

                <Route path="/tarifs/courrier/simulateur/">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/tarifs/courrier/comparateur/">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/tarifs/courrier/roi">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/tarifs/courrier" component={PricingLetter} />

                <Route path="/tarifs/" component={PricingLetter} />

                <Route path="/pricing_admin">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/guide" component={GuidesIndex} />

                <Route exact path="/blog" component={BlogIndex} />
                <Route path="/blog/:article_id" component={BlogArticle} />

                <Route exact path="/templates/" component={Templates} />
                <Route path="/templates/:template_id" component={Template} />

                <Route path="/impersonate/:token">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/reset-password/:token">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/reset-password">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/invite-register/:token">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/invite-register-partner/:token">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/login">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/signup">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/app/setup/">
                  <RedirectToApp />{' '}
                </Route>

                <Route path="/app/dashboard/">
                  <RedirectToApp />{' '}
                </Route>

                <Route
                  path={`/redirect`}
                  render={(props: any) => {
                    debug('query : ', queryString.parse(props.location.search).next_url)
                    return <Redirect to={queryString.parse(props.location.search).next_url as string} />
                  }}
                />

                <Redirect from="/guide-de-demarrage" to="/" />
                <Redirect from="/authCallback" to="/login" />

                {/* Legacy Redirect App 3.0 */}

                <Redirect from="/letters/new" to="/app/dashboard/letters/new" />
                <Redirect from="/letters/:letter_id" to="/app/dashboard/letters/:letter_id" />
                <Redirect from="/account/invoicing" to="/app/dashboard/settings/invoicing" />

                <Route component={NoMatch} />
              </Switch>
            </Parent>
          </ConnectedRouter>
        </I18n>
      </Provider>
    </MediaHoc>
  )
}

function RedirectToApp() {
  // 👇️ redirect to external URL
  let new_url = 'https://app.mysendingbox.fr'

  if (process.env.REACT_APP_NODE_ENV === 'staging' || process.env.REACT_APP_NODE_ENV === 'development') {
    new_url = 'https://app-staging.mysendingbox.fr'
  }

  new_url =
    new_url +
    window.location.pathname.replace('/app/dashboard/', '/').replace('/app/dashboard', '/').replace('/app/', '/').replace('/app', '/') +
    window.location.search +
    window.location.hash

  // alert(window.location)
  // alert(new_url)

  window.location.replace(new_url)

  return null
}
export default Routes
