import React, { Component } from 'react'
import { Button, Card, Container, Divider, Grid, Header, Image } from 'semantic-ui-react'
import Script from 'react-load-script'
import { Link } from 'react-router-dom'
import Embed from 'react-runkit'
import Helmet from 'react-helmet'
import { LoadingComponent } from '../../components/Loading'
import LazyLoad from 'react-lazyload'

/*
import Debug from 'debug'
*/

/*
const debug = Debug('app:containers:Services_Letters')
*/
interface Services_LettersState {
  runkitLoaded: boolean
}

class Services_Letters extends Component<any, Services_LettersState> {
  constructor(props: any) {
    super(props)
    this.state = {
      runkitLoaded: false,
    }
  }

  handleScriptLoad = (): any => {
    this.setState({
      runkitLoaded: true,
    })
  }

  render(): JSX.Element {
    return (
      <div>
        <Helmet>
          <title>Envoyer des cartes postales - MySendingBox</title>
          <meta
            name="description"
            content="Une suite d'outils flexible et moderne pour envoyer des cartes postales très simplement depuis votre application ou site web."
          />

          <meta property="og:title" content="Envoyer des cartes postales - MySendingBox" />
          <meta
            property="og:description"
            content="Une suite d'outils flexible et moderne pour envoyer des cartes postales très simplement depuis votre application ou site web."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row centered>
              <Grid.Column computer={14} tablet="16" mobile="16" textAlign="center">
                <Button.Group size="medium">
                  <Button as={Link} to="/services/letters">
                    Courrier
                  </Button>
                  <Button color="violet" as={Link} to="/services/postcards">
                    Carte postale
                  </Button>
                </Button.Group>

                <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                  Intégrez l’envoi de cartes postales <br />
                  dans votre communication.
                </Header>

                <Header as="h2" textAlign="center" size="medium">
                  MySendingBox vous permet d’adresser des cartes postales <br />
                  personnalisées à chacun de vos clients ou prospects.
                </Header>

                <Divider hidden />
                <Button as={Link} to="/solutions/" size="big" primary content="Découvrir nos solutions" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="12" tablet="14" mobile="16" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Header size="large" textAlign="center">
                  Le support idéal pour vous différencier
                </Header>
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="6" tablet="14" mobile="16" className="m-bottom-20">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/mysendingbox/icons/cartepostale_1.png" />{' '}
                        Personnalisation
                      </Header>
                      <p style={{ marginLeft: '60px' }}>L’espace du timbre est votre seule limite. Personnalisez votre contenu de A à Z.</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/mysendingbox/icons/cartepostale_3.png" />{' '}
                        Originalité
                      </Header>
                      <p style={{ marginLeft: '60px' }}>
                        Bien plus personnel qu’un e-mail, créer du lien avec vos clients en leur adressant des cartes postales
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column computer="6" tablet="14" mobile="16" className="m-bottom-20">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/mysendingbox/icons/cartepostale_2.png" />{' '}
                        Susciter l’attention
                      </Header>
                      <p style={{ marginLeft: '60px' }}>Démarquez-vous en adressant des cartes postales à vos clients et renforcer votre image.</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" className="m-no-bottom">
                        <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_45/mysendingbox/icons/cartepostale_4.png" />{' '}
                        Viralité
                      </Header>
                      <p style={{ marginLeft: '60px' }}>Profitez de la viralité de la carte postale comme outil de referral marketing.</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="12" tablet="14" mobile="16" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="10" tablet="14" mobile="16">
                <Header size="large" textAlign="center">
                  Nos templates de cartes postales prêts à l’emploi
                </Header>
                <Header size="small" textAlign="center">
                  Personnalisez vos templates HTML pour optimiser vos envois de cartes postales.
                </Header>
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="6" tablet="14" mobile="16">
                <Card raised fluid>
                  <Image src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_700/postcard_welcome_recto_lbyoxt.png" />
                </Card>
              </Grid.Column>
              <Grid.Column verticalAlign="middle" computer="6" tablet="14" mobile="16" textAlign="center">
                <Button primary size="big" as={Link} to="/app/dashboard/">
                  Personnaliser ce template
                </Button>
                <Divider hidden />
                <Link to="/templates">En découvrir d'autres</Link>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer="12" tablet="14" mobile="16" textAlign="center">
                <Divider section />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column computer={12} tablet="10" mobile="16" textAlign="center" verticalAlign="middle">
                <Header as="h3" style={{ fontSize: '2em' }}>
                  Une API moderne et simple à intégrer
                </Header>

                <Header size="small" textAlign="center">
                  Testez dès maintenant l’API d’envoi de cartes postales la plus simple et moderne.
                </Header>
                <p>
                  Librairies disponibles :{' '}
                  <a href="https://github.com/seeuletter/seeuletter-node" title="Package Node.js - API MySendingBox d'envoi de courrier">
                    Node.js
                  </a>
                  ,{' '}
                  <a href="https://github.com/seeuletter/seeuletter-ruby" title="Package Ruby - API MySendingBox d'envoi de courrier">
                    Ruby
                  </a>
                  ,{' '}
                  <a href="https://github.com/seeuletter/seeuletter-php" title="Package PHP - API MySendingBox d'envoi de courrier">
                    PHP
                  </a>
                  ,{' '}
                  <a href="https://github.com/seeuletter/seeuletter-python" title="Package Python - API MySendingBox d'envoi de courrier">
                    Python
                  </a>
                  .
                </p>
              </Grid.Column>

              <Grid.Column width="16">
                <Divider hidden />
              </Grid.Column>

              <Grid.Column computer={12} tablet="10" mobile="16" textAlign="center">
                <LazyLoad placeholder={<LoadingComponent />}>
                  <div style={{ textAlign: 'left' }}>
                    <Script url="https://embed.runkit.com" onLoad={this.handleScriptLoad} />

                    {this.state.runkitLoaded ? (
                      <Embed
                        syntaxTheme="stripe-light"
                        nodeVersion="8.x.x"
                        uiStyles={{
                          borderColor: 'transparent',
                          controlsBackground: '#f6f9fc',
                          controlsColor: '#6772e5',
                          hoverColor: '#32325d',
                          logoColor: '#6772e5',
                          editedColor: '#6772e5',
                          runButtonBackground: '#01c3a7',
                          runButtonColor: '#fff',
                          runButtonBorder: 'transparent',
                        }}
                        gutterStyle="inside"
                        source={`
// Faites appel à la bibliothèque Seeuletter avec une clé test secrète.
const Seeuletter = require('seeuletter')('test_4345c732-1e06-4096-9446-a54054c9b470')

// Créez une nouvelle carte postale à partir d'un template HTML (vous pouvez aussi passer directement un PDF ou un lien vers un PDF)
const postcard = await Seeuletter.postcards.create({
  description: 'Test Postcard from the Node.js Wrapper',
  to: {
    name: 'Erlich',
    address_line1: '30 rue de rivoli',
    address_city: 'Paris',
    address_country: 'France',
    address_postalcode: '75004'
  },
  // https://www.seeuletter.com/templates
  source_file_front: 'Hy2GUkiyz',
  source_file_front_type: 'template_id',

  source_file_back: 'rkfnt1s1z',
  source_file_back_type: 'template_id',

  variables: {
    PRENOM: 'Erlich',
    NOM: 'Bachman',
    CODE_PROMO_BIENVENUE: 'CODE',
    URL_COURTE_BIENVENUE: 'https://goo.gl/uqTHnD',
    ADRESSE: '30 rue de Rivoli',
    CODE_POSTAL : '75004',
    VILLE : 'Paris',
    PAYS : 'France'

  }
})
`}
                      />
                    ) : (
                      <LoadingComponent />
                    )}
                  </div>
                </LazyLoad>

                <Divider hidden section />

                <Button primary as={Link} to="/guide/bien-demarrer-avec-seeuletter-envoi-courrier-carte-postale-en-ligne">
                  Guide de démarrage
                </Button>
                <Button href="https://docs.MySendingBox.fr/" target="_blank">
                  Consulter la documentation
                </Button>

                <Divider section hidden className="mobile only" />

                <Divider section hidden />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default Services_Letters
