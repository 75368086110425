import React, { Component } from 'react'
import { Container, Menu } from 'semantic-ui-react'

import { connect, ConnectedProps } from 'react-redux'
import LoginZone from './LoginZone'
import NavLinkCustom from './NavLinkCustom'
import { Link } from 'react-router-dom'
import { RootReducerType } from '../reducers'

class TopNav extends Component<PropsFromRedux> {
  render() {
    return (
      <Menu borderless inverted={false}>
        <Container fluid={false}>
          {this.props.ui.nav_visibility ? (
            <Menu.Item name="mysendingbox" as={Link} style={{ fontWeight: '600', fontSize: '1.6em' }} to="/" link>
              {process.env.REACT_APP_WHITE_LABEL === 'canon_fac_simile' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/c_scale,w_220/v1573204494/templates_clients/Canon/Logo_Fac_transparent_without_canon.png"
                    style={{ width: '140px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'axena' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/c_scale,w_220/v1573204494/logo_clients/axena_logo.png"
                    style={{ width: '140px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === '3h18' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1594378618/logo_clients/3h18-logo-cmjn.jpg"
                    style={{ width: '80px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'euro_tvs' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1602061409/logo_clients/logo_EURO_TVS.png"
                    style={{ width: '50px', marginRight: '30px', marginBottom: '1px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'sederoni' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1603099004/logo_clients/Sederoni_Noir_Transparent.png"
                    style={{ width: '70px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'e-print' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1605023847/logo_clients/LOGO_EPRINT.svg"
                    style={{ width: '110px', marginRight: '20px' }}
                  />
                </React.Fragment>
              )}
              <img src="https://res.cloudinary.com/lifebot/image/upload/v1569493696/mysendingbox/msb_rectangle/MSB_vH_RVB.jpg" style={{ width: '200px' }} />
            </Menu.Item>
          ) : (
            <Menu.Item name="mysendingbox" style={{ fontWeight: '600', fontSize: '1.6em' }}>
              {process.env.REACT_APP_WHITE_LABEL === 'canon_fac_simile' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/c_scale,w_220/v1573204494/templates_clients/Canon/Logo_Fac_transparent_without_canon.png"
                    style={{ width: '140px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'axena' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/c_scale,w_220/v1573204494/logo_clients/axena_logo.png"
                    style={{ width: '140px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === '3h18' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1594378618/logo_clients/3h18-logo-cmjn.jpg"
                    style={{ width: '80px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'euro_tvs' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1602061409/logo_clients/logo_EURO_TVS.png"
                    style={{ width: '50px', marginRight: '30px', marginBottom: '1px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'sederoni' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1603099004/logo_clients/Sederoni_Noir_Transparent.png"
                    style={{ width: '70px', marginRight: '40px' }}
                  />
                </React.Fragment>
              )}
              {process.env.REACT_APP_WHITE_LABEL === 'e-print' && (
                <React.Fragment>
                  <img
                    src="https://res.cloudinary.com/lifebot/image/upload/v1605023847/logo_clients/LOGO_EPRINT.svg"
                    style={{ width: '110px', marginRight: '20px' }}
                  />
                </React.Fragment>
              )}
              <img src="https://res.cloudinary.com/lifebot/image/upload/v1569493696/mysendingbox/msb_rectangle/MSB_vH_RVB.jpg" style={{ width: '200px' }} />
            </Menu.Item>
          )}

          {!!this.props.ui.nav_visibility && (
            <Menu.Menu position="right">
              <NavLinkCustom name="Solutions" icon="grid layout" to="/solutions" />

              <NavLinkCustom name="Tarifs" icon="euro" to="/tarifs/courrier" />

              {(process.env.REACT_APP_WHITE_LABEL !== 'canon_fac_simile' &&
                process.env.REACT_APP_WHITE_LABEL !== 'axena' &&
                process.env.REACT_APP_NODE_ENV === 'production' && (
                  <NavLinkCustom name="API" icon="code" isExternal target="_blank" className="mobile hidden" to="https://docs.mysendingbox.fr/" />
                )) ||
                (process.env.REACT_APP_WHITE_LABEL !== 'canon_fac_simile' &&
                  process.env.REACT_APP_WHITE_LABEL !== 'axena' &&
                  process.env.REACT_APP_NODE_ENV === 'staging' && (
                    <NavLinkCustom
                      name="API"
                      icon="code"
                      isExternal
                      target="_blank"
                      className="mobile hidden"
                      to="https://docs-staging.mysendingbox.fr/#introduction"
                    />
                  )) ||
                (process.env.REACT_APP_NODE_ENV === 'development' && (
                  <NavLinkCustom
                    name="API"
                    icon="code"
                    isExternal
                    target="_blank"
                    className="mobile hidden"
                    to="https://docs-development.mysendingbox.fr/#introduction"
                  />
                ))}
              <NavLinkCustom name="Guide utilisateur" icon="book" to="/guide/bien-demarrer-avec-seeuletter-envoi-courrier-carte-postale-en-ligne" />

              <NavLinkCustom name="Blog" icon="edit outline" to="/blog" />

              <Menu.Item>
                <LoginZone />
              </Menu.Item>
            </Menu.Menu>
          )}
        </Container>
      </Menu>
    )
  }
}

const mapStateToProps = (state: RootReducerType) => ({
  ui: state.ui,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(TopNav)
