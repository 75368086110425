import LocalStorage from './LocalStorageUtils'
import Debug from 'debug'
import store from '../store'
import * as ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { checkTokenExpiry } from './jwtHelper'
import { fetchLogout } from '../actions/action_auth'
import { AnyAction } from 'redux'

const debug = Debug('app:RequiredUtils')

export type Locales = 'en' | 'fr'

export function getLanguage(): Locales {
  let browserLanguage = navigator.language ?? navigator.userLanguage

  debug('detected browserLanguage :', browserLanguage)

  if (browserLanguage) {
    browserLanguage = browserLanguage.split('-')[0]
  } else {
    browserLanguage = 'fr'
  }
  if (navigator.userAgent === 'ReactSnap') {
    browserLanguage = 'fr'
  }
  debug('browserLanguage : ', browserLanguage)

  let lang: string | null
  if (LocalStorage.get('lang')) {
    lang = LocalStorage.get('lang')
  } else {
    LocalStorage.set('lang', browserLanguage)
    lang = browserLanguage
  }
  const lang2 = lang !== 'fr' ? 'en' : 'fr'
  debug('setLanguage : ', lang2)
  return lang2
}

export function logPageView(page: string): void {
  // We dont log impersonate page because it's contain jwt token in url
  if (page.indexOf('impersonate') === -1) {
    const state: any = store.getState()
    if (state.auth.profile) {
      // debug('setting userId');
      ReactGA.set({ userId: state.auth.profile.user_id })
    } else {
      ReactGA.set({ userId: null })
    }
    debug('Logging : ', page)
    ReactPixel.pageView() // For tracking page view
    ReactGA.pageview(page)
  }
}

export function checkTokenValidity(next_url?: string): void {
  if (!checkTokenExpiry()) {
    debug('Token is expired... Loging out !')
    store.dispatch(fetchLogout(next_url) as unknown as AnyAction)
  }
}

export function saveRefereer(): void {
  if (window.document.referrer) {
    LocalStorage.set('referrer', window.document.referrer)
  }
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve: any) => setTimeout(resolve, ms))
}
