import React, { FC } from 'react'
import { Button, Container, Divider, Grid, Header, Image, Label, Segment, Statistic } from 'semantic-ui-react'
import { AppMedia } from '../../components/MediaHoc'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import SegmentCustom from '../../components/SegmentCustom'

const { Media } = AppMedia

const Deux_io: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Deux.io - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
        <meta
          name="description"
          content="Fidélisez vos clients grâce au courrier. Entretenez une relation privilégiée avec vos clients en leur adressant des lettres ou des cartes postales personnalisées."
        />

        <meta property="og:title" content="Rétention - MySendingBox - Solutions d'envoi de courrier et de carte postale" />
        <meta
          property="og:description"
          content="Fidélisez vos clients grâce au courrier. Entretenez une relation privilégiée avec vos clients en leur adressant des lettres ou des cartes postales personnalisées."
        />
      </Helmet>

      <Container fluid={false} style={{ flex: 1, minHeight: '400px', backgroundSize: '75%' }}>
        <Divider hidden />

        <Grid columns="equal">
          <Grid.Row centered verticalAlign="middle">
            <Grid.Column computer={7} tablet="8" mobile="16" textAlign="center">
              {/*

              <Divider hidden/>

              <Image inline src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_120/logo_deuxio_ngidpi.png" centered={true} style={{}}/>
              <span style={{margin: '0 20px 0 30px'}}>x</span>
              <Image inline src="https://res.cloudinary.com/lifebot/image/upload/c_pad,h_43,w_120/v1505913415/logo_bleu_grand_xd5tfw.png" centered={true} style={{}}/>
*/}

              {/*
              <ScrollLink as={'a'} smooth={true} offset={-50} duration={500} to="offre-speciale-deux-io" href="#offre-speciale-deux-io"><span aria-label="stars" role="img">✨</span> &nbsp; Voir l'offre spéciale Deux.io <span aria-label="stars" role="img">✨</span></ScrollLink>
*/}
              {/*
              <Message style={{padding : '0,7em 1,5em'}} size="mini" color="orange" compact as={ScrollLink} smooth={true} offset={-50} duration={500} to="offre-speciale-deux-io" href="#offre-speciale-deux-io">
                <span aria-label="stars" role="img">✨</span> &nbsp; Offre spéciale Deux.io <span aria-label="stars" role="img">✨</span>
              </Message> */}
              <Divider hidden />

              <Header as="h1" textAlign="center" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                Vos clients méritent mieux qu'un e-mail.
              </Header>

              <Header as="h2" textAlign="center" size="medium">
                Envoyez des lettres ou des cartes postales personnalisées en quelques minutes.{' '}
                <span aria-label="mailbox" role="img">
                  📮
                </span>
              </Header>
              <Divider hidden />

              <Button as={Link} to="/signup/?nav=false" size="big" color="blue" content="Commencer" />

              <Divider hidden />
            </Grid.Column>

            <Grid.Column computer="6" tablet="7" mobile="16">
              <SegmentCustom className="center-block" transparent compact opacity={0.75} inverted={false} attached={false} raised>
                <Label as="a" color="violet" ribbon="right">
                  OFFRE PARTENAIRE
                </Label>
                <div className="center-text" style={{ margin: '50px' }}>
                  <Image centered src="https://res.cloudinary.com/lifebot/image/upload/c_scale,q_auto,w_180/logo_deuxio_ngidpi.png" />
                  {/*
                  <Header as="h3">
                    <b>-20% sur votre envoi</b> avec le code :
                    <br/>
                    ISAMIA_20
                  </Header> */}

                  <Divider hidden />
                  <Button
                    as={'a'}
                    smooth
                    offset={-50}
                    duration={500}
                    to="offre-speciale-deux-io"
                    href="#offre-speciale-deux-io"
                    size="small"
                    color="orange"
                    content="Offre Spéciale Deux.io"
                  />

                  {/*
                  <ScrollLink as={'a'} smooth={true} offset={-50} duration={500} to="offre-speciale-deux-io" href="#offre-speciale-deux-io"><span aria-label="stars" role="img">✨</span> &nbsp; Voir l'offre spéciale Deux.io <span aria-label="stars" role="img">✨</span></ScrollLink>
*/}
                </div>
              </SegmentCustom>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider section />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16">
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_150/application_icon_home_fl6thu.png"
                      centered
                      style={{ display: 'block', width: '100%', margin: 'auto' }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      API flexible et moderne
                    </Header>
                    <p>Intégrez facilement l'envoi de courrier ou de carte postale dans vos process marketing. Intégration Zapier disponible.</p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="m-top-30">
                  <Grid.Column computer="12" tablet="12" mobile="13">
                    <Header as="h3" size="medium">
                      Outil de création de campagne
                    </Header>
                    <p>
                      Créez vos campagnes de courrier ou de cartes postales en quelques minutes, sans aucune intégration préalable. "Le Mailchimp du courrier".
                    </p>
                  </Grid.Column>
                  <Grid.Column computer="3" tablet="3" mobile="3">
                    <Image
                      src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_150/ring_icon_scrudd.png"
                      centered
                      style={{ display: 'block', width: '100%', margin: 'auto' }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider hidden />
              <Divider section />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="16">
              <Segment basic textAlign="center" id={'offre-speciale-deux-io'}>
                <Header as="h3" size="large" textAlign="center">
                  Offre spéciale Deux.io
                  <Header.Subheader>
                    Bénéficiez des tarifs business, sans conditions de volume, <br />
                    valable sur votre 1er mois d'utilisation.
                  </Header.Subheader>
                </Header>
                <Divider hidden section />

                <Grid>
                  <Grid.Row centered>
                    <Grid.Column computer={6} tablet="8" mobile="16" textAlign="left">
                      <Segment className="center-block" style={{ padding: '30px 50px' }} textAlign="center">
                        <Header as="h2" size="medium" textAlign="center" style={{ marginLeft: '-20px' }}>
                          <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_100/tout_type_de_courrier_v83igd.png" />
                          <Header.Content>Courrier</Header.Content>
                        </Header>
                        <Divider hidden />

                        <Statistic value={`1,05€`} label={`par courrier couleur`} />

                        <p>au lieu de 1,20€</p>

                        <Divider hidden />

                        {/*

                        <Header textAlign="center">
                          <Header.Content>
                            <Link to="/solutions/acquisition">En savoir plus</Link>
                          </Header.Content>
                        </Header>
*/}
                      </Segment>
                    </Grid.Column>

                    <Grid.Column as={Media} at="mobile" computer={1}>
                      <Divider hidden />
                    </Grid.Column>

                    <Grid.Column computer={6} tablet="8" mobile="16" textAlign="left">
                      <Segment className="center-block" style={{ padding: '30px 50px' }} textAlign="center">
                        <Header as="h2" size="medium" textAlign="center" style={{ marginLeft: '-20px' }}>
                          <Image src="https://res.cloudinary.com/lifebot/image/upload/c_crop,w_550/c_scale,q_auto,w_100/originalite_m2fyya.png" />
                          <Header.Content>Carte Postale</Header.Content>
                        </Header>
                        <Divider hidden />

                        <Statistic value={`1,35€`} label={`par carte postale`} />

                        <p>au lieu de 1,50€</p>

                        <Divider hidden />

                        {/*

                        <Header textAlign="center">
                          <Header.Content>
                            <Link to="/solutions/acquisition">En savoir plus</Link>
                          </Header.Content>
                        </Header>
*/}
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Divider hidden />
                <p>
                  <i>Affranchissement inclus</i>
                </p>
                <Divider hidden />

                <Button as={Link} to="/signup/?nav=false" size="big" color="blue" content="Commencer" />
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="10" tablet="14" mobile="16" textAlign="center">
              <Divider section />
              <Divider hidden />

              <Header as="h2">Le courrier intelligent avec MySendingBox</Header>

              <p>Des outils simples à mettre en place et ultra performants.</p>
              <Divider hidden />
              <Divider hidden />

              <Button as={Link} to="/signup/?nav=false" size="big" color="blue" content="Commencer" />
              <p>
                <small>Pas de carte bancaire requise.</small>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default Deux_io
