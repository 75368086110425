import React, { Component } from 'react'
import { Button, Card, Checkbox, Container, Divider, Grid, Header, Icon, Image, Loader, Segment } from 'semantic-ui-react'
import { createClient } from 'contentful'
import { Link, RouteChildrenProps } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { JsonLd } from '../../utils/Utils'
import { formatDate, substractDay } from '../../utils/dateUtils'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import { variables } from '../../utils/Constants'
import { isSuperAdmin } from '../../utils/AuthService'
import { connect, ConnectedProps } from 'react-redux'
import Debug from 'debug'

import '../../Blog.scss'
import PropTypes from 'prop-types'
import ContactUsButton from '../../components/ContactUsButton'
import marked from '../../utils/MarkdownRenderer'
import { RootReducerType } from '../../reducers'

const debug = Debug('app:containers:blog:Article')

const connector = connect((state: RootReducerType) => ({
  auth: state.auth,
}))

type BlogArticleProps = ConnectedProps<typeof connector> & RouteChildrenProps

interface BlogArticleState {
  activeItem: unknown | null
  menuItems: unknown | null
  article: any | null
  articles: any | null
  preview: boolean
  loading?: boolean
}

class BlogArticle extends Component<BlogArticleProps, BlogArticleState> {
  static contextTypes = {
    t: PropTypes.func,
  }

  constructor(props: BlogArticleProps) {
    super(props)
    this.state = {
      activeItem: null,
      menuItems: null,
      article: null,
      articles: null,
      preview: process.env.REACT_APP_NODE_ENV === 'development',
    }

    debug('window.snapStore :', window.snapStore)

    const response =
      window.snapStore &&
      window.snapStore[
        `https://cdn.contentful.com/spaces/rwy17oe5lw1b/environments/master/entries?content_type=2wKn6yEnZewu2SCCkus4as&fields.slug=${
          (props.match?.params as any).article_id
        }`
      ]

    if (response && response.items && response.items.length) {
      this.setState({ article: response.items[0].fields })
    }
  }

  componentDidMount(): void {
    if (!this.state.article) {
      this.fetchPost()
    }
    this.fetchOtherPosts()
  }

  componentDidUpdate(prevProps: BlogArticleProps): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        loading: true,
      })
      this.fetchPost()
      this.fetchOtherPosts()
    }
  }

  fetchOtherPosts = () => {
    const client = createClient({
      space: 'oim6ty3xru2b',
      host: this.state.preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: this.state.preview ? '0Iy_0zYONpseuKcBMBaZrnVNHD52m3BFzO_7FeGvtAc' : 'TJDUyTV3GMnDR5ADPVkHH7eyUtIJ511Q5IOkh4DYLgI',
    })

    debug('Fetching Entries....')

    client
      .getEntries({
        content_type: '2wKn6yEnZewu2SCCkus4as',
        'fields.slug[ne]': (this.props.match?.params as any).article_id,
        'fields.date[gt]': substractDay(60),
        limit: 3,
      })
      .then((response: any) => {
        debug(response.items)
        return this.setState({
          articles: orderBy<any>(
            response.items.map((item: any) => {
              return item.fields
            }),
            ['date'],
            ['desc']
          ),
        })
      })
      .catch((error: any) => {
        return error
      })
  }

  fetchPost = () => {
    const client = createClient({
      space: 'oim6ty3xru2b',
      host: this.state.preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: this.state.preview ? '0Iy_0zYONpseuKcBMBaZrnVNHD52m3BFzO_7FeGvtAc' : 'TJDUyTV3GMnDR5ADPVkHH7eyUtIJ511Q5IOkh4DYLgI',
    })

    debug('Fetching Entries....')

    client
      .getEntries<any>({
        content_type: '2wKn6yEnZewu2SCCkus4as',
        'fields.slug': (this.props.match?.params as any).article_id,
      })
      .then((response: any) => {
        debug(response.items)
        if (response.items.length) {
          // Hack because date function is too strict
          response.items[0].fields.date = response.items[0].fields.date.substr(0, 10)
          this.setState({
            article: response.items[0].fields,
            loading: false,
          })
        } else {
          this.setState({
            article: null,
            loading: false,
          })
        }
        return true
      })
      .catch((error: any) => {
        return error
      })
  }

  render() {
    const { article, loading, articles, preview } = this.state
    const { auth } = this.props

    if (!article || loading) {
      return (
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          {isSuperAdmin(auth) && (
            <Checkbox
              label="Preview"
              toggle
              checked={preview}
              onClick={() => {
                this.setState(
                  {
                    preview: !this.state.preview,
                  },
                  () => {
                    this.fetchPost()
                  }
                )
              }}
              style={{ float: 'right' }}
            />
          )}
          <Loader active>Chargement en cours...</Loader>
        </Segment>
      )
    } else if (!article) {
      return (
        <div>
          <Helmet>
            <title>Article Introuvable - Blog - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          </Helmet>

          <Container
            fluid={false}
            style={{
              flex: 1,
              minHeight: '400px',
              /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%',
            }}
          >
            <Divider hidden />

            <Grid columns="equal">
              <Grid.Row verticalAlign="middle" style={{ marginTop: '25px' }}>
                <Grid.Column width={16} textAlign="center">
                  {isSuperAdmin(auth) && (
                    <Checkbox
                      label="Preview"
                      toggle
                      checked={preview}
                      onClick={() => {
                        this.setState(
                          {
                            preview: !this.state.preview,
                          },
                          () => {
                            this.fetchPost()
                          }
                        )
                      }}
                      style={{ float: 'right' }}
                    />
                  )}

                  <Header as="h1" style={{ fontSize: '3em' }}>
                    Article Introuvable
                  </Header>

                  <Button as={Link} to="/blog">
                    Retourner à l'accueil du Blog
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      )
    } else {
      return (
        <div id="blog">
          <Helmet>
            <title>{article.title} - Blog - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
            <meta name="description" content={article.description} />
            <meta property="og:title" content={`${article.title} - Blog - MySendingBox - Solutions d'envoi de courrier et de carte postale`} />

            <meta property="og:description" content={article.description} />

            <meta property="og:image" content={`https://res.cloudinary.com/lifebot/image/upload/q_auto,c_pad,g_center,h_630,w_1200/${get(article, 'image')}`} />
          </Helmet>

          <JsonLd
            data={{
              '@context': 'http://schema.org',
              '@type': 'BlogPosting',
              headline: article.title,
              image: `https://res.cloudinary.com/lifebot/image/upload/q_auto,c_pad,g_center,h_630,w_1200/${get(article, 'image')}`,
              url: `https://www.MySendingBox.fr${window.location.pathname}/`,
              datePublished: formatDate(article.date, 'yyyy-MM-dd'),
              dateCreated: formatDate(article.date, 'yyyy-MM-dd'),
              dateModified: formatDate(article.date, 'yyyy-MM-dd'),
              description: article.description,
              author: {
                '@type': 'Person',
                name: get(article, 'author[0].fields.name', 'Raphaël'),
              },
              mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': `https://www.MySendingBox.fr${window.location.pathname}/`,
              },
            }}
          />

          <Container
            fluid={false}
            style={{
              flex: 1,
              minHeight: '400px',
              /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%',
            }}
          >
            <Divider hidden />

            <Grid columns="equal">
              <Grid.Row verticalAlign="middle" style={{ marginTop: '25px' }}>
                <Grid.Column width={16} textAlign="left">
                  {isSuperAdmin(auth) && (
                    <Checkbox
                      label="Preview"
                      toggle
                      checked={preview}
                      onClick={() => {
                        this.setState(
                          {
                            preview: !this.state.preview,
                          },
                          () => {
                            this.fetchPost()
                          }
                        )
                      }}
                      style={{ float: 'right' }}
                    />
                  )}

                  <Header as="h1" style={{ fontSize: '3em' }}>
                    {article.title}
                    <Header.Subheader>
                      {get(article, 'author[0].fields.name', 'Raphaël')} &nbsp; &bull; &nbsp; {formatDate(article.date, 'dd MMMM yyyy')}
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column computer="12" tablet="16" mobile="16" textAlign="left" className="m-bottom-30">
                  <Card fluid>
                    <Image src={`https://res.cloudinary.com/lifebot/image/upload/q_auto,c_thumb,g_center,h_630,w_1200/${get(article, 'image')}`} />
                    <Card.Content>
                      <Card.Description>
                        <Divider hidden />
                        <div dangerouslySetInnerHTML={{ __html: marked(article.body) }} />
                      </Card.Description>
                    </Card.Content>

                    <Card.Content extra>
                      <Button
                        color="facebook"
                        size="mini"
                        href={`https://www.facebook.com/dialog/share?app_id=${variables.fb_app_id}&display=popup&href=https://www.MySendingBox.fr${window.location.pathname}/&redirect_uri=https://www.MySendingBox.fr${window.location.pathname}/`}
                        target="_blank"
                      >
                        <Icon name="facebook" /> Facebook
                      </Button>
                      <Button
                        color="twitter"
                        size="mini"
                        href={`https://twitter.com/intent/tweet?text=${article.title}&url=https://www.MySendingBox.fr${window.location.pathname}/`}
                        target="_blank"
                      >
                        <Icon name="twitter" /> Twitter
                      </Button>
                      <Button
                        color="linkedin"
                        size="mini"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.MySendingBox.fr${window.location.pathname}/&title=${article.title}&summary=${article.description}&source=MySendingBox.fr`}
                        target="_blank"
                      >
                        <Icon name="linkedin" /> Linkedin
                      </Button>
                    </Card.Content>
                  </Card>
                  <ContactUsButton size="big" color="orange" displayAs="button" buttonText={this.context.t(article.buttonText || `Besoin d'aide ?`)} />
                </Grid.Column>
                <Grid.Column computer="4" tablet="16" mobile="16" textAlign="center">
                  <Button as={Link} to="/blog/" fluid primary>
                    {' '}
                    Retourner à l'accueil du Blog{' '}
                  </Button>

                  {articles ? (
                    articles.map((item: any) => (
                      <Card key={item.slug} fluid as={Link} to={`/blog/${item.slug}`} link>
                        <Image src={`https://res.cloudinary.com/lifebot/image/upload/q_auto,c_thumb,g_center,h_630,w_1200/${get(item, 'image')}`} />
                        <Card.Content>
                          <Card.Header>{item.title}</Card.Header>
                          <Card.Meta>
                            <span className="date">{formatDate(item.date, 'dd MMMM yyyy')}</span>
                          </Card.Meta>
                        </Card.Content>
                      </Card>
                    ))
                  ) : (
                    <Loader active />
                  )}

                  <ContactUsButton displayAs="button" buttonText={this.context.t("Besoin d'aide ?")} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      )
    }
  }
}

export default connector(BlogArticle)
