import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header, Loader, Menu, Segment } from 'semantic-ui-react'
import { Link, Route, RouteChildrenProps } from 'react-router-dom'
import { createClient } from 'contentful'
import GuidesItem from './Item'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'

import Debug from 'debug'
import { IntercomAPI } from 'react-intercom'

const debug = Debug('app:containers:guides:Index')

type GuidesIndexProps = RouteChildrenProps

interface GuidesIndexState {
  menuItems: any | null
}

class GuidesIndex extends Component<GuidesIndexProps, GuidesIndexState> {
  constructor(props: GuidesIndexProps) {
    super(props)
    this.state = {
      menuItems: null,
    }

    debug('window.snapStore :', window.snapStore)

    const response = window.snapStore && window.snapStore[`https://cdn.contentful.com/spaces/uu95v6ljdw3h/environments/master/entries?content_type=guide`]

    if (response && response.items && response.items.length) {
      debug('Data set from snapStore ! :', response.items)
      const items = orderBy(
        response.items.map((item: any) => {
          return item.fields
        }),
        ['order'],
        ['asc']
      )

      this.setState({ menuItems: groupBy(items, 'category') })
    }
  }

  componentDidMount(): void {
    if (!this.state.menuItems) {
      this.fetchPosts()
    }
  }

  fetchPosts = (): any => {
    let preview = false
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      preview = true
    }

    const client = createClient({
      space: 'uu95v6ljdw3h',
      host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: preview
        ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
        : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
    })

    debug('Fetching Entries....')

    client
      .getEntries({
        content_type: 'guide',
      })
      .then((response: any) => {
        debug(response.items)
        const items = orderBy(
          response.items.map((item: any) => {
            return item.fields
          }),
          ['order'],
          ['asc']
        )

        return this.setState({
          menuItems: groupBy(items, 'category'),
        })
      })
      .catch((error: any) => {
        return error
      })
  }

  render(): JSX.Element {
    const { menuItems } = this.state

    if (!menuItems) {
      return (
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          <Loader active>Chargement en cours...</Loader>
        </Segment>
      )
    }
    return (
      <div>
        <Container
          fluid={false}
          style={{
            flex: 1,
            minHeight: '400px',
            /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%',
          }}
        >
          <Divider hidden />

          <Grid columns="equal">
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={16} textAlign="center">
                <Header style={{ fontSize: '3em' }}>
                  Guide
                  <Header.Subheader style={{ fontSize: '1.14285714rem' }}>Optimisez vos envois de courrier ou de carte postale.</Header.Subheader>
                  <Header.Subheader style={{ fontSize: '1.14285714rem' }}>
                    Utilisez l'outil en ligne ou l'API d'envoi de courrier et de carte postale.{' '}
                    <span role="img" aria-label="rocket">
                      🚀
                    </span>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column computer={4} tablet={4} mobile={16}>
                <Menu vertical fluid size="large">
                  {/* <Menu.Item>
                   <Input placeholder='Search...'/>
                   </Menu.Item>
                   */}
                  {Object.keys(menuItems).map((item: any) => {
                    return (
                      <Menu.Item key={item}>
                        {item}
                        <Menu.Menu>
                          {menuItems[item].map((value: any) => (
                            <Menu.Item
                              active={`/guide/${value.url}` === this.props.location.pathname}
                              key={value.url}
                              name={value.title}
                              to={`/guide/${value.url}`}
                              as={Link}
                            >
                              {value.title}
                            </Menu.Item>
                          ))}
                        </Menu.Menu>
                      </Menu.Item>
                    )
                  })}
                </Menu>

                <Button
                  color="blue"
                  fluid
                  onClick={(e: any) => {
                    if (window.Intercom) {
                      e.preventDefault()
                      IntercomAPI('showNewMessage')
                    }
                  }}
                  as="a"
                  href="mailto:hello@MySendingBox.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Besoin d'aide ?
                </Button>
              </Grid.Column>

              <Grid.Column width="16" textAlign="center" only="mobile">
                <Divider hidden />
              </Grid.Column>

              <Grid.Column stretched>
                <Segment padded>
                  <Route path="/guide/:guide_id" component={GuidesItem} />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default GuidesIndex
