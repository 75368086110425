import React, { Component } from 'react'
import { Loader, Segment, VisibilityEventData } from 'semantic-ui-react'
import Debug from 'debug'
import { connect, ConnectedProps } from 'react-redux'
import { toggleFooter, toggleNav, togglePusher, updateLanguage } from '../../actions/action_ui'
import queryString from 'query-string'
import { createClient } from 'contentful'
import { logException } from '../../utils/ErrorLogger'
import List3Items from './List3Items'
import BigBlock from './BigBlock'
import List3ItemsCampaign from './List3ItemsCampaign'
import PropTypes from 'prop-types'
import { RootReducerType } from '../../reducers'
import { RouteChildrenProps } from 'react-router'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

const debug = Debug('app:containers:Contentful')

const connector = connect((state: RootReducerType) => ({
  ui: state.ui,
}))

type ContentfulProps = ConnectedProps<typeof connector> &
  RouteChildrenProps & {
    dispatch: ThunkDispatch<RootReducerType, void, Action>
  }

interface ContentfulState {
  modalOpen: boolean
  landing: any | null
  gifUrl: string
  playing: boolean
  calculations: { onScreen: boolean }
  loading?: boolean
}

class Contentful extends Component<ContentfulProps, ContentfulState> {
  static contextTypes = {
    t: PropTypes.func,
  }

  constructor(props: ContentfulProps) {
    super(props)
    this.state = {
      modalOpen: false,
      landing: null,
      gifUrl: '',
      playing: false,
      calculations: {
        onScreen: false,
      },
    }

    debug('window.snapStore constructor: ', window.snapStore)

    const response =
      window.snapStore &&
      window.snapStore[
        `https://cdn.contentful.com/spaces/uu95v6ljdw3h/environments/master/entries?content_type=landing&fields.url=${(props.match?.params as any).page_url}`
      ]

    debug('response: ', response)

    if (response && response.items && response.items.length) {
      debug('Data set from snapStore ! :', response.items)
      if (response.items[0].fields.pusherText) {
        props.dispatch(togglePusher(true, response.items[0].fields.pusherText))
      }

      this.setState({
        landing: { ...response.items[0].fields, updated_at: response.items[0].sys.updatedAt, created_at: response.items[0].sys.createdAt },
        loading: false,
      })
    }
  }

  async componentDidUpdate(prevProps: ContentfulProps): Promise<void> {
    debug('componentDidUpdate')
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        loading: true,
      })

      await this.fetchLanding()
      debug('componentDidUpdate IF')
    }
  }

  async componentDidMount(): Promise<void> {
    debug('this.props.match.params.page_url :', (this.props.match?.params as any).page_url)
    if (!queryString.parse(this.props.location.search).nav) {
      this.props.dispatch(toggleNav(false))
      this.props.dispatch(toggleFooter(false))
    }
    if (!this.state.landing) {
      await this.fetchLanding()
    }
  }

  fetchLanding = async () => {
    try {
      let preview = false
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        preview = true
      }

      const client = createClient({
        space: 'uu95v6ljdw3h',
        host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
        accessToken: preview
          ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
          : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
      })

      debug('Fetching Entries....')

      const response = await client.getEntries<any>({
        content_type: 'landing',
        'fields.url': (this.props.match?.params as any).page_url,
      })
      debug(response.items)
      if (response.items && response.items.length) {
        if (response.items[0].fields.pusherText) {
          this.props.dispatch(togglePusher(true, response.items[0].fields.pusherText))
        }
        if (response.items[0].fields.lang === 'en') {
          this.props.dispatch(updateLanguage('en'))
        } else {
          this.props.dispatch(updateLanguage('fr'))
        }

        this.setState({
          landing: { ...response.items[0].fields, updated_at: response.items[0].sys.updatedAt, created_at: response.items[0].sys.createdAt },
          loading: false,
        })
      }
    } catch (ex) {
      logException(ex)
    }
  }

  componentWillUnmount() {
    debug('componentWillUnmount')
    this.props.dispatch(toggleNav(true))
    this.props.dispatch(toggleFooter(true))
    this.props.dispatch(togglePusher(false))
  }

  openModal = (data: string) => {
    debug('data : ', data)
    if (data === 'gif-campagne') {
      this.setState({
        gifUrl: 'https://lifebot.s3.amazonaws.com/website/Video-D%C3%A9mo-Drag-and-Drop.mp4',
      })
    }
    this.setState({
      modalOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    })
  }

  handleVideoClick = () => {
    this.setState({
      playing: !this.state.playing,
    })
  }

  handleUpdate = (e: null, { calculations }: VisibilityEventData) => {
    debug('calculations.onScreen : ', calculations.onScreen)
    this.setState({
      playing: true,
    })
  }

  startPlaying = () => {
    debug('Visible')
    if (!this.state.playing) {
      debug('Playing..')
      this.setState({
        playing: true,
      })
    }
  }

  render() {
    const { landing, loading } = this.state

    if (!landing || loading) {
      return (
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          <Loader active>{this.context.t('Chargement en cours...')}</Loader>
        </Segment>
      )
    } else {
      if (landing.landingType === 'list_3_items') {
        return <List3Items handleVideoClick={this.handleVideoClick} startPlaying={this.startPlaying} playing={this.state.playing} landing={landing} />
      } else if (landing.landingType === 'big_block') {
        return <BigBlock handleVideoClick={this.handleVideoClick} startPlaying={this.startPlaying} playing={this.state.playing} landing={landing} />
      } else if (landing.landingType === 'list_3_items_campagne') {
        return <List3ItemsCampaign handleVideoClick={this.handleVideoClick} startPlaying={this.startPlaying} playing={this.state.playing} landing={landing} />
      }
    }
  }
}

export default connector(Contentful)
