import LocalStorage from './LocalStorageUtils'
import get from 'lodash/get'

import Debug from 'debug'
import { logException } from './ErrorLogger'

const debug = Debug('app:utils:AuthService')

export function getToken(): string | null {
  // Retrieves the user token from localStorage
  try {
    return LocalStorage.get('token')
  } catch (ex) {
    debug('getToken ex : ', ex)
    return null
  }
}

export function setToken(token: string): void | unknown {
  // Retrieves the user token from localStorage
  try {
    return LocalStorage.set('token', token)
  } catch (ex) {
    debug('getToken ex : ', ex)
    logException(ex)
    return ex
  }
}

export function setProfile(profile: any): void | null {
  try {
    debug('Setting profile...')
    LocalStorage.set('profile', JSON.stringify(profile))
  } catch (ex) {
    debug('getToken ex : ', ex)
    logException(ex)
    return null
  }
}

export function getProfile(): any | null {
  // Retrieves the profile data from localStorage
  try {
    return JSON.parse(LocalStorage.get('profile') ?? '')
  } catch (ex) {
    debug('getProfile ex : ', ex)
    return null
  }
}

export function getUserId(): string {
  return getProfile() && getProfile().user_id
}

export function getCompanyId(): string {
  return getProfile() && getProfile().company_id
}

export function getRole(): string {
  return getProfile() && getProfile().role
}

export function getIntercomHash(): string {
  return getProfile() && getProfile().intercom_hash
}

export function isSuperAdmin(auth: any): boolean {
  // debug('isSuperAdmin : ', get(auth, 'profile.super_admin', false))
  return get(auth, 'profile.super_admin', false)
}

export function hasRole(role: string): boolean {
  // debug('Checking hasRole : ', role)
  if (role === 'admin' && getRole() === 'admin') {
    return true
  } else if (role === 'editor' && ['editor', 'admin'].includes(getRole())) {
    return true
  } else if (role === 'sender' && ['sender', 'editor', 'admin'].includes(getRole())) {
    return true
  }
  return false
}
