// https://gist.github.com/acdlite/a68433004f9d6b4cbc83b5cc3990c194 */
import React from 'react'

import Loadable from 'react-loadable'
import { Button, Loader, Message, Segment } from 'semantic-ui-react'
import { prefix } from 'inline-style-prefixer'

import Debug from 'debug'

const debug = Debug('app:utils:CodeSplitting')

function LoadingLoadable(props: any): JSX.Element | null {
  if (props.error) {
    debug('LoadingLoadable : ', props.error)
    return (
      <Segment basic style={{ minHeight: '330px' }}>
        <Message style={{ margin: '50px', textAlign: 'center' }}>
          Oups ! Petit soucis lors du chargement de l'application.
          <br />
          En attendant vous pouvez essayer de recharger la page.
          <br />
          <Button
            color="blue"
            onClick={() => {
              window.location.reload(true)
            }}
          >
            Recharger la page
          </Button>
        </Message>
      </Segment>
    )
  } else if (props.pastDelay) {
    return (
      <div style={prefix({ display: 'flex', alignItems: 'center', justifyContent: 'center' })}>
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          <Loader active size="big">
            Chargement de l'application...
          </Loader>

          <Button
            color="blue"
            onClick={() => {
              window.location.reload(true)
            }}
          >
            Recharger la page
          </Button>

          <Button size="mini" basic onClick={() => window.location.reload(true)}>
            Trop long ? Rechargez la page
          </Button>
        </Segment>
      </div>
    )
  } else {
    return null
  }
}

function RenderLoadable(loaded: any, props: any): JSX.Element {
  const Component = loaded.default
  return <Component {...props} />
}

export const App = Loadable({
  loader: () => import(/* webpackChunkName: "app" */ './../containers/dashboard/App'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['app'],
  delay: 300, // 0.3 seconds
})

export const BatchsIndex = Loadable({
  loader: () => import(/* webpackChunkName: "batchsIndex" */ './../containers/dashboard/batchs/BatchsIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['batchsIndex'],
  delay: 300, // 0.3 seconds
})

export const RulesIndex = Loadable({
  loader: () => import(/* webpackChunkName: "rulesIndex" */ './../containers/dashboard/rules/Rules'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['rulesIndex'],
  delay: 300, // 0.3 seconds
})

export const CampaignsIndex = Loadable({
  loader: () => import(/* webpackChunkName: "campaignsIndex" */ './../containers/dashboard/campaigns/CampaignsIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['campaignsIndex'],
  delay: 300, // 0.3 seconds
})

export const QuickSteps = Loadable({
  loader: () => import(/* webpackChunkName: "quickSteps" */ './../containers/quick_setup/QuickSteps'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['quickSteps'],
  delay: 300, // 0.3 seconds
})

export const Pricing_Admin_New_Janvier_2018 = Loadable({
  loader: () => import(/* webpackChunkName: "pricingAdmin" */ './../containers/Pricing_Admin_New_Janvier_2018'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['pricingAdmin'],
  delay: 300, // 0.3 seconds
})
