import React, { Component } from 'react'
import TopNav from '../components/TopNav'
import { Container, Divider, Segment, Sidebar } from 'semantic-ui-react'
import Footer from './Footer'
import Intercom from 'react-intercom'
import { RouteChildrenProps, withRouter } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import get from 'lodash/get'
import { getIntercomHash, getProfile, getUserId } from '../utils/AuthService'
import { fetchUser } from '../actions/action_user'
import random from 'lodash/random'
import { Helmet } from 'react-helmet'
import { variables } from '../utils/Constants'
import Debug from 'debug'
import isBot from 'isbot'
import PropTypes from 'prop-types'
import { sleep } from '../utils/RequiredUtils'
import queryString from 'query-string'
import { RootReducerType } from '../reducers'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

const debug = Debug('app:containers:Parent')

const connector = connect((state: RootReducerType) => ({
  pathname: get(state, 'router.location.pathname'),
  user: state.user.data,
  ui: state.ui,
}))

type ParentProps = ConnectedProps<typeof connector> &
  RouteChildrenProps & {
    dispatch: ThunkDispatch<RootReducerType, void, Action>
  }

interface ParentState {
  visible: boolean
  loadIntercom: boolean
}

class Parent extends Component<ParentProps, ParentState> {
  static contextTypes = {
    t: PropTypes.func,
  }

  constructor(props: ParentProps) {
    super(props)
    if (getProfile()) {
      debug('Profile found in Localstorage.')
      props.dispatch(fetchUser(getUserId()))
    }
    // if (process.env.REACT_APP_NODE_ENV !== 'production') {
    //   props.dispatch(togglePusher(true))
    // } else {
    //   props.dispatch(togglePusher(true)) // Force display for COVID-19 Message
    // }
    this.state = {
      visible: true,
      loadIntercom: false,
    }
  }

  async componentDidMount(): Promise<void> {
    debug('Parent DidMount')
    await sleep(2000)
    this.setState({
      loadIntercom: true,
    })
    await sleep(16000)
    this.setState({
      visible: false,
    })
  }

  componentDidUpdate(prevProps: ParentProps): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    debug('process.env.REACT_APP_WHITE_LABEL : ', process.env.REACT_APP_WHITE_LABEL)
    const { pathname, user } = this.props
    const { loadIntercom } = this.state
    const query_string = queryString.parse(this.props.location.search)

    debug('query_string: ', query_string)

    const classes_array = []
    if (pathname.match(/app\/|authCallback/)) {
      classes_array.push(`pattern-background`)
    }
    if (process.env.REACT_APP_WHITE_LABEL === 'canon_fac_simile') {
      if (pathname.match(/login|signup/)) {
        classes_array.push(`background-canon`)
      }
    }

    const classes = classes_array.join(' ')

    debug('classes:  ', classes)

    const impersonated = getProfile() ? getProfile().impersonated : pathname.indexOf('impersonate') !== -1
    debug('impersonated : ', impersonated)

    let updated_user: any | null = null
    if (user && !impersonated) {
      updated_user = {
        user_id: user._id,
        email: user.email,
        fake_data: random(0, 1000, true),
        current_page: window.location.pathname,
        user_hash: getIntercomHash(),
        company: {
          id: user.company._id,
          name: get(user, 'company.name', 'Non défini'),
        },
      }
      if (user.branded_for) {
        updated_user.branded_for = user.branded_for
      }
      debug('Updating user in Intercom : %O', updated_user)
    }

    return (
      <div>
        {!impersonated &&
          !isBot(get(window, 'navigator.userAgent')) &&
          loadIntercom &&
          (updated_user ? (
            <Intercom appID={process.env.REACT_APP_NODE_ENV === 'production' ? 'ass3pi0g' : 'tx4spr2c'} {...updated_user} />
          ) : (
            <Intercom appID={process.env.REACT_APP_NODE_ENV === 'production' ? 'ass3pi0g' : 'tx4spr2c'} />
          ))}

        <Helmet>
          <title>MySendingBox - Plateforme d'envoi de courriers</title>
          <meta
            name="description"
            content="Une suite d'outils et d'API flexible et moderne pour envoyer du courrier ou des cartes postales très simplement depuis votre application ou site web."
          />

          <meta property="fb:app_id" content={variables.fb_app_id} />

          <meta property="og:title" content="MySendingBox - Solutions d'envoi de courrier et de carte postale" />
          <meta
            property="og:description"
            content="Une suite d'outils et d'API flexible et moderne pour envoyer du courrier ou des cartes postales très simplement depuis votre application ou site web."
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/lifebot/image/upload/c_lpad,g_center,h_630,q_auto,w_1200/v1569493705/mysendingbox/msbbycorus_square/MSBbyCORUS_RVB.jpg"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://www.MySendingBox.fr${window.location.pathname}/`} />

          <link rel="canonical" href={`https://www.MySendingBox.fr${window.location.pathname}/`} />
        </Helmet>
        <Sidebar.Pushable as="div" id="app">
          <Sidebar
            as={Segment}
            textAlign="center"
            color={'red'}
            animation="push"
            direction="top"
            visible={this.props.user && !this.props.user.company.credit_card_exists}
            inverted
          >
            Attention ! Vous n'avez pas renseigné votre moyen de paiement. Veuillez paramétrer votre compte
          </Sidebar>
          {/* <Sidebar as={Segment} textAlign="center" color={'blue'} animation="push" direction="top" visible={this.props.user && this.state.visible} inverted>
            Pour information : la Poste ne fera pas de relève les vendredis 24 & 31 décembre. Vos courriers seront bien produits mais seront ramassés par la
            Poste le lundi suivant.
          </Sidebar> */}
          {/* <Sidebar
            as={Segment}
            textAlign="center"
            color={process.env.REACT_APP_NODE_ENV === 'staging' ? 'orange' : process.env.REACT_APP_NODE_ENV === 'development' ? 'grey' : 'violet'}
            animation="push"
            direction="top"
            visible={this.props.ui.pusher_visibility}
            inverted
          >
            {!['staging', 'development'].includes(process.env.REACT_APP_NODE_ENV) && (
              <Label color="orange" size="small">
                Info
              </Label>
            )}

            <Header style={{ display: 'inline', marginLeft: '20px' }}>
              {process.env.REACT_APP_NODE_ENV === 'staging' ? (
                `Staging `
              ) : process.env.REACT_APP_NODE_ENV === 'development' ? (
                `Development `
              ) :
              process.env.REACT_APP_NODE_ENV !== 'production' ? (
                `Non prod `
              ) : (
                ``
                // <a href={'https://www.corus.fr/societe/actualites/groupe-corus-rachete-lifebot'}>
                //   Seeuletter intègre le groupe Corus et devient MySendingBox !
                // </a>
              )}
               COVID-19 : Tous nos services continuent à être opérationnels, notre partenaire CORUS pour la production des courriers émis assure comme habituellement les dépôts quotidiens.
            </Header>
          </Sidebar> */}
          <Sidebar.Pusher as={Container} fluid id="app" className={classes}>
            <TopNav />

            {/*  To fix for ie11 and to work on Chrome  http://jsbin.com/ERELezu/24/edit?html,css,output */}
            {/* Olivier : Ici le this.props.children est remplit par les routes dans routes et dans routes le chemin / est affecté au composant home (.js)  */}

            <div style={{ flex: '1 0 auto' }}>{this.props.children}</div>

            <Divider hidden section />

            <Footer produit={false} prix={false} link={false} />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    )
  }
}

// bug https://github.com/ReactTraining/react-router/issues/4671
export default withRouter(connector(Parent))
// export default (Parent)
